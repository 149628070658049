import { Button, Drawer, Input, Select, Spin, Switch, message } from "antd";
import {
  DeleteOutlined,
  PlayCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { makePostRequest } from "../../utils";
import { useParams } from "react-router-dom";
import { Str } from "../../utils/constants";
import { getTableColumnsFromSchema } from "../../utils/schema_utils";
import { v4 as uuidv4 } from "uuid";
import { ColInputField } from "../Common/widgets/ColInputField";

const FilterDrawer = ({
  onToggleDataDrawer,
  openDataDrawer,
  table,
  schema,
  appEvent,
}) => {
  const params = useParams();

  const [rerender, setRerender] = useState(false);
  const [applyingFilter, setApplyingFilter] = useState(false);
  const [filterGroups, setFilterGroups] = useState([
    {
      groupOperation: true,
      uuid: uuidv4(),
      filterItems: [{ column: "", operation: "", value: "" }],
    },
  ]);

  const onRemoveFilterGroup = (uuid) => {
    if (filterGroups.length > 1) {
      const index = filterGroups.findIndex((fg) => fg.uuid === uuid);
      // delete from filter groups
      const getFilterGroups = [...filterGroups];
      getFilterGroups.splice(index, 1);
      setFilterGroups([...getFilterGroups]);
    }
  };

  const onAddFilterGroup = () => {
    setFilterGroups([
      ...filterGroups,
      {
        groupOperation: true,
        uuid: uuidv4(),
        filterItems: [{ column: "", operation: "", value: "" }],
      },
    ]);
  };

  const onAddFilterItem = (uuid) => {
    const filterGroupIndex = filterGroups.findIndex((fg) => fg.uuid === uuid);
    const fg = filterGroups[filterGroupIndex];
    fg.filterItems.push({ column: "", operation: "", value: "" });
    filterGroups[filterGroupIndex] = fg;
    setFilterGroups(filterGroups);
    setRerender(!rerender);
  };

  const onRemoveFilterItem = (uuid, filterIndex) => {
    const filterGroupIndex = filterGroups.findIndex((fg) => fg.uuid === uuid);
    const fg = filterGroups[filterGroupIndex];
    if (fg.filterItems.length > 1) {
      fg.filterItems.splice(filterIndex, 1);
      filterGroups[filterGroupIndex] = fg;
      setFilterGroups(filterGroups);
      setRerender(!rerender);
    }
  };

  const onChangeFilterItemInput = (uuid, filterIndex, inputType, value) => {
    const filterGroupIndex = filterGroups.findIndex((fg) => fg.uuid === uuid);
    const cloned = [...filterGroups];

    cloned[filterGroupIndex].filterItems[filterIndex][inputType] = value;
    setFilterGroups([...cloned]);
  };

  const onSetFilterGroupCondition = (uuid, status) => {
    const filterGroupIndex = filterGroups.findIndex((fg) => fg.uuid === uuid);
    const cloned = [...filterGroups];
    cloned[filterGroupIndex].groupOperation = status;
    setFilterGroups([...cloned]);
  };

  const validateFilters = () => {
    for (const index in filterGroups) {
      const fg = filterGroups[index];
      if (!fg.filterItems || fg.filterItems.length < 1) {
        message.warning("No filters added");
        return false;
      } else {
        for (const index2 in fg.filterItems) {
          const item = fg.filterItems[index2];
          if (
            !item.column ||
            item.column?.length < 1 ||
            !item.operation ||
            item.operation.length < 1 ||
            !item.value ||
            item.value.length < 1
          ) {
            message.warning("Remove or complete missing filter input fields");
            return false;
          }
        }
      }
    }
    return true;
  };
  const onApplyFilters = async () => {
    if (validateFilters()) {
      //
      appEvent.setAppEvent({
        type: "filterTable",
        data: filterGroups.map((fg) => {
          return {
            operation: fg.groupOperation ? "and" : "or",
            filters: fg.filterItems,
          };
        }),
      });
      onToggleDataDrawer(false);
      return;
    }
  };

  const tabColumns = getTableColumnsFromSchema(schema.databaseSchema, table);

  return (
    <Drawer
      title={
        <div className="justify-between flex items-center pt-1">
          <span className="text-sm text-gray-600">Filter {table} rows</span>
          <div className="flex gap-x-2">
            <Button
              disabled={applyingFilter}
              onClick={onAddFilterGroup}
              className="text-gray-700 text-xs font-bold mx-2 px-1 flex flex-row gap-x-2 items-center"
              type="text"
            >
              Add filter Group
              <PlusOutlined />
            </Button>
            <Button
              disabled={applyingFilter}
              onClick={onApplyFilters}
              className="text-gray-700  text-xs font-bold mx-2 px-1 flex flex-row gap-x-2 items-center"
              type="text"
            >
              Apply filters
              <PlayCircleOutlined />
            </Button>
          </div>
        </div>
      }
      closeIcon={<CloseCircleOutlined />}
      placement="right"
      width={600}
      onClose={() => onToggleDataDrawer(false)}
      open={openDataDrawer}
    >
      <Spin spinning={applyingFilter}>
        <div className="col">
          {filterGroups.map((filterGroup) => {
            return (
              <FilterGroupItem
                key={filterGroup.uuid}
                filterGroupUUID={filterGroup.uuid}
                filterGroupCondition={filterGroup.groupOperation}
                filterItems={filterGroup.filterItems}
                onRemoveFilterGroup={() =>
                  onRemoveFilterGroup(filterGroup.uuid)
                }
                onAddFilterItem={() => onAddFilterItem(filterGroup.uuid)}
                onRemoveFilterItem={onRemoveFilterItem}
                onChangeFilterItemInput={onChangeFilterItemInput}
                onSetFilterGroupCondition={onSetFilterGroupCondition}
                tabColumns={tabColumns}
              />
            );
          })}
        </div>
      </Spin>
    </Drawer>
  );
};

export default FilterDrawer;

const FilterItem = ({
  tabColumns = [],
  uuid,
  filterIndex,
  item,
  onRemoveFilterItem,
  onChangeFilterItemInput,
}) => {
  const colOptions = tabColumns?.map((col) => {
    return { value: col.Field, label: col.Field };
  });

  return (
    <div className="flex flex-row gap-x-2 p-2 my-0">
      <Select
        className="w-4/12 text-xs h-10"
        placeholder="Select column"
        status={item.column ? "" : "error"}
        // value={item.column}
        onChange={(e) =>
          onChangeFilterItemInput(uuid, filterIndex, "column", e)
        }
        options={colOptions}
      />
      <Select
        className="w-4/12 text-xs h-10"
        placeholder="Select comparison"
        status={item.operation ? "" : "error"}
        /* value={item.filter} */
        onChange={(e) =>
          onChangeFilterItemInput(uuid, filterIndex, "operation", e)
        }
        options={Str.filterOperators}
      />
      <ColInputField
        colType={tabColumns.find((col) => col.Field === item.column)}
        onChange={(value) =>
          onChangeFilterItemInput(uuid, filterIndex, "value", value)
        }
        value={item.value}
      />
      <Button
        onClick={onRemoveFilterItem}
        className="text-blue-600 font-bold mx-2 px-1 flex flex-row gap-x-2 items-center"
        type="text"
      >
        <MinusCircleOutlined className="text-gray-700" />
      </Button>
    </div>
  );
};

const FilterGroupItem = ({
  filterGroupUUID,
  filterItems = [],
  onAddFilterItem,
  onRemoveFilterItem,
  onRemoveFilterGroup,
  onChangeFilterItemInput,
  onSetFilterGroupCondition,
  filterGroupCondition,
  tabColumns,
}) => {
  return (
    <div>
      <div className="row gap-x-2 bg-gray-100 rounded relative py-2 mb-12">
        <div className="p-2 w-full gap-x-2 flex justify-between items-center">
          <Switch
            checkedChildren="All of the following"
            unCheckedChildren="Any of the following"
            className="bg-gray-400"
            defaultChecked
            checked={filterGroupCondition}
            onChange={(isChecked) =>
              onSetFilterGroupCondition(filterGroupUUID, isChecked)
            }
          />

          <Button
            onClick={onRemoveFilterGroup}
            className="text-gray-600 text-xs mx-2 px-1 flex flex-row gap-x-2 items-center"
            type="text"
          >
            remove filter group
            <DeleteOutlined className="text-gray-700" />
          </Button>
        </div>

        {filterItems.map((filterItem, filterIndex) => {
          return (
            <FilterItem
              uuid={filterGroupUUID}
              tabColumns={tabColumns}
              filterIndex={filterIndex}
              key={filterIndex}
              onRemoveFilterItem={() =>
                onRemoveFilterItem(filterGroupUUID, filterIndex)
              }
              item={filterItem}
              onChangeFilterItemInput={onChangeFilterItemInput}
            />
          );
        })}

        <Button
          onClick={onAddFilterItem}
          className="text-gray-700 font-bold mx-2 px-1 flex flex-row gap-x-2 items-center"
          type="text"
        >
          New filter
          <PlusOutlined />
        </Button>
      </div>
    </div>
  );
};
