import React, { useContext, useState } from "react";
import { makePostRequest, makePutRequest } from "../../../utils";
import { ContextServiceContext } from "../../../context/schema_context";
import { Tag, message } from "antd";
import { Str } from "../../../utils/constants";
import { MButton } from "../../Common/buttons";

const Selector = ({
  workflowId,
  isExistingNodesActivated,
  nodes,
  toggleSavedWorkflows,
}) => {
  const selectors = [
    {
      title: Str.nodeTypes.title.name,
      description: Str.nodeTypes.title.description,
      key: Str.nodeTypes.title.type
    },
    {
      title: Str.nodeTypes.textinput.name,
      description: Str.nodeTypes.textinput.description,
      key: Str.nodeTypes.textinput.type
    },
    {
      title: Str.nodeTypes.select.name,
      description: Str.nodeTypes.select.description,
      key: Str.nodeTypes.select.type
    },
    {
      title: Str.nodeTypes.submit.name,
      description: Str.nodeTypes.submit.description,
      key: Str.nodeTypes.submit.type
    }
  ]
  const contextService = useContext(ContextServiceContext);
  const [loading, setLoading] = useState(false);



  const onSaveFlow = async () => {
    const datasourceId = contextService.schema.databaseSchema.datasourceId;
    if (nodes.length < 1) {
      message.warning("Add at least one component to the workflow");
      return;
    }
    const hasTitle = nodes.find((nd) => nd.type === "titletext");
    if (!hasTitle) {
      message.warning("Add at least one title text component to the workflow");
      return;
    }

    const hasSubmit = nodes.find((nd) => nd.type === Str.nodeTypes.submit.type);
    if (!hasSubmit) {
      message.warning("Add at least one submit component to the workflow");
      return;
    }

    setLoading(true);
    try {
      let response;
      if (isExistingNodesActivated) {
        response = await makePutRequest(
          `/api/datasource/${datasourceId}/workflows`,
          {
            datasource_id: datasourceId,
            name: hasTitle.data.label,
            forms: nodes,
            actions: [],
            workflow_id: workflowId,
          }
        );
      } else {
        response = await makePostRequest(
          `/api/datasource/${datasourceId}/workflows`,
          {
            datasource_id: datasourceId,
            name: hasTitle.data.label,
            forms: nodes,
            actions: [],
          }
        );
      }

      if (response.status == "success") {
        message.success(
          isExistingNodesActivated ? "Workflow updated" : "New workflow created"
        );
        toggleSavedWorkflows();
        setLoading(false);
      } else {
        message.error(response.reason);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
      setLoading(false);
    }
  };


  return (
    <section className="relative w-[350px] shadow  h-full overflow-y-scroll">
      <div className=" border-b-gray-100 border-b-2 p-3">
        <span className="font-bold mb-1 block text-[14px]">
          Create new workflow
        </span>
        <span className="text-xs text-gray-400 block">
          Drag components to the left
        </span>
      </div>

      <div className="p-3">
        {selectors.map((selector) => {
          return <SelectorItem
            description={selector.description}
            name={selector.title}
            type={selector.key}
            icon={selector.key}
          />
        })}

        <div className="absolute bottom-1 left-0 right-0">
          <div className="p-2">
            <MButton
              onClick={onSaveFlow}
              loading={loading}
              className={"w-full"}
              label={
                isExistingNodesActivated ? "Update workflow" : "Create workflow"
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const SelectorItem = ({ name, description, icon, type }) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <div
      onDragStart={(event) => onDragStart(event, type)}
      draggable
      className="flex gap-x-2 items-center my-6 cursor-move rounded"
    >
      <div className="bg-gray-100  w-2/12 h-12 rounded">
        <div
          style={{ backgroundSize: 35 }}
          className={`h-full bg-center bg-no-repeat  rounded-md p-2  bg-[url("textinput.png")]`}>
        </div>
      </div>


      <div className="flex flex-col gap-y-1 hover:bg-gray-100 p-2 rounded w-10/12">
        <span className="text-gray-600 text-md">{name}</span>
        <span className="text-gray-400">{description}</span>
      </div>
    </div>)
};

export default Selector;
