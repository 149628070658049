import { Alert, Button, Modal, message } from "antd";
import { Str } from "../../utils/constants";
import { MButton } from "../Common/buttons";
import {
  HourglassFilled,
  CopyOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { useState } from "react";
import { makePostRequest } from "../../utils";

const ConnectNewDBModal = ({
  openPendingModal,
  togglePendingModal,
  datasource,
  isTablesNotSetup,
  isCloseable
}) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const onConnectRefresh = async () => {
    setLoading(true);
    setError(undefined);
    try {
      const response = await makePostRequest("/api/db_product/connect", {
        datasource_id: datasource?.id,
      });

      if (response.status === "success") {
        setLoading(false);
        window.location.reload();
      } else {
        setError(response.reason);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const providerConfig = JSON.parse(
    datasource?.db_product?.provider_config ?? "{}"
  );

  const isActive = datasource?.db_product?.status === "active";
  const isPending = datasource?.db_product?.status === "pending";

  console.log(providerConfig);
  return (
    <Modal
      open={openPendingModal}
      closable={isCloseable}
      maskClosable={isCloseable}
      onCancel={() => togglePendingModal(false)}
      footer={null}
      title={` ${datasource?.name}`}
    >
      {isTablesNotSetup && (
        <Alert
          description={
            "No tables are setup for this database yet. Please add tables to your database and click on refresh connection to continue"
          }
          type="error"
          className="text-xs"
          closable
          banner
        />
      )}
      {error && (
        <Alert
          description={error}
          type="error"
          className="text-xs"
          closable
          banner
        />
      )}
      <div className=" flex mx-auto gap-y-2 gap-x-6 justify-between py-5 mt-0">
        <DBInfoItem
          title={"Engine"}
          info={datasource?.db_product?.engine_type}
        />
        <DBInfoItem
          title={"Region"}
          info={
            Str.availableDbRegions.find(
              (rg) => rg.value === providerConfig.database?.region
            )?.label
          }
        />
        <DBInfoItem
          title={"Size"}
          info={
            Str.availableDbSizes.find(
              (rg) => rg.value === providerConfig.database?.size
            )?.label
          }
        />
      </div>

      <div className="flex gap-x-6">
        <DBInfoItem
          title={"Cost"}
          info={
            Str.availableDbSizes.find(
              (rg) => rg.value === providerConfig.database?.size
            )?.cost
          }
        />
        <DBInfoItem title={"SSL"} info={"True (Required)"} />
      </div>

      <div className="my-8">
        <DBCredItem
          info={providerConfig.database?.connection.host}
          title={"DB Host name"}
        />
        <DBCredItem
          info={providerConfig.database?.connection.port}
          title={"DB Port"}
        />
        <DBCredItem
          info={providerConfig.database?.connection.password}
          title={"DB Password"}
        />
        <DBCredItem
          info={providerConfig.database?.connection.user}
          title={"Default user"}
        />
      </div>
      <div className="flex mt-6 justify-between items-center gap-x-4">
        <div className="gap-x-2 flex items-center">
          {isActive && <CheckCircleFilled className="text-green-500" />}
          {isPending && <HourglassFilled className="text-blue-500" />}
          {datasource?.db_product?.status} deployment
        </div>
        <MButton
          loading={loading}
          className={"py-1 px-1"}
          onClick={onConnectRefresh}
          label={isActive ? "Refresh connection" : "Connect to Sequelbase"}
        />
      </div>
    </Modal>
  );
};

const DBInfoItem = ({ info, title }) => {
  return (
    <div className="flex flex-col gap-y-2">
      <span className="text-gray-600 text-xs">{title}</span>
      <span className="font-bold">{info}</span>
    </div>
  );
};

const DBCredItem = ({ info, title }) => {
  return (
    <div className="my-4 relative">
      <Button
        onClick={() => {
          navigator.clipboard.writeText(info);
          message.info(`Copied  ${title}`);
        }}
        type="text"
        className="text-xs absolute right-0 top-8"
        icon={<CopyOutlined />}
      />
      <span className="text-xs">{title}</span>
      <div className="bg-gray-200 rounded-md my-2 p-2 ">
        <span className="text-gray-700 text-xs"> {info}</span>
      </div>
    </div>
  );
};
export default ConnectNewDBModal;
