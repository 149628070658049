import { useEffect } from "react";
import {
  PageLoading,
  fetchUserOrDie,
  makeClientSideApiRequest,
} from "../utils";
import { Spin, message } from "antd";
import { Helmet } from "react-helmet";

export default function AppSessionLoader() {
  useEffect(() => {
    loadPage();
  }, []);

  const loadPage = async () => {
    try {
      const response = await makeClientSideApiRequest("/api/datasource/active");
      if (response && response.status === "success" && response.data != null) {
        const activeSource = response.data;
        window.location = `/datasource/${activeSource.id}`;
      } else if (
        response &&
        response.status === "success" &&
        response.data == null
      ) {
        window.location = `/connect`;
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SequelBase | Loading datasources</title>
        <meta name="description" content="Sequelbase" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        <link rel="icon" href="/database_logo_smaller.png" />
        <link rel="canonical" href="https://app.sequelbase.com/connect" />
      </Helmet>
      <main className="h-screen flex flex-col items-center justify-center">
        <div>
          <Spin  spinning className="text-2xl"/>
        </div>
      </main>
    </>
  );
}
