import { Button, Dropdown, Table, Tag } from "antd";
import { Component } from "react";
import PropTypes from "prop-types";

// open modal or open record on the right panel

class CommonTable extends Component {
  state = { headers: [] };

  static propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.object,
    timeTaken: PropTypes.number,
    total: PropTypes.number,
    onRowSelected: PropTypes.func,
  };

  componentDidMount() {
    let headers = [];
    if (this.props.data !== undefined && this.props.data.length > 0) {
      headers = Object.keys(this.props.data[0]).map((header, index) => {
        return {
          title: header,
          dataIndex: header,
        };
      });
    }
    this.setState({ ...this.state, headers });
  }

  render() {
    let headers = [];
    if (
      this.props.data !== undefined &&
      this.props.data.length > 0 &&
      headers.length < 1
    ) {
      headers = Object.keys(this.props.data[0]).map((header, index) => {
        return {
          title: header,
          dataIndex: header,
        };
      });
    }

    const loading = this.props.loading;
    const data = this.props.data;
    /*     const isUpdateIncluded = this.state.headers.find(
        (header) => header.key == "update-operation"
      );
  
     
      if (selectedOperations.includes("Update") && !isUpdateIncluded) {
        this.setState({
          ...this.state,
          headers: [...this.state.headers, this.updateActionData],
        });
      } else if (isUpdateIncluded && !selectedOperations.includes("Update")) {
        const withUpdateHeader = [...this.state.headers];
        const removedWithUpdateHeader = withUpdateHeader.filter(
          (header) => header.key != "update-operation"
        );
        this.setState({
          ...this.state,
          headers: [...removedWithUpdateHeader],
        });
      } */

    return (
      <div className="">
        <div>
          <CommonTableHighlight
            total={this.props.total}
            timeTaken={this.props.timeTaken}
          />
        </div>
        {data && (
          <div className=" overflow-y-scroll">
            <Table
              className="mt-4 p-0"
              loading={loading}
              columns={headers}
              dataSource={[...data]}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    this.props.onRowSelected(record);
                  }, // click row
                  onDoubleClick: (event) => {
                    //  alert(JSON.stringify(record));
                  }, // double click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {}, // mouse leave row
                };
              }}
              // actions={["Update"]}
              bordered
              size="small"
              scroll={{ x: headers.length > 5 ? 3300 : undefined }}
              rowClassName={() => "text-xs p-0 m-0 "}
            />
          </div>
        )}
      </div>
    );
  }
}

export default CommonTable;

const CommonTableHighlight = ({ total, timeTaken }) => {
  return (
    <div className="text-black flex gap-x-6 pt-5 px-2">
      <HighlighItem
        tooltip={"Total records returned"}
        icon={"/row.png"}
        label={` ${total} Records returned`}
      />
      <HighlighItem
        tooltip="Time taken to run query"
        icon={"/clock.png"}
        label={` ${parseFloat(timeTaken).toFixed(2)} Seconds`}
      />
    </div>
  );
};
const HighlighItem = ({ label, icon, tooltip }) => {
  return (
    <a
      href="#"
      title={tooltip}
      className="text-gray-700 text-xs flex gap-x-2 items-center"
    >
      <img className="w-4" src={icon} width={100} height={40} />
      {label}
    </a>
  );
};
