import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react";
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";

import "../index.css";
import {
  MultipleSelectNode,
  SelectNode,
  SubmitFormNode,
  TextInputNode,
  TitleTextNode,
} from "../nodes";
import { makePostRequest } from "../../../utils";
import { ContextServiceContext } from "../../../context/schema_context";
import { NodesServiceContext } from "../../../context/nodes_context";
import { message } from "antd";
import { Str } from "../../../utils/constants";
import WorkflowInfo from "./workflow_info";

const SelectedWorkflow = ({ selectedWorkflow = {} }) => {
  const reactFlowWrapper = useRef(null);

  const [isNodeActivated, setIsNodeActivated] = useState(false);
  const [isCommandRunSuccessful, setIsCommandRunSuccessful] = useState(false);
  const [formValues, setFormValues] = useState({});

  const [nodes, setNodes] = useNodesState([]);

  const contextService = useContext(ContextServiceContext);
  const [loading, setLoading] = useState(false);

  const { id: workflow_id, forms } = selectedWorkflow;

  if (!isNodeActivated) {
    const newNodes = JSON.parse(forms).map((node) => {
      return { ...node, data: { ...node.data, disabled: false } };
    });
    setNodes((nds) => newNodes);
    setIsNodeActivated(true);
  }

  const nodeTypes = useMemo(
    () => ({
      textinput: TextInputNode,
      select: SelectNode,
      selectmultple: MultipleSelectNode,
      submit: SubmitFormNode,
      titletext: TitleTextNode,
    }),
    []
  );

  const onRunFlowCommands = async () => {
    const datasourceId = contextService.schema.databaseSchema.datasourceId;
    setLoading(true);
    try {
      let response;
      response = await makePostRequest(
        `/api/datasource/${datasourceId}/workflows/runWorkFlowCommands`,
        {
          datasource_id: datasourceId,
          workflow_id,
          form_values: formValues,
        }
      );

      if (response.status === "success") {
        message.success("Command run successfully");
        setIsCommandRunSuccessful(true)
        setLoading(false);
      } else {
        message.error(response.reason);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const onToggleEdit = (id) => {
    console.log("dummy press");
  };

  const onNodeValueChange = (label, value) => {
    setFormValues({ ...formValues, [label]: value });
  };

  const isNodeExcludedFromInput = (node) => {
    return (
      node.type === Str.nodeTypes.title.type ||
      node.type === Str.nodeTypes.submit.type
    );
  };

  const validateNodeValues = () => {
    for (const index in nodes) {
      const node = nodes[index];
      const nodeLabel = node.data.label;
      if (isNodeExcludedFromInput(node)) continue;

      console.log(formValues[nodeLabel]);
      if (!formValues[nodeLabel] || formValues[nodeLabel].length < 1) {
        message.info(`Please enter ${nodeLabel}`);
        return false;
      }
    }
    return true;
  };


  const onSubmitForm = () => {
    if (validateNodeValues()) {
      onRunFlowCommands();
    }
  };

  return (
    <div style={{ height: "90vh" }} className="dndflow w-full relative">
      <NodesServiceContext.Provider
        value={{ onToggleEdit, onSubmitForm, onNodeValueChange, loading, setIsCommandRunSuccessful, isCommandRunSuccessful }}
      >
        <ReactFlowProvider>
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              snapToGrid={true}
              // defaultViewport={{ x: 150, y: 0, zoom: 1 }}
              panOnDrag={false}
              draggable={false}
              nodes={nodes}
              nodeTypes={nodeTypes}
            >
              {/*  <Background variant="dots" gap={12} size={1} /> */}
            </ReactFlow>
          </div>
          <WorkflowInfo isCommandRunSuccessful={isCommandRunSuccessful} selectedWorkflow={selectedWorkflow} />
        </ReactFlowProvider>
      </NodesServiceContext.Provider>
    </div>
  );
};

export default SelectedWorkflow;
