import { Checkbox, Spin, Switch, message } from "antd";
import { Str } from "../../../utils/constants";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { makeClientSideApiRequest } from "../../../utils";
import { useParams } from "react-router-dom";

const backendPermissionOptions = [
  { label: "Read", value: Str.backendPermissionOpts.read },
  { label: "Write", value: Str.backendPermissionOpts.write },
  { label: "Update", value: Str.backendPermissionOpts.update },
];

export const DashboardPermissions = ({
  onChangeDashboardPermission,
  dashboardPermissions,
  dashboardGroupSelect,
  onChangeDashboardGroupSelect,
  loading,
  setLoading,
  savedDashboards,
  setSavedDashboards,
}) => {
  const params = useParams();

  useEffect(() => {
    if (savedDashboards.length < 1) {
      loadWorkFlows();
    }
  }, []);

  const loadWorkFlows = async () => {
    try {
      setLoading(true);
      const response = await makeClientSideApiRequest(
        `/api/datasource/${params?.id}/workflows`
      );
      if (!response.data) {
        setSavedDashboards(undefined);
        message.warning(response.reason);
        return;
      } else if (response.data.data?.length > 0) {
        setSavedDashboards(response.data.data);
      } else {
        setSavedDashboards(undefined);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSavedDashboards(undefined);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="w-full ">
        <div className="flex gap-x-3 py-3 bg-gray-100 rounded-md my-3 px-2">
          <Switch
            checkedChildren="All dashboards"
            unCheckedChildren="Select dashboards"
            className="bg-gray-500"
            onChange={(x) =>
              onChangeDashboardGroupSelect(
                !dashboardGroupSelect,
                savedDashboards.map((dsh) => dsh.id)
              )
            }
            checked={dashboardGroupSelect}
          />
        </div>

        {savedDashboards && savedDashboards.length < 1 && (
          <div className="h-96 overflow-y-scroll mt-2 flex items-center justify-center">
            {loading && <span></span>}
            {!loading && <span>No dashboards found</span>}
          </div>
        )}

        {savedDashboards && savedDashboards.length > 0 && (
          <div className="h-96 overflow-y-scroll mt-2">
            {savedDashboards.map((dashboard) => {
              return (
                <div className="flex my-4 justify-between">
                  <span className="">{dashboard?.name}</span>
                  <div className="bg-blue-100 rounded-lg px-2 py-1">
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      className="bg-gray-500"
                      onChange={(x) =>
                        onChangeDashboardPermission(dashboard?.id, x)
                      }
                      checked={dashboardPermissions.includes(dashboard?.id)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Spin>
  );
};
