import { Button, Cascader, Drawer, Spin, message } from "antd";
import {
  CloseCircleOutlined,
  CheckOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import ReactJson, { InteractionProps } from "react-json-view";

import React from "react";
import {
  getFilterWidget,
  getWidgetObj,
} from "./widget_definitions/widget_definitions.ts";
import { IWidgetObj } from "../../../interface/iwidget_builder.ts";
import {
  validateObjRemoval,
  validateValue,
} from "./widget_definitions/widget_validation.ts";
import { cascadeSelectorOptions } from "./widget_definitions/cascade_selector_option.ts";

const WidgetEditor = ({
  onDrawerComplete,
  openDrawer,
  existingWidget,
  onToggleDrawer,
}) => {
  const [loading, setLoading] = useState(false);
  const [widgetObj, setWidgetObj] = useState<IWidgetObj>(
    getFilterWidget("select")
  );
  const defaultCascade = existingWidget
    ? [existingWidget?.type, "input", existingWidget.widgetConfig.inputType]
    : ["filters", "input", "select"];

  useEffect(() => {
    preFillexistingWidget();
  }, [existingWidget]);

  const preFillexistingWidget = () => {
    if (existingWidget) setWidgetObj(existingWidget);
  };

  const onUpdateWidget = (
    data: InteractionProps,
    event: "delete" | "update" | "add"
  ) => {
    if (event === "update" || event === "add") {
      const { name, new_value } = data;
      if (!validateValue(name, new_value, existingWidget !== undefined)) {
        return false;
      }
    }

    if (event === "delete") {
      const { name } = data;
      if (!validateObjRemoval(name, existingWidget !== undefined)) {
        return false;
      }
    }
    setWidgetObj(data.updated_src as IWidgetObj);
    return true;
  };

  const onSubmitWidget = () => {
    // validate widget key
    onDrawerComplete(widgetObj, existingWidget !== undefined);
  };

  const onDeleteWidget = () => {
    // validate widget key
    onDrawerComplete(widgetObj, true, true);
  };

  return (
    <Drawer
      destroyOnClose={true}
      title={
        <div className="justify-between flex items-center pt-1">
          <span className="text-sm text-gray-600">{`Add or Manage app filters`}</span>
          <div className="flex gap-x-2">
            <>
              {" "}
              <Button
                loading={loading}
                disabled={loading}
                onClick={onSubmitWidget}
                className="text-gray-700 text-xs font-bold mx-2 px-1 flex flex-row gap-x-1 items-center"
                type="text"
              >
                {existingWidget ? "Update widget" : " Add widget"}
                <CheckOutlined />
              </Button>{" "}
              {existingWidget && (
                <Button
                  loading={loading}
                  disabled={loading}
                  onClick={onDeleteWidget}
                  className="text-red-700 text-xs font-bold mx-2 px-1 flex flex-row gap-x-1 items-center"
                  type="text"
                >
                  Remove widget
                  <DeleteOutlined />
                </Button>
              )}
            </>
          </div>
        </div>
      }
      closeIcon={<CloseCircleOutlined />}
      placement="right"
      width={600}
      onClose={() => onToggleDrawer(false)}
      open={openDrawer}
    >
      <Spin spinning={loading}>
        <div className="flex flex-col">
          <div className=" my-2 bg-gray-200 p-2 rounded-lg">
            <span className="block">Select widget type</span>
            <Cascader
              allowClear={false}
              disabled={existingWidget !== undefined}
              className="w-6/12"
              options={cascadeSelectorOptions}
              onChange={(selected) => {
                console.log(selected);
                const widgetType = selected[0];
                const formType = selected.length > 2 ? selected[2] : selected[1]
                setWidgetObj(getWidgetObj(widgetType, formType));
              }}
              defaultValue={defaultCascade}
              changeOnSelect
            />
          </div>
          <ReactJson
            onEdit={(interaction) => onUpdateWidget(interaction, "update")}
            onAdd={(interaction) => onUpdateWidget(interaction, "add")}
            onDelete={(interaction) => onUpdateWidget(interaction, "delete")}
            collapsed={false}
            src={widgetObj}
            theme="threezerotwofour"
            style={{ borderRadius: 5 }}
            shouldCollapse={false}
          />
        </div>
      </Spin>
    </Drawer>
  );
};

export default WidgetEditor;
