import { Avatar, Drawer, Input, List, Space, Spin, Tag, message } from "antd";
import {
  CloseCircleOutlined,
  WarningOutlined,
  LikeOutlined,
  MessageOutlined,
  StarOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { MButton } from "../Common/buttons";
import {
  makeApiRequest,
  makeClientSideApiRequest,
  makePostRequest,
} from "../../utils";
import { useParams } from "react-router-dom";
import LogDetailsModal from "./log_details";
import moment from "moment";

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const CommandHistoryDrawer = ({
  onToggleHistoryDrawer,
  openHistoryDrawer,
  table,
  selectedLogs,
}) => {
  const [loading, setLoading] = useState(false);

  const [openLogModal, setOpenLogModal] = useState(false);
  const [selectedLog, setSelectedLog] = useState(undefined);

  const [commands, setCommands] = useState([]);
  const params = useParams();
  if (commands.length < 1 && selectedLogs?.length > 0) {
    setCommands(selectedLogs);
  }else{
    console.log(commands, selectedLogs);
  }


  useEffect(() => {
    console.log(selectedLogs);
    if (selectedLogs && selectedLogs.length > 0) {
      setCommands(selectedLogs);
    }
    if (table) {
      onLoadCommandHistory();
    }
  }, []);

  const onToggleModal = (status, selectedLog) => {
    console.log(status, selectedLog, 'on selected log');
    setOpenLogModal(status);
    setSelectedLog(selectedLog);
  };

  const onLoadCommandHistory = async () => {
    setLoading(true);
    try {
      const response = await makeClientSideApiRequest(
        `/api/datasource/${params?.id}/getCommandHistory/${table}`
      );

      if (response.status === "success") {
        setLoading(false);
        setCommands(response.data.data);
      } else {
        setLoading(false);
        message.error(response.reason);
      }
      // this.setState({ ...this.state, loading: false });
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const getCommandIcon = (query) => {
    if (query.toLowerCase().startsWith("insert")) {
      return "/plus.png";
    }
    if (query.toLowerCase().startsWith("update")) {
      return "/updated.png";
    }
    if (query.toLowerCase().startsWith("delete")) {
      return "/delete.png";
    }
    if (query.toLowerCase().includes("post")) {
      return "/link.png";
    }
  };
  const getCommandShortTitle = (query) => {
    console.log(query);
    if (query.toLowerCase().startsWith("insert")) {
      return "Performed insert operation";
    }
    if (query.toLowerCase().startsWith("update")) {
      return "Performed update operation";
    }
    if (query.toLowerCase().startsWith("delete")) {
      return "Performed delete operation";
    }
    if (query.toLowerCase().includes("post")) {
      return "Sent data to webhook";
    }
  };

  return (
    <Drawer
      title={
        <div className="justify-between flex items-center pt-1">
          <span className="text-sm text-gray-600">Operation logs</span>
        </div>
      }
      closeIcon={<CloseCircleOutlined />}
      placement="right"
      onClose={() => onToggleHistoryDrawer(false)}
      open={openHistoryDrawer}
    >
      <LogDetailsModal
        openLogModal={openLogModal}
        onToggleLogModal={onToggleModal}
        log={selectedLog}
      />
      <Spin className="p-20" spinning={loading}>
        <div className="min-h-96">
          {!loading && commands.length < 1 && (
            <div className="mt-10 flex flex-col items-center justify-center">
              <WarningOutlined className="text-xl my-8" />
              <h1 className="text-gray-400 text-center">
                No operation logs for this table yet. Information about insert,
                update and delete operations performed on this table will show
                here
              </h1>
            </div>
          )}
          {commands.length > 0 && (
            <List
              itemLayout="vertical"
              size="large"
              pagination={{
                onChange: (page) => {
                  console.log(page);
                },
                pageSize: 10,
              }}
              dataSource={commands}
              header={
                <div className="text-gray-400">
                  Showing last {commands.length} operations
                </div>
              }
              renderItem={(item) => (
                <div className="my-3 flex flex-col items-center justify-center">
                  <Tag
                    className="w-full flex justify-between items-start relative text-center cursor-pointer h-14 rounded-lg justify-center p-2"
                    onClick={() => onToggleModal(true, item)}
                  >
                    <div className="flex flex-col items-start justify-start">
                      <div className="flex items-center gap-x-2">
                        <img
                          src={getCommandIcon(item.query)}
                          alt="open"
                          className="w-4 h-4"
                        />
                        <span> {getCommandShortTitle(item.query)}</span>
                      </div>
                      <span className="block mt-1 ml-6 text-gray-400">
                        {" "}
                        {moment(item.created_at).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}{" "}
                      </span>
                    </div>

                    <img src="/share.png" alt="open" className="w-4 h-4" />
                  </Tag>
                </div>
              )}
            />
          )}
        </div>
      </Spin>
    </Drawer>
  );
};

export default CommandHistoryDrawer;
