import { Input } from "antd";

const GeneralInput = ({ value, onChangeInput }) => {
  return (
    <div className="my-3 bg-white rounded-lg border p-2 text-sm shadow-sm">
      <span className="text-gray-500 block my-1 text-xs">
        {" "}
        Enter input label
      </span>

      <Input
        required={true}
        value={value}
        onChange={(input) => onChangeInput("label", input.target.value)}
        className={`border  h-10 border-blue-400`}
        placeholder={`Enter input label`}
      />
    </div>
  );
};

export default GeneralInput;
