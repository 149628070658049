import React, { useContext, useEffect, useState } from "react";
import {
  makeClientSideApiRequest,
  makePostRequest,
  makePutRequest,
} from "../../../utils";
import { List, Spin, Tag, message } from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";
import CommandHistoryDrawer from "../../HistoryLogs";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import { NodesServiceContext } from "../../../context/nodes_context";

const WorkflowOperations = ({ selectedWorkflow = {} }) => {
  const [loading, setLoading] = useState(false);
  const [runLogs, setRunLogs] = useState([]);
  const [openHistoryDrawer, setOpenHistoryDrawer] = useState();
  const [selectedLogs, setSelectedLogs] = useState([]);
  const { isCommandRunSuccessful, setIsCommandRunSuccessful } =
    useContext(NodesServiceContext);

  const params = useParams();

  useEffect(() => {
    onLoadCommandHistory();
  }, []);

  const refreshCommand = async () => {
    await onLoadCommandHistory();
    setIsCommandRunSuccessful(false);
  };

  const onLoadCommandHistory = async () => {
    setLoading(true);
    try {
      const response = await makeClientSideApiRequest(
        `/api/datasource/${params?.id}/getWorkflowRunHistory/${selectedWorkflow.id}`
      );

      if (response.status === "success") {
        setLoading(false);
        setRunLogs(response.data.data);
      } else {
        setLoading(false);
        message.error(response.reason);
      }
      // this.setState({ ...this.state, loading: false });
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const onOpenRunLogs = (status, selectedLogs) => {
    setOpenHistoryDrawer(status);
    setSelectedLogs(selectedLogs);
  };

  if (isCommandRunSuccessful && !loading) {
    refreshCommand();
  }

  return (
    <>
      {" "}
      {selectedLogs && (
        <CommandHistoryDrawer
          onToggleHistoryDrawer={onOpenRunLogs}
          openHistoryDrawer={openHistoryDrawer}
          selectedLogs={selectedLogs}
        />
      )}
      <Spin spinning={loading}>
        <List
          className="mt-4"
          itemLayout="vertical"
          size="large"
          pagination={{
            onChange: (page) => {},
            pageSize: 5,
          }}
          dataSource={runLogs}
          header={
            <div className="text-gray-400">
              Showing last {runLogs.length} runs
            </div>
          }
          renderItem={(item) => (
            <div className="my-4 ">
              <Tag
                onClick={() => onOpenRunLogs(true, item.command_log ?? [])}
                className="w-full block relative text-center cursor-pointer h-16 rounded-lg flex flex-col justify-start items-start gap-y-3 p-2"
              >
                <div className="flex justify-between w-full">
                  <span>Run workflow</span>

                  <img src="/check.png" className="w-4 h-4" alt="status" />
                </div>
                <span>
                  {" "}
                  {moment(item.created_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}{" "}
                </span>
              </Tag>
            </div>
          )}
        />
      </Spin>
    </>
  );
};

export default WorkflowOperations;
