import { Drawer, Input, Radio, Segmented, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { MButton } from "../../Common/buttons";
import { makePostRequest } from "../../../utils";
import { useParams } from "react-router-dom";
import { Str } from "../../../utils/constants";
import RowData from "./row_data";
import DrawerTitle from "./drawer_title";
import RowRelationships from "./row_relationships";

const RowInfo = ({
  selectedRow,
  onToggleDataDrawer,
  openDataDrawer,
  table,
  schema,
  isCreateRow,
  appEvent,
}) => {
  const [fieldInputs, setFieldsInputs] = useState([]);
  const [userAction, setUserAction] = useState(undefined);
  const [sendingCommand, setSendingCommand] = useState(false);
  const [currentView, setCurrentView] = useState(
    Str.rowDisplayOptions.row_data
  );

  const onChangeCurrentView = (value) => {
    setCurrentView(value);
  };

  const params = useParams();

  if (fieldInputs.length < 1 && !isCreateRow) {
    const inputs = Object.keys(selectedRow)?.map((field) => {
      return { data: selectedRow[field], field };
    });
    setFieldsInputs(inputs);
  }

  const getTableColumnsFromSchema = (table) => {
    return schema?.databaseSchema.columns?.[table];
  };

  const validateInputsAgainstSchema = (inputs) => {
    // only validate the first table
    const columns = getTableColumnsFromSchema(table);
    for (const index in columns) {
      const column = columns[index];
      const fieldInput = inputs?.find((input) => input.field == column.Field);
      if (
        column["Field"]?.toLowerCase() != "id" &&
        column["Null"]?.toLowerCase() != "yes" &&
        (!fieldInput || fieldInput.data?.length < 1)
      ) {
        message.error(`${column.Field} input is required`);
        return false;
      }
    }
    return true;
  };

  const getPrimaryField = () => {
    const columns = getTableColumnsFromSchema(table);
    const col = columns.find(
      (col) => col.Extra === "auto_increment" || col.Key === "PRI"
    );
    return col?.Field;
  };

  const sendCommand = (commandType, data = []) => {
    const primaryKeyField = getPrimaryField();

    if (commandType === "delete" || validateInputsAgainstSchema(data)) {
      const command = {
        type: commandType,
        table: table,
        data: data,
        idField: primaryKeyField,
        idValue: data?.find((field) => field.field == primaryKeyField)?.data,
      };
      onSendCommand(command);
    }
  };

  const onCommandSuccess = (command) => {
    const { type } = command;
    //this.props.setIsNeedRefresh(true);
    //this.props.setUserOperationData(undefined);

    switch (type) {
      case "insert":
        message.success("New record created");
        break;
      case "update":
        message.success("Record updated");
        break;
      case "delete":
        message.success("Record deleted");
        break;
      default:
        break;
    }
  };

  const onDataInput = (field, event) => {
    const data = event;
    // remove the current field from previous state
    const getInputs = [...fieldInputs].filter((input) => input.field != field);
    // add current field back to previous state with new value
    getInputs.push({
      field,
      data,
    });

    setFieldsInputs([...getInputs]);
  };

  const isRequiredColumn = (columnKey) => {
    const columns = getTableColumnsFromSchema(table);
    const column = columns.find((col) => {
      return col.Field === columnKey;
    });

    return column?.Null?.toLowerCase() !== "yes";
  };

  const onSendCommand = async (command) => {
    setSendingCommand(true);
    try {
      const response = await makePostRequest(
        "/api/datasource/execute_command",
        {
          datasource_id: params?.id,
          command,
        }
      );

      if (response.status === "success") {
        setSendingCommand(false);
        onCommandSuccess(command);
        appEvent.setAppEvent({
          type: "refreshTable",
        });
        if (command.type !== "update") {
          onToggleDataDrawer(false, undefined);
        }
      } else {
        setSendingCommand(false);
        message.error(response.reason);
      }
      // this.setState({ ...this.state, sendingCommand: false });
    } catch (error) {
      message.error(error.message);
      setSendingCommand(false);
    }
  };

  const primaryKeyField = getPrimaryField();
  return (
    <Drawer
      width={currentView === Str.rowDisplayOptions.relationships ? 800 : 450}
      title={
        <DrawerTitle
          selectedRow={selectedRow}
          isCreateRow={isCreateRow}
          primaryKeyField={primaryKeyField}
          setUserAction={setUserAction}
          table={table}
        />
      }
      closeIcon={<CloseCircleOutlined />}
      placement="right"
      onClose={() => onToggleDataDrawer(false)}
      open={openDataDrawer}
    >
      <div className="w-full">
        <Segmented
          block
          options={
            isCreateRow
              ? [Str.rowDisplayOptions.row_data]
              : [
                  Str.rowDisplayOptions.row_data,
                  Str.rowDisplayOptions.actions,
                  Str.rowDisplayOptions.relationships,
                ]
          }
          className="text-blue-500"
          onChange={onChangeCurrentView}
        />
      </div>

      {currentView === Str.rowDisplayOptions.actions && (
        <div className="flex flex-col justify-center items-center my-6 gap-y-5">
          <span className="text-center block">Coming Soon</span>
          <span className="text-center block text-gray-500 ">
            Perform special operations on a specific row
          </span>
        </div>
      )}

      {currentView === Str.rowDisplayOptions.relationships && (
        <RowRelationships
          table_relationships={
            schema?.databaseSchema?.tbl_relationships?.[table]
          }
          table={table}
          filter={selectedRow.id}
        />
      )}

      {currentView === Str.rowDisplayOptions.row_data && (
        <RowData
          selectedRow={selectedRow}
          isCreateRow={isCreateRow}
          primaryKeyField={primaryKeyField}
          getTableColumnsFromSchema={getTableColumnsFromSchema}
          table={table}
          fieldInputs={fieldInputs}
          onDataInput={onDataInput}
          userAction={userAction}
          sendingCommand={sendingCommand}
          sendCommand={sendCommand}
          isRequiredColumn={isRequiredColumn}
        />
      )}
    </Drawer>
  );
};

export default RowInfo;
