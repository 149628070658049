export const CollapsNavBtn = ({ toggleShwTable }) => {
  return (
    <div
      onClick={toggleShwTable}
      className={`cursor-pointer absolute bottom-4 left-0 right-0`}
    >
      <div className="flex items-center justify-center">
        <img src="/back.png" alt="open" className="w-3 h-3" />
      </div>
    </div>
  );
};
