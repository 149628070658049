import React from "react";
import {
  WarningFilled,
  CloseOutlined,
  CloseCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import { v4 as uuidv4 } from "uuid";
import ErrorBoundary from "../Common/ErrorBoundary";
import Question from "../Question";
import { ContextService } from "../../utils/contextservice/ContextService";
import { ContextServiceContext } from "../../context/schema_context";
import { Selector } from "../Selector";

const defaultKey = uuidv4();
const defaultTab = {
  label: "Datasource Overview",
  type: "quick_actions",
  children: (
    <ErrorBoundary id="Default_question_tab">
      <Selector tabId={defaultKey} />,
    </ErrorBoundary>
  ),
};

class Tab extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isFullScreen: PropTypes.bool,
  };

  state = {
    tabs: [
      {
        ...defaultTab,
        key: defaultKey,
      },
    ],
    activeTabIndex: defaultKey,
  };

  setActiveIndex = (index) => {
    this.setState({ ...this.state, activeTabIndex: index });
  };

  removeTab = (index) => {
    const tabs = [...this.state.tabs];
    let newActiveTabIndex = this.state.activeTabIndex;
    tabs.splice(index, 1);
    if (this.state.activeTabIndex > 0 && this.state.activeTabIndex == index) {
      newActiveTabIndex = this.state.activeTabIndex - 1;
    }
    this.setState({ ...this.state, tabs, activeTabIndex: newActiveTabIndex });
  };

  setItems = (items) => {
    this.setState({ ...this.state, tabs: items });
  };

  onChange = (newActiveKey) => {
    this.setActiveIndex(newActiveKey);
  };

  add = (newTab = undefined, key = undefined) => {
    const newActiveKey = key || uuidv4();
    let tabs = [...this.state.tabs];

    // remove all quick action tab
    if (newTab && newTab.type !== "quick_actions") {
      tabs = tabs.filter((tab, index) => {
        if (index === 0) return true;
        return tab.type !== "quick_actions";
      });
    }
    // remove all quick action tab

    tabs.push(
      newTab || {
        ...defaultTab,
        children: (
          <ErrorBoundary id="Default_question_tab">
            <Selector tabId={newActiveKey} />
          </ErrorBoundary>
        ),
        key: newActiveKey,
      }
    );
    this.setState({
      ...this.state,
      tabs: tabs,
      activeTabIndex: newActiveKey,
    });
  };

  remove = (targetKey) => {
    // dont remove overview tab
    const findIndex = this.state.tabs.findIndex((tb) => tb.key === targetKey);
    if (findIndex === 0) return;
    // dont remove overview tab

    if (this.state.tabs.length <= 1) return;
    let newActiveKey = this.state.activeTabIndex;
    let lastIndex = -1;
    this.state.tabs.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = this.state.tabs.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    this.setState({
      ...this.state,
      tabs: newPanes,
      activeTabIndex: newActiveKey,
    });
  };

  onEdit = (targetKey, action) => {
    if (action === "add") {
      this.add();
    } else {
      this.remove(targetKey);
    }
  };

  constructor(props) {
    super(props);
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }

  componentDidUpdate() {
    const contextService = this.context;
    contextService.tabs.setTabs(this.state.tabs);
    contextService.activeTab.setActiveTab(this.state.activeTabIndex);

    // push tab from external sources
    if (
      contextService.events.appEvent &&
      contextService.events.appEvent.type === "pushAddDefaultTabEvent"
    ) {
      this.add();
      contextService.events.setAppEvent(undefined);
    }

    if (
      contextService.events.appEvent &&
      contextService.events.appEvent.type === "pushTabEvent"
    ) {
      this.add(
        contextService.events.appEvent.tabData,
        contextService.events.appEvent.tabKey
      );
      contextService.events.setAppEvent(undefined);
    }
    // push tab from external sources
  }
  render() {
    const { tabs, activeTabIndex } = this.state;
    return (
      <ErrorBoundary isFullScreen id="datasource_tab">
        <div className="bg-gray-50 w-full h-full">
          <Tabs
            type="editable-card"
            onChange={this.onChange}
            activeKey={this.state.activeTabIndex}
            onEdit={this.onEdit}
            items={tabs}
            className="text-xs"
          />
        </div>
      </ErrorBoundary>
    );
  }
}
Tab.contextType = ContextServiceContext;
export default Tab;
