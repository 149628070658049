import { Button, Tag, Tooltip } from "antd";
import { MButton } from "../../Common/buttons";
import { PlayCircleOutlined, InfoCircleFilled } from "@ant-design/icons";
import React from "react";

export const AppNav = (props: {
  appName: string;
  description: string;
  loading: boolean;
  isPreview: boolean;
  isHasFilters: boolean;
  onApplyFilter: () => void;
  onToggleSelectedWorkFlow: () => void;
  onToggleAppDrawer: (status: boolean) => void;
}) => {
  return (
    <div className="flex items-center justify-between pt-4 px-2">
      <div className="flex items-center gap-x-3">
        <div
          onClick={props.onToggleSelectedWorkFlow}
          className=" cursor-pointer hover:bg-gray-100 rounded-full top-3 left-4  shadow p-4 w-12 h-12 bg-white z-40 flex items-center justify-center"
        >
          <img src="/arrow.png" className="" />
        </div>

        <Tooltip title={props.description}>
          <div className="flex items-center gap-x-3 cursor-pointer">
            <span className="font-bold text-lg text-gray-800 ">
              {props.appName}
            </span>
            <InfoCircleFilled className="text-gray-400 text-lg" />
          </div>
        </Tooltip>
      </div>

      <div className="flex gap-x-2 items-center">
        {props.isPreview && (
          <Tag className="text-xs rounded-lg" color="volcano">
            Previewing
          </Tag>
        )}
        {props.isHasFilters && (
          <Button
            loading={props.loading}
            disabled={props.loading}
            onClick={props.onApplyFilter}
            className="text-gray-700 text-xs font-bold mx-2 px-1 flex flex-row gap-x-1 items-center"
            type="text"
          >
            Apply filters
            <PlayCircleOutlined />
          </Button>
        )}

        <MButton
          label={"Manage App"}
          onClick={() => props.onToggleAppDrawer(true)}
          className="cursor-pointer"
          loading={undefined}
          icon={undefined}
          disabled={undefined}
        />
      </div>
    </div>
  );
};
