import { Card, Mentions, Spin, Statistic, message } from "antd";

export const RawSQLEditor = ({
  isError,
  onChange,
  loading,
  rawSQL,
  sqlKeywords,
}) => {
  //
  return (
    <div className="rounded-lg">
      <Mentions
        //    filterOption={filterOptions}

        readOnly={loading}
        onChange={onChange}
        status={isError ? "error" : ""}
        value={rawSQL}
        rows={8}
        placeholder="Use @ to quickly insert  table names, column names, etc"
        className="text-sm"
        options={sqlKeywords}
      />
    </div>
  );
};
