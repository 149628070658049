import {
  DeleteFilled,
  EditFilled,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { Button, Table, Tag } from "antd";
import { Component } from "react";
import PropTypes from "prop-types";
import { QuestionConfigContext } from "../../context/question_config_context";

// open modal or open record on the right panel

class TablePresentation extends Component {
  state = { headers: [] };

  static propTypes = {
    setUserOperationData: PropTypes.func,
    loading: PropTypes.bool,
    data: PropTypes.object,
  };

  updateActionData = {
    title: "",
    key: "update-operation",
    fixed: "right",
    width: 40,
    render: (_, record) => (
      <Button
        icon={<EditFilled />}
        style={{ fontSize: 12 }}
        size="small"
        onClick={() =>
          this.props.setUserOperationData({
            isManage: true,
            fieldsData: record,
          })
        }
        className="text-sm p-0 m-0"
        type="link"
      />
    ),
  };

  componentDidUpdate() {
    //  const { selectedOperations = [] } = dataAppConfig || {};
    const newHeaders = Object.keys(this.props.data[0]).map((header, index) => {
      return {
        title: header,
        dataIndex: header,
        fixed: index < 1 ? "left" : "",
      };
    });
    /*  if (selectedOperations.includes("Update")) {
        newHeaders.push(this.updateActionData);
      } */
    if (JSON.stringify(this.state.headers) != JSON.stringify(newHeaders)) {
      this.setState({ ...this.state, headers: newHeaders });
    }
  }

  componentDidMount() {
    const headers = Object.keys(this.props.data[0]).map((header, index) => {
      return {
        title: header,
        dataIndex: header,
        fixed: index < 1 ? "left" : "",
      };
    });

    this.setState({ ...this.state, headers });
  }

  render() {
    const loading = this.props.loading;
    const data = this.props.data;

    /*     const isUpdateIncluded = this.state.headers.find(
        (header) => header.key == "update-operation"
      );
  
     
      if (selectedOperations.includes("Update") && !isUpdateIncluded) {
        this.setState({
          ...this.state,
          headers: [...this.state.headers, this.updateActionData],
        });
      } else if (isUpdateIncluded && !selectedOperations.includes("Update")) {
        const withUpdateHeader = [...this.state.headers];
        const removedWithUpdateHeader = withUpdateHeader.filter(
          (header) => header.key != "update-operation"
        );
        this.setState({
          ...this.state,
          headers: [...removedWithUpdateHeader],
        });
      } */

    return (
      <div className="">
        {data.length > 0 && (
          <div className="h-[600px] overflow-y-scroll">
            <Table
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    this.context.onToggleDataDrawer(true, record);
                  }, // click row
                  onDoubleClick: (event) => {
                    //  alert(JSON.stringify(record));
                  }, // double click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {}, // mouse leave row
                };
              }}
              className="mt-4 p-3"
              loading={loading}
              columns={this.state.headers}
              dataSource={[...data]}
              // actions={["Update"]}
              bordered
              size="small"
              scroll={{ x: this.state.headers.length > 5 ? 3300 : undefined }}
              rowClassName={() => "text-xs p-0 m-0 "}
            />
          </div>
        )}
      </div>
    );
  }
}

TablePresentation.contextType = QuestionConfigContext;

export default TablePresentation;
