import React, { useContext, useState } from "react";
import { Spin } from "antd";
import { ContextServiceContext } from "../../context/schema_context";
import { truncate } from "../../utils";
import SavedTeamMembers from "./team_members";

const TeamManagement = ({ tabId }) => {
  const [teamMembers, setTeamMembers] = useState([]);

  return (
    <div className="relative">
      <SavedTeamMembers
        setTeamMembers={setTeamMembers}
        teamMembers={teamMembers}
      />
    </div>
  );
};

export default TeamManagement;
