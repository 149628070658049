export const isColListOmmited = (col) => {
  return col.Extra === "auto_increment";
};

export const getPrimaryFieldCol = (schema, table) => {
  const columns = getTableColumnsFromSchema(schema, table);
  const col = columns.find(
    (col) => col.Extra === "auto_increment" || col.Key === "PRI"
  );
  return col;
};

export const getTableColumnsFromSchema = (schema, table) => {
  return schema?.columns?.[table];
};

export const isTable = (schema, table) => {
  return schema?.tables?.includes(table)
};



export const getTableFromSchema = (schema) => {
  return schema?.tables;
};

export const getDateTimeCols = (schema, table) => {
  const columns = getTableColumnsFromSchema(schema, table);

  const cols = columns.filter((col) => {
    const colType = col.Type.toLowerCase();
    return (
      colType === "timestamp" || colType === "datetime" || colType === "date"
    );
  });

  return cols;
};

export const getTimestampCol = (schema, table) => {
  const columns = getTableColumnsFromSchema(schema, table);

  const col = columns.find((col) => {
    const colType = col.Type.toLowerCase();
    const defaultVal = col.Default?.toLowerCase();
    return colType === "timestamp" && defaultVal === "current_timestamp";
  });

  return col;
};

