import React from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Card,
  Drawer,
  Input,
  Modal,
  Spin,
  Statistic,
  message,
  DatePicker,
} from "antd";
import { MButton } from "../Common/buttons";
import {
  QuestionCircleFilled,
  ArrowUpOutlined,
  OrderedListOutlined,
  ClusterOutlined,
} from "@ant-design/icons";

import {
  makeClientSideApiRequest,
  makePostRequest,
} from "../../utils";
import { ContextServiceContext } from "../../context/schema_context";
import RecordHighlight from "./highlight";
import ErrorBoundary from "../Common/ErrorBoundary";
import PresentError from "./present_error";
import RowInfo from "./row_drawer/row_info_drawer";
import TablePresentation from "./table_presentation_with_edit";
import CommandHistoryDrawer from "../HistoryLogs";
import FilterDrawer from "./filter_drawer";
import dayjs from "dayjs";
import { TotalRowCountWidget } from "../Common/widgets/TotalRowCountWidget";
import { RangeRowCountWidget } from "../Common/widgets/RangeRowCountWidget";
import TriggerDrawer from "./trigger_drawer";

export const presentationTypes = {
  TABLE: "table",
  PIECHART: "piechart",
  LINEGRAPH: "linechart",
};

class Backend extends React.Component {
  static propTypes = {
    tableName: PropTypes.string.isRequired,
    tabId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isFullScreen: PropTypes.bool,
  };

  state = {
    errMsg: undefined,
    selectedRow: undefined,
    data: undefined,
    loading: false,
    datasourceSchema: undefined,
    openDataDrawer: false,
    openFilterDrawer: false,
    openTriggerDrawer: false,
    groupFilters: undefined,
    offset: 0,
    pageSize: 15,
    totalResults: 0,
    isCreateRow: false,
    searchTerm: undefined,
    orderBy: undefined,
    order: undefined,
    openHistoryDrawer: false,
  };

  componentDidMount() {}

  componentDidUpdate() {
    const contextService = this.context;

    if (
      this.context.schema.databaseSchema &&
      !this.state.hasLoadedQuestions &&
      !this.state.loading
    ) {
      this.setState({ hasLoadedQuestions: true }, () => {
        this._onLoadQuestion(this.state.offset, this.state.pageSize);
      });
      return;
    }
    // push event from external sources
    if (
      contextService.events.appEvent &&
      contextService.events.appEvent.type === "refreshTable"
    ) {
      this._onLoadQuestion(this.state.offset, this.state.pageSize);
      contextService.events.setAppEvent(undefined);
    }

    if (
      contextService.events.appEvent &&
      contextService.events.appEvent.type === "filterTable"
    ) {
      this.setState(
        {
          ...this.state,
          groupFilters: contextService.events.appEvent.data,
        },
        () => {
          this._onLoadQuestion(
            0,
            this.state.pageSize,
            this.state.searchTerm,
            this.state.order,
            this.state.order,
            contextService.events.appEvent.data
          );
          contextService.events.setAppEvent(undefined);
        }
      );
    }
  }


  onToggleDataDrawer = (status, data, isCreate = false) => {
    this.setState({
      ...this.state,
      openDataDrawer: status,
      selectedRow: data,
      isCreateRow: isCreate,
    });
  };

  onToggleHistoryDrawer = (status) => {
    this.setState({
      ...this.state,
      openHistoryDrawer: status,
    });
  };

  onToggleFilterDrawer = (status) => {
    this.setState({
      ...this.state,
      openFilterDrawer: status,
    });
  };

  onToggleTriggerDrawer = (status) => {
    this.setState({
      ...this.state,
      openTriggerDrawer: status,
    });
  };

  _onTableConfigChange = async (paginateConfig, filterConfig, sortConfig) => {
    const { field, order } = sortConfig;
    const { current, pageSize } = paginateConfig;

    const offset = current * pageSize - pageSize;
    this.setState(
      {
        ...this.state,
        offset: offset,
        pageSize,
        order: order,
        orderBy: field,
      },
      () =>
        this._onLoadQuestion(
          offset > 0 ? offset : 0,
          pageSize,
          this.state.searchTerm,
          order,
          field
        )
    );
  };

  _onRefresh = () => {
    this._onLoadQuestion(
      this.state.offset,
      this.state.pageSize,
      this.state.searchTerm,
      this.state.order,
      this.state.orderBy
    );
  };

  _onSearch = async (searchTerm) => {
    this.setState({ ...this.state, searchTerm }, () => {
      // always start search at offset 0
      this._onLoadQuestion(0, this.state.pageSize, searchTerm);
    });
  };

  _onLoadQuestion = async (
    offset = 0,
    pageSize = this.state.pageSize,
    searchTerm = this.state.searchTerm,
    order = this.state.order,
    orderBy = this.state.orderBy,
    groupFilters
  ) => {
    const isSorting = order && orderBy;
    this.setState({
      ...this.state,
      hasLoadedQuestions: true,
      loading: true,
      errMsg: undefined,
    });
    try {
      let response;
      if (groupFilters && groupFilters.length > 0) {
        response = await makePostRequest(
          `/api/datasource/${
            this.context.schema.databaseSchema.datasourceId
          }/backend?table=${
            this.props.tableName
          }&offset=${offset}&pageSize=${pageSize}${
            searchTerm ? `&searchTerm=${searchTerm}` : ""
          }${isSorting ? `&orderBy=${orderBy}&order=${order}` : ""}`,
          {
            groupFilters,
          }
        );
      } else {
        response = await makeClientSideApiRequest(
          `/api/datasource/${
            this.context.schema.databaseSchema.datasourceId
          }/backend?table=${
            this.props.tableName
          }&offset=${offset}&pageSize=${pageSize}${
            searchTerm ? `&searchTerm=${searchTerm}` : ""
          }${isSorting ? `&orderBy=${orderBy}&order=${order}` : ""}`
        );
      }

      if (response?.status === "success") {
        this.setState({
          ...this.state,
          loading: false,
          data: response?.data,
        });
      } else if (response?.status && response?.reason) {
        this.setState({
          ...this.state,
          errMsg: response?.reason,
          loading: false,
        });
        return;
      } else {
        this.setState({
          ...this.state,
          errMsg: "Unable to complete request",
          loading: false,
        });
      }
    } catch (error) {
      console.log(error);
      message.warning("Unable to complete request");
      this.setState({
        ...this.state,
        loading: false,
        errMsg: "Unable to complete request",
      });
    }
  };

  _clearFiltersAndReload = () => {
    this.setState(
      {
        offset: 0,
        pageSize: 15,
        searchTerm: undefined,
        order: undefined,
        orderBy: undefined,
      },
      () => {
        this._onLoadQuestion();
      }
    );
  };

  render() {
    const { question, inputType, query, loading } = this.state;
    const isNaturalLanguageInput = inputType === 1;
    const contextService = this.context;
    const databaseSchema = contextService.schema.databaseSchema;
    const isReady = databaseSchema;

    console.log(this.state.data?.result?.length);

    // You can render any custom fallback UI
    return (
      <div className="h-[740px] relative bg-gray-50 px-4">
        {!isReady && (
          <div className="h-full flex justify-center items-center">
            {" "}
            <Spin spinning={true} />
          </div>
        )}
        {!this.state.data && this.state.loading && (
          <div className="h-full flex justify-center items-center">
            {" "}
            <Spin spinning={true} />
          </div>
        )}

        {isReady &&
          (!this.state.data || this.state.data.result.length < 1) &&
          this.state.loading && (
            <div className="h-full flex justify-center items-center">
              {" "}
              <Spin spinning={true} />
            </div>
          )}

        {isReady && this.state.data && this.state.openHistoryDrawer && (
          <ErrorBoundary id="data_history_info">
            <CommandHistoryDrawer
              onToggleHistoryDrawer={this.onToggleHistoryDrawer}
              openHistoryDrawer={this.state.openHistoryDrawer}
              table={this.props.tableName}
            />
          </ErrorBoundary>
        )}

        {isReady && this.state.data && (
          <ErrorBoundary id="table_trigger_drawer">
            <TriggerDrawer
              onToggleDataDrawer={this.onToggleTriggerDrawer}
              openDataDrawer={this.state.openTriggerDrawer}
              table={this.props.tableName}
              schema={this.context.schema}
              datasource={this.context}
              appEvent={this.context.events}
            />
          </ErrorBoundary>
        )}

        {isReady && this.state.data && (
          <ErrorBoundary id="table_filter_drawer">
            <FilterDrawer
              onToggleDataDrawer={this.onToggleFilterDrawer}
              openDataDrawer={this.state.openFilterDrawer}
              selectedRow={{}}
              table={this.props.tableName}
              schema={this.context.schema}
              datasource={this.context}
              appEvent={this.context.events}
            />
          </ErrorBoundary>
        )}

        {isReady &&
          this.state.data &&
          (this.state.selectedRow || this.state.isCreateRow) && (
            <ErrorBoundary id="question_row_info">
              <RowInfo
                onToggleDataDrawer={this.onToggleDataDrawer}
                openDataDrawer={this.state.openDataDrawer}
                selectedRow={this.state.selectedRow}
                table={this.props.tableName}
                schema={this.context.schema}
                datasource={this.context}
                appEvent={this.context.events}
                isCreateRow={this.state.isCreateRow}
              />
            </ErrorBoundary>
          )}

        {/*             <div className="w-11/12 mx-auto">
              {this.state.errMsg && <PresentError errMsg={this.state.errMsg} />}
            </div> */}
        {isReady && (
          <>
            {this.state.data && (
              <div className="flex flex-row my-4 w-11/12 mx-auto px-4 gap-x-2">
                <div className="w-4/12">
                  <TotalRowCountWidget table={this.props.tableName} />{" "}
                </div>

                <div className=" w-4/12">
                  <RangeRowCountWidget table={this.props.tableName} />
                </div>

                <Card className="w-4/12" bordered={false}>
                  <Statistic
                    className="w-full"
                    title={
                      <div className="flex justify-between items-center">
                        <span>Triggers</span>
                        <MButton
                          className={"py-2 px-1"}
                          label={"Manage triggers"}
                          onClick={() => this.onToggleTriggerDrawer(true)}
                        />
                      </div>
                    }
                    value={0}
                    valueStyle={{ color: "orange" }}
                    prefix={<ClusterOutlined />}
                  />
                </Card>
              </div>
            )}

            {/* // Query highlights */}
            {this.state.data &&
              this.state.data.result &&
              (this.state.data.result.length > 0 ||
                this.state.searchTerm?.length > 0) && (
                <div className="w-11/12 mx-auto">
                  <ErrorBoundary id="record_highlight">
                    <RecordHighlight
                      tableName={this.props.tableName}
                      total={this.state.data.total_results}
                      timeTaken={this.state.data.time_taken}
                      noData={!this.state.data}
                      presentationType={this.state.presentationType}
                      onTogglePresentation={this.onTogglePresentation}
                      saveQuestion={this.saveQuestion}
                      loading={this.state.savingQuestion}
                      onSearchTable={this._onSearch}
                      onRefresh={this._onRefresh}
                      onShowHistory={() => this.onToggleHistoryDrawer(true)}
                      onFilterRow={() => this.onToggleFilterDrawer(true)}
                      onAddRow={() =>
                        this.onToggleDataDrawer(true, undefined, true)
                      }
                    />
                  </ErrorBoundary>
                </div>
              )}
            {/* // Query highlights */}

            {/* // TODO, Merge all messages */}

            {/* // No data in table message */}
            {this.state.data &&
              this.state.data.result &&
              this.state.searchTerm?.length < 1 &&
              this.state.data.result.length < 1 && (
                <div className="w-11/12 mx-auto">
                  <ErrorBoundary id="record_highlight">
                    <div className="">
                      <div className="flex mt-40 items-center flex-col h-full gap-y-4">
                        <QuestionCircleFilled className="text-4xl" />
                        <span className="text-center text-lg">
                          Table does not contain any data
                        </span>
                        <MButton
                          onClick={() =>
                            this.onToggleDataDrawer(true, undefined, true)
                          }
                          label={"Add a new row"}
                        />
                      </div>
                    </div>
                  </ErrorBoundary>
                </div>
              )}
            {/* // No data in table message */}

            {/* // Nothing returned from filter message */}
            {this.state.data &&
              this.state.data.result &&
              this.state.data.result.length < 1 && (
                <div className="w-11/12 mx-auto">
                  <ErrorBoundary id="record_highlight">
                    <div className="">
                      <div className="flex mt-40 items-center flex-col h-full gap-y-4">
                        <QuestionCircleFilled className="text-4xl" />
                        <span className="text-center text-lg">
                          Your filters did not return any records
                        </span>
                        <MButton
                          onClick={this._clearFiltersAndReload}
                          label={"Clear filter and refresh"}
                        />
                      </div>
                    </div>
                  </ErrorBoundary>
                </div>
              )}
            {/* // Nothing returned message */}

            {/* // Error message */}
            {this.state.errMsg &&
              (!this.state.data?.result ||
                this.state.data.result?.length < 1) && (
                <div className="w-11/12 mx-auto">
                  <ErrorBoundary id="record_highlight">
                    <div className="">
                      <div className="flex mt-40 items-center flex-col h-full gap-y-4">
                        <QuestionCircleFilled className="text-4xl" />
                        <span className="text-center text-lg">
                          {this.state.errMsg}
                        </span>
                        <MButton
                          onClick={this._clearFiltersAndReload}
                          label={"Clear filter and refresh"}
                        />
                      </div>
                    </div>
                  </ErrorBoundary>
                </div>
              )}
            {/* // Error message */}

            {this.state.data && this.state.data?.result?.length > 0 && (
              <div className="w-11/12 mx-auto">
                <ErrorBoundary id="questions_present_data">
                  <TablePresentation
                    type={this.state.presentationType}
                    data={this.state.data.result}
                    errMsg={this.state.errMsg}
                    total={this.state.data.total_results}
                    pageSize={this.state.pageSize}
                    onPaginate={this._onTableConfigChange}
                    loading={this.state.loading}
                    onToggleDataDrawer={this.onToggleDataDrawer}
                  />
                </ErrorBoundary>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

Backend.contextType = ContextServiceContext;
export default Backend;
