import React from "react";
import PropTypes from "prop-types";
import { Button, Card, Drawer, Input, Modal, Spin, message } from "antd";
import { MButton } from "../Common/buttons";
import {
  makeClientSideApiRequest,
  makePostRequest,
  makePutRequest,
  truncate,
} from "../../utils";
import { ContextServiceContext } from "../../context/schema_context";
import RecordHighlight from "./highlight";
import ErrorBoundary from "../Common/ErrorBoundary";
import PresentData from "./present_data";
import { QuestionConfigContext } from "../../context/question_config_context";
import PresentError from "./present_error";
import SaveQuestionModal from "./save_question_modal";
import RowInfo from "./row_info_drawer";
import SavedQuestions from "./saved_question";

export const presentationTypes = {
  TABLE: "table",
  PIECHART: "piechart",
  LINEGRAPH: "linechart",
};

class Question extends React.Component {
  static propTypes = {
    tabId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isFullScreen: PropTypes.bool,
  };

  state = {
    inputType: 1,
    loadingSavedQuestions: false,
    loading: false,
    datasourceSchema: undefined,
    question: undefined,
    data: undefined,
    presentationType: presentationTypes.TABLE,
    questionConfig: { appConfig: {} },
    openSaveModal: false,
    openDataDrawer: false,
    appNameConfig: undefined,
    savingQuestion: false,
    isSavedQuestion: false,
    savedQuestion: undefined,
    hasLoadedQuestions: undefined,
    savedQuestionsList: undefined,
    errMsg: undefined,
    selectedRow: undefined,
  };
  //   componentDidUpdate() {
  //     const contextService = this.context;
  //     if (!this.state.datasourceSchema && contextService.schema.databaseSchema) {
  //       this.setState({
  //         ...this.state,
  //         datasourceSchema: contextService.schema.databaseSchema,
  //       });
  //     }
  //   }
  componentDidMount() {}

  componentDidUpdate() {
    if (this.context.schema.databaseSchema && !this.state.hasLoadedQuestions) {
      this.setState({ hasLoadedQuestions: true }, () => this._onLoadQuestion());
    }
  }
  constructor(props) {
    super(props);
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }

  onQuestionInput = (e) => {
    this.setState({
      ...this.state,
      question: e.target.value,
    });
  };

  onSetInputType = () => {
    this.setState({
      ...this.state,
      inputType: this.state.inputType === 1 ? 2 : 1,
    });
  };

  onToggleDataDrawer = (status, data) => {
    this.setState({
      ...this.state,
      openDataDrawer: status,
      selectedRow: data,
    });
  };

  onToggleSaveModal = (status) => {
    this.setState({
      ...this.state,
      openSaveModal: status,
    });
  };

  saveQuestion = async () => {
    console.log(this.state.data);
    this.setState({ ...this.state, savingQuestion: true, errMsg: undefined });
    try {
      const response = await makePutRequest("/api/question", {
        question_id: this.state.data.log.id,
        name: this.state.appNameConfig,
        app_config: this.state.questionConfig,
      });

      if (response.status == "success") {
        this.setState({
          ...this.state,
          savingQuestion: false,
          openSaveModal: false,
          isSavedQuestion: true,
          savedQuestion: response.data,
        });
      } else {
        message.error(response.reason);
        this.setState({ ...this.state, errMsg: response.reason });
      }
    } catch (error) {
      message.error(error.message);
      this.setState({
        ...this.state,
        savingQuestion: false,
        errMsg: error.message,
      });
    }
  };

  _onLoadQuestion = async () => {
    console.log(this.context.schema);
    this.setState({
      ...this.state,
      hasLoadedQuestions: true,
      loadingSavedQuestions: true,
      errMsg: undefined,
    });
    try {
      const response = await makeClientSideApiRequest(
        `/api/datasource/${this.context.schema.databaseSchema.datasourceId}/questions`
      );
      if (!response.data) {
        this.setState({ ...this.state, errMsg: response.reason });
        return;
      }
      this.setState({
        ...this.state,
        loadingSavedQuestions: false,
        savedQuestionsList: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        ...this.state,
        loadingSavedQuestions: false,
        error: error.message,
      });
    }
  };

  selectSavedQuestion = async (savedQuestion) => {
    this.setState(
      {
        ...this.state,
        savingQuestion: false,
        openSaveModal: false,
        isSavedQuestion: true,
        savedQuestion: savedQuestion,
        appNameConfig: savedQuestion.name,
        questionConfig: JSON.parse(savedQuestion.app_config),
      },
      async () => await this.askSavedQuestion()
    );
  };
  askSavedQuestion = async (id) => {
    console.log(id || this.state.savedQuestion.id);
    this.setState({ ...this.state, loading: true, errMsg: undefined });
    try {
      const response = await makePostRequest("/api/question/refresh", {
        question_id: id || this.state.savedQuestion.id,
      });

      if (response.status == "success") {
        this.setState(
          {
            ...this.state,
            loading: false,
            data: response?.data,
            question: response?.data.log.question,
          },
          () => this.setTabTitle()
        );
      } else {
        this.setState({
          ...this.state,
          loading: false,
          errMsg: response.reason,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({ ...this.state, loading: false, errMsg: error.message });
    }
  };

  askQuestion = async () => {
    this.setState({ ...this.state, loading: true, errMsg: undefined });
    const { question } = this.state;
    try {
      const response = await makePostRequest("/api/question", {
        question: question,
        datasource_id: this.context.schema.databaseSchema.datasourceId,
      });

      if (response.status === "success") {
        this.setTabTitle();
        this.setState({
          ...this.state,
          loading: false,
          data: response?.data,
        });
      } else {
        this.setState({
          ...this.state,
          loading: false,
          errMsg: response.reason,
        });
      }
    } catch (error) {
      this.setState({ ...this.state, loading: false, errMsg: error.message });
    }
  };

  setTabTitle = () => {
    const tabs = [...this.context.tabs.tabs];
    const setTab = this.context.tabs.setTabs;
    tabs.find((tab) => tab.key == this.props.tabId).label = truncate(
      this.state.question
    );
    setTab(tabs);
  };

  onTogglePresentation = (type) => {
    this.setState({ ...this.state, presentationType: type });
    // onSetQuestionConfig("displayAs", type);
  };

  onInputName = (event) => {
    this.setState({ ...this.state, appNameConfig: event.target.value });
  };

  onSetQuestionConfig = (type, config) => {
    const { section, data } = config;
    this.setState({
      questionConfig: {
        ...this.state.questionConfig,
        [type]: {
          ...this.state.questionConfig[type],
          [section]: data,
        },
      },
    });
  };

  render() {
    const { question, inputType, query, loading } = this.state;
    const isNaturalLanguageInput = inputType === 1;
    const contextService = this.context;
    const databaseSchema = contextService.schema.databaseSchema;
    const isReady = databaseSchema;

    console.log(this.state.errMsg);
    /*   const {
      question_id,
      result = [],
      time_taken: timeTaken = 0,
      log: questionLog = {},
      pre_query_content = [],
    } = this.state.data; */

    // You can render any custom fallback UI
    return (
      <div className="h-[740px] relative bg-gray-50 px-4">
        {!isReady && (
          <div className="h-full flex justify-center items-center">
            {" "}
            <Spin spinning={true} />
          </div>
        )}
        {!this.state.data && this.state.loading && (
          <div className="h-full flex justify-center items-center">
            {" "}
            <Spin spinning={true} />
          </div>
        )}

        {isReady && this.state.loadingSavedQuestions && (
          <div className="h-full flex justify-center items-center">
            {" "}
            <Spin spinning={true} />
          </div>
        )}

        {isReady &&
          this.state.savedQuestionsList &&
          this.state.savedQuestionsList.length > 0 &&
          !this.state.data &&
          !this.state.errMsg &&
          !this.state.savedQuestion &&
          !this.state.loading && (
            <ErrorBoundary id="questions_saved_questions">
              <SavedQuestions
                selectSavedQuestion={this.selectSavedQuestion}
                savedQuestionsList={this.state.savedQuestionsList}
              />
            </ErrorBoundary>
          )}
        {isReady && this.state.data && this.state.selectedRow && (
          <ErrorBoundary id="question_row_info">
            <RowInfo
              onToggleDataDrawer={this.onToggleDataDrawer}
              openDataDrawer={this.state.openDataDrawer}
              selectedRow={this.state.selectedRow}
            />
          </ErrorBoundary>
        )}
        {isReady && (
          <>
            <SaveQuestionModal
              onInputName={this.onInputName}
              openSaveModal={this.state.openSaveModal}
              onToggleSaveModal={this.onToggleSaveModal}
              savingQuestion={this.state.savingQuestion}
              appNameConfig={this.state.appNameConfig}
              saveQuestion={this.saveQuestion}
            />

            {this.state.data &&
              !this.state.errMsg &&
              this.state.data.result &&
              this.state.data.result.length > 0 && (
                <div className="w-11/12 mx-auto">
                  <ErrorBoundary id="record_highlight">
                    <QuestionConfigContext.Provider
                      value={{
                        data: this.state.data.result,
                        type: this.state.presentationType,
                        onSetQuestionConfig: this.onSetQuestionConfig,
                        questionConfig: this.state.questionConfig,
                        onToggleSaveModal: this.onToggleSaveModal,
                        isSavedQuestion: this.state.isSavedQuestion,
                      }}
                    >
                      <RecordHighlight
                        total={this.state.data.result?.length}
                        timeTaken={this.state.data.time_taken}
                        noData={!this.state.data}
                        presentationType={this.state.presentationType}
                        onTogglePresentation={this.onTogglePresentation}
                        saveQuestion={this.saveQuestion}
                        loading={this.state.savingQuestion}
                      />
                    </QuestionConfigContext.Provider>
                  </ErrorBoundary>
                </div>
              )}

            <div className="w-11/12 mx-auto">
              {this.state.errMsg && <PresentError errMsg={this.state.errMsg} />}
            </div>

            {this.state.data && !this.state.errMsg && (
              <div className="w-11/12 mx-auto">
                <QuestionConfigContext.Provider
                  value={{
                    data: this.state.data.result,
                    type: this.state.presentationType,
                    onToggleDataDrawer: this.onToggleDataDrawer,
                    onSetQuestionConfig: this.onSetQuestionConfig,
                    questionConfig: this.state.questionConfig,
                    onToggleSaveModal: this.onToggleSaveModal,
                    loading: this.state.loading,
                  }}
                >
                  <ErrorBoundary id="questions_present_data">
                    <PresentData
                      type={this.state.presentationType}
                      data={this.state.data.result}
                      errMsg={this.state.errMsg}
                    />
                  </ErrorBoundary>
                </QuestionConfigContext.Provider>
              </div>
            )}

            <div className="absolute left-0 right-0  mx-auto lg:w-11/12   rounded-lg p-4 px-2 bottom-0">
              {!this.state.isSavedQuestion && (
                <div className="mx-auto w-full my-8 relative h-90">
                  <div></div>
                  <textarea
                    disabled={this.state.isSavedQuestion}
                    readOnly={this.state.isSavedQuestion}
                    onChange={this.onQuestionInput}
                    type="text"
                    placeholder="Type your question here.."
                    className="text-md pt-4 outline-none text-black focus:ring-0 h-14 rounded-full  bg-white p-2 border border-[#ADB5BD] w-full px-5"
                  ></textarea>
                  <MButton
                    loading={this.state.loading}
                    disabled={
                      !this.state.question || this.state.question.length < 5
                    }
                    onClick={this.askQuestion}
                    className={"absolute right-2 top-2"}
                    label={"Ask question"}
                  />
                </div>
              )}
              {this.state.isSavedQuestion && (
                <div className="mx-auto w-full my-8 relative h-90">
                  <Spin spinning={this.state.loading}>
                    <Card
                      disabled={this.state.isSavedQuestion}
                      readOnly={this.state.isSavedQuestion}
                      type="text"
                      placeholder="Type your question here.."
                      className="rounded-lg"
                    >
                      <div className="flex justify-between items-center ">
                        <div className="flex flex-col items-start justify-center">
                          <span className="text-left">
                            {this.state.appNameConfig}
                          </span>
                          <span className="text-left text-gray-400">
                            {this.state.question}
                          </span>
                        </div>
                        <MButton
                          loading={this.state.loading}
                          onClick={() => this.askSavedQuestion()}
                          className={""}
                          label={"Refresh question"}
                        />
                      </div>
                    </Card>
                  </Spin>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

Question.contextType = ContextServiceContext;
export default Question;
