import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { message } from "antd";

const GoogleLoginButton = ({ onSuccess }) => {
  const responseGoogle = async (response) => {
    // console.log("HEREEEE", response);
    return await onSuccess(true, response?.credential);
  };

  const errorGoogle = (response) => {
    message.error("Unable to authenticate with google");
  };

  return (
    <div>
      <GoogleLogin onSuccess={responseGoogle} onFailure={errorGoogle} />
    </div>
  );
};

export default GoogleLoginButton;
