import { Radio, Select, message } from "antd";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { makePostRequest } from "../../utils";
import { Helmet } from "react-helmet";
import { FlagOption, Str } from "../../utils/constants";
import { NewMySQL } from "./NewMysql";
import { ExistingMySQL } from "./ExistingMysql";

const options = [
  { label: <FlagOption title={"New database"} flag={"newdb"} />, value: "new" },
  {
    label: <FlagOption title={"Existing database"} flag={"existingdb"} />,
    value: "existing",
  },
];

export default function ConnectDB() {
  const navigate = useNavigate();

  const [typeSelected, setTypeSelected] = useState(
    Str.datasourceTypesNew[0].value
  );
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(undefined);
  const [databaseOption, setDatabaseOption] = useState("new");

  const isNewDb = databaseOption === "new";

  const defaultEngine = isNewDb
    ? Str.datasourceTypesNew[0].value
    : Str.datasourceTypesExisting[0].value;

  const _validateGeneralData = () => {
    return true;
  };

  const _onAddDatasource = async (type, data) => {
    if (_validateGeneralData()) {
      if (isNewDb) {
        await connectNewSource(data);
      } else {
        await connectExistingSource(data);
      }
    }
  };

  const connectNewSource = async (formData) => {
    try {
      setLoading(true);
      const response = await makePostRequest("/api/db_product", {
        ...formData,
        datasource_type: typeSelected,
      });

      if (response.status === "success") {
        setLoading(false);
        onGotoDatasources();
      } else {
        message.warning(response.reason);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setErrMsg(error.message);
      setLoading(false);
    }
  };

  const connectExistingSource = async (formData) => {
    try {
      setLoading(true);
      const response = await makePostRequest("/api/datasource", {
        db_flags: ["sql_mode=only_full_group_by"],
        is_update: false,
        ...formData,
        datasource_type: typeSelected,
        // datasource_name: "residencepassdb0",
        // username: "spend-db-admin",
        // password: "AVNS_nUi6uTfDFNGRVHeYuDr",
        // host: "spend-africa-db-do-user-12302551-0.b.db.ondigitalocean.com",
        // database: "spend-db-prod",
      });

      if (response.status == "success") {
        setLoading(false);
        onGotoDatasources();
      } else {
        message.warning(response.reason);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setErrMsg(error.message);
      setLoading(false);
    }
  };

  const onInputData = (type, event) => {
    formData[type] = event.target.value;
    setFormData({ ...formData });
  };

  const onGotoDatasources = () => {
    navigate(`/`);
  };

  const onChangeIsExisting = ({ target: { value } }) => {
    setDatabaseOption(value);
    setTypeSelected(
      value == "new"
        ? Str.datasourceTypesNew[0].value
        : Str.datasourceTypesExisting[0].value
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SequelBase | Connect your datasource</title>
        <meta name="description" content="Sequelbase" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        <link rel="icon" href="/database_logo_smaller.png" />
        <link rel="canonical" href="https://app.sequelbase.com/connect" />
      </Helmet>
      <div className="bg-white">
        <main className="h-screen bg-white  mx-auto py-0 w-full flex items-center justify-between">
          <div className="h-full bg-gray-100 w-6/12 ">
            <div className="bg-white h-full py-10 px-10">
              <>
                <div className="flex items-center my-4 mx-6">
                  <img
                    alt="logo"
                    className="w-8"
                    src="/database_black.png"
                    width={100}
                    height={20}
                  />
                  <h1
                    style={{ fontFamily: "mainFontBold" }}
                    className="text-gray-800 text-lg ml-1 font-black"
                  >
                    {" "}
                    SequelBase
                  </h1>
                </div>
                <span className="text-gray-600 text-sm mx-6">
                  Link or create a new database to continue.{" "}
                  {" Need help? email "}
                  <span className="text-blue-600">
                    <a
                      href="#"
                      onClick={(e) => {
                        window.location.href = "mailto:support@sequelbase.com";
                        e.preventDefault();
                      }}
                    >
                      {" "}
                      support@sequelbase.com
                    </a>{" "}
                  </span>
                </span>
                <div>
                  <div className="px-7 mt-12 flex flex-col">
                    <span className="text-gray-500 text-xs my-2">
                      Select Datasource option
                    </span>
                    <div>
                      <Radio.Group
                        options={options}
                        onChange={onChangeIsExisting}
                        value={databaseOption}
                        optionType="button"
                      />
                    </div>
                  </div>
                  <div className="px-7 w-full pt-8 pb-2 flex flex-col">
                    <span className="text-gray-500 text-xs my-2">
                      Select Datasource type
                    </span>
                    <Select
                      defaultValue={defaultEngine}
                      className="w-40"
                      value={typeSelected}
                      onChange={(value) => {
                        setTypeSelected(value);
                      }}
                      options={
                        isNewDb
                          ? Str.datasourceTypesNew
                          : Str.datasourceTypesExisting
                      }
                    />
                  </div>

                  {/* mysql datasource form */}
                  {isNewDb && typeSelected.toLocaleLowerCase() == "mysql" && (
                    <NewMySQL
                      onInputData={onInputData}
                      _onAddDatasource={_onAddDatasource}
                      loading={loading}
                    />
                  )}

                  {/* mysql datasource form */}
                  {!isNewDb && (
                    <ExistingMySQL
                      onInputData={onInputData}
                      _onAddDatasource={_onAddDatasource}
                      loading={loading}
                    />
                  )}
                </div>
              </>
            </div>
          </div>
          <div className="h-full bg-gray-100 w-6/12 flex flex-col items-center justify-center">
            <img className="" src="/login_img.svg" />
            <div className="px-20 text-center my-6">
              <span className="">
                Modern MySQL & PostgreSQL platform powered by AI.{" "}
                <span className="font-boldd">
                  <span className="font-bold"> Advanced filters, </span>{" "}
                  <span className="font-bold">Data relationships, </span>
                  <span className="font-bold">No code workflows, </span>
                  <span className="font-bold">Seamless imports </span>, out of
                  the box.
                </span>{" "}
                <span className="font-bold">without writing any codes </span>
              </span>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
