import { useContext, useState } from "react"
import { RowActionType } from "../../../../interface/iwidget_builder"
import { Modal } from "antd";
import React from "react";
import { getTableNamesFromSchemaAsKeywords, interpolateTextWithKeywords, objAsKeywords } from "../../../../utils/keyword_utils";
import { ContextServiceContext } from "../../../../context/schema_context";
import { Str } from "../../../../utils/constants";
import { WidgetInputComponent } from "../../../Common/widgets/WidgetInputComponent";
import { getInputExtraWidgetObj } from "../../../../utils";


const InputsRowAction = (props: {
    name: string,
    description: string,
    isOpen: boolean,
    actionObj: any,
    rowData: any,
    onCancel: () => void,
}) => {
    const contextService = useContext(ContextServiceContext);
    const schema = contextService.schema;
    const [userInputs, setUserInputs] = useState({});

    const { onCancel, actionObj, isOpen, name } = props
    const { widgetConfig } = actionObj;
    const { actions = [], inputs = [] } = widgetConfig;

    console.log(widgetConfig);

    const onPerformRowAction = async () => {
        await console.log(actionObj);
        onCancel()
    }


    const keyWords =
        [
            ...objAsKeywords(props.rowData, 'rowData'),
            ...Str.defaultSQLKeywords,
            ...getTableNamesFromSchemaAsKeywords(schema.databaseSchema)
        ]

    const getDescription = () => {
        return interpolateTextWithKeywords(keyWords, widgetConfig.description)
    }

    const getAction = (action: string) => {
        return interpolateTextWithKeywords(keyWords, action)
    }

    const onUserInput = (key, value) => {
        console.log(key, value);
        setUserInputs({ ...userInputs, [key]: value });
    };

    return (
        <Modal open={isOpen} title={name}
            okText={widgetConfig?.okText ?? 'Submit action'}
            onOk={onPerformRowAction} onCancel={onCancel} >
            <div className="text-black text-sm">
                {getDescription()}
            </div>

            <div className="py-2  rounded-sm mt-6 mb-10">
                {inputs.map((input, index) => {
                    console.log(input);
                    const { inputType, inputName, defaultValue, inputOptions } = input
                    return <div className="my-4 flex flex-col gap-y-1">
                        <span>{inputName}</span>
                        <WidgetInputComponent
                            inputType={inputType}
                            value={undefined}
                            //  value={userInputs[`${widget.type}.${widget.key}`]}
                            onChange={(x) =>
                                console.log(x)
                                //onUserInput(`${widget.type}.${widget.key}`, x)
                            }
                            defaultValue={defaultValue}
                            extra={inputOptions}
                        />
                    </div>
                })}
            </div>

            <span className="block mt-4 mb-4 text-gray-500">Actions performed in the following order:</span>
            <div className="bg-gray-200 py-2 px-3 rounded-sm mb-10">
                {actions.map((action, index) => {
                    return <span className="text-gray-600 text-xs block my-2">({index + 1}) {getAction(action?.action)}</span>
                })}
            </div>
        </Modal>
    )
}

export default InputsRowAction