import React, { useState, useEffect, useContext } from "react";

import {
  getInputExtraWidgetObj,
  makeClientSideApiRequest,
  makePostRequest,
} from "../../../utils";
import { ContextServiceContext } from "../../../context/schema_context";
import { message } from "antd";
import CommonTable from "../../Common/common_table";
import ManageAppDrawer from "../manage_app_drawer/index.tsx";
import { AppNav } from "./app_nav.tsx";
import AppRowInfo from "../row_info_drawer/row_info_drawer.tsx";
import { WidgetInputComponent } from "../../Common/widgets/WidgetInputComponent";

const SelectedApp = ({ selectedApp = {}, onToggleSelectedWorkFlow }) => {
  const [userInputs, setUserInputs] = useState({});

  const [selectedRow, setSelectedRow] = useState({});
  const contextService = useContext(ContextServiceContext);
  const [appResponse, setAppResponse] = useState({
    result: [],
    total: 0,
    time_taken: 0,
  });
  const [loading, setLoading] = useState(false);
  const [openManageAppDrawer, setOpenManageAppDrawer] = useState(false);
  const [openRowData, setOpenRowData] = useState(false);

  const [app, setApp] = useState(selectedApp);
  const [isPreview, setIsPreview] = useState(false);

  const { widgets = "[]" } = app ?? {};
  const parsedWidgets = JSON.parse(widgets);
  const filterWidgets = parsedWidgets?.filter((wdg) => wdg.type === "filter");
  const rowWidgets = parsedWidgets?.filter((wdg) => wdg.type === "action");
  const appWidgets = parsedWidgets?.filter((wdg) => wdg.type === "app");


  useEffect(() => {
    onLoadApp();
  }, [app]);

  // add default values
  useEffect(() => {
    const defaultValues = {};
    parsedWidgets?.forEach((widget) => {
      defaultValues[`${widget.type}.${widget.key}`] =
        widget.widgetConfig.defaultValue;
    });

    setUserInputs(defaultValues);
  }, []);

  const onLoadApp = async (isFilter) => {
    const datasourceId = contextService.schema.databaseSchema.datasourceId;
    setLoading(true);
    try {
      let response;
      if (isPreview || (isPreview && isFilter)) {
        response = await makePostRequest(
          `/api/datasource/${datasourceId}/backend/runFilter/${selectedApp.id
          }?${isPreview ? "preview=true" : ""}`,
          {
            inputs: Object.keys(userInputs).map((input) => {
              return { [`@${input}`]: userInputs[input] };
            }),
            command: JSON.parse(app.command),
            keywords: app.keywords,
          }
        );
      } else if (isFilter) {
        response = await makePostRequest(
          `/api/datasource/${datasourceId}/backend/runFilter/${selectedApp.id}`,
          {
            inputs: Object.keys(userInputs).map((input) => {
              return { [`@${input}`]: userInputs[input] };
            }),
          }
        );
      } else {
        response = await makeClientSideApiRequest(
          `/api/datasource/${datasourceId}/backend/runFilter/${selectedApp.id}`
        );
      }

      if (response.status === "success") {
        setAppResponse(response.data);
        setLoading(false);
      } else {
        message.error(response.reason);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const onPreviewToggle = (app) => {
    if (app) {
      setIsPreview(true);
      setUserInputs(app.inputs);
      setApp(app);
    }
    setOpenManageAppDrawer(false);
  };

  const onToggleOpenApp = (isOpen, app) => {
    if (isOpen) setIsPreview(false);
    if (app) setApp(app);
    setOpenManageAppDrawer(isOpen);
  };

  const onUserInput = (key, value) => {
    console.log(key, value);
    setUserInputs({ ...userInputs, [key]: value });
  };

  const onApplyFilter = () => {
    onLoadApp(true);
  };

  const onToggleRowDrawer = (status, row) => {
    setOpenRowData(status);
    setSelectedRow(row);
  };

  return (
    <>
      {openManageAppDrawer && (
        <ManageAppDrawer
          onToggleAppDrawer={onToggleOpenApp}
          openAppDrawer={openManageAppDrawer}
          onPreviewToggle={onPreviewToggle}
          existingApp={app}
        />
      )}

      <AppRowInfo
        rowWidgets={rowWidgets}
        selectedRow={selectedRow}
        onToggleDataDrawer={(status) => onToggleRowDrawer(status)}
        openDataDrawer={openRowData}
      />

      <div className="w-11/12 mx-auto pt-5">
        <AppNav
          loading={loading}
          isPreview={isPreview}
          isHasFilters={filterWidgets?.length > 0}
          onApplyFilter={onApplyFilter}
          onToggleSelectedWorkFlow={onToggleSelectedWorkFlow}
          onToggleAppDrawer={(status) => onToggleOpenApp(status)}
          appName={app.name}
          description={app.description}
        />

        <div className="">
          <div className="flex flex-wrap gap-y-3 mt-8 bg-white shadow rounded my relative">
            <div className="absolute right-2 top-1"></div>
            {filterWidgets?.map((widget) => {
              return (
                <div className="p-1  w-3/12">
                  <div className="rounded-lg  p-4 mx-3 flex flex-col w-full gap-y-2">
                    <span className="my-">{widget.name}</span>
                    <WidgetInputComponent
                      value={userInputs[`${widget.type}.${widget.key}`]}
                      inputType={widget.widgetConfig.inputType}
                      onChange={(x) =>
                        onUserInput(`${widget.type}.${widget.key}`, x)
                      }
                      extra={getInputExtraWidgetObj(widget)}
                      defaultValue={widget.widgetConfig.defaultValue}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <CommonTable
            loading={loading}
            timeTaken={appResponse.time_taken}
            total={appResponse.result.length}
            data={appResponse.result}
            onRowSelected={(row) => onToggleRowDrawer(true, row)}
          />
        </div>
      </div>
    </>
  );
};

export default SelectedApp;
