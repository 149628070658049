import { Component } from "react";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
import { getRandomColor } from "../../utils";
import { Button, Select } from "antd";
import { WarningFilled } from "@ant-design/icons";
import PropTypes from "prop-types";
import { QuestionConfigContext } from "../../context/question_config_context";

const getChartData = (labels, graphHeaders, data = {}) => {
  return {
    labels: labels,
    datasets: graphHeaders.map((header) => {
      return {
        label: header,
        data: data[header],
        backgroundColor: getRandomColor(),
      };
    }),
  };
};

const getChartOption = (title) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: title,
      },
    },
  };
};

class LineChart extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    onTogglePresentation: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    onSetQuestionConfig: PropTypes.func.isRequired,
    questionConfig: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (
      this.context.questionConfig?.graphConfig?.lineChart &&
      this.context.questionConfig?.graphConfig?.lineChart?.labelKey &&
      this.context.questionConfig?.graphConfig?.lineChart?.dataKeys
    ) {
      this.onAddDefaults();
    }
  }

  state = {
    defaultOptions: { labelKey: undefined, dataKey: undefined },
    graphData: { data: {} },
  };

  onAddDefaults = () => {
    const { labelKey, dataKeys } =
      this.context.questionConfig?.graphConfig?.lineChart || {};

    const labelPicked = this.context.data.map((row) => row[labelKey]);
    const data = {};
    for (const index in dataKeys) {
      data[dataKeys[index]] = this.context.data.map(
        (row) => row[dataKeys[index]]
      );
    }
    this.setState({
      ...this.state,
      graphData: {
        ...this.state.graphData,
        labels: labelPicked,
        data: data,
      },
    });
  };

  onChageLabelOption = (labelKey) => {
    const picked = this.context.data.map((row) => row[labelKey]);
    this.setState({
      ...this.state,
      graphData: { ...this.state.graphData, labels: picked },
      defaultOptions: { ...this.state.defaultOptions, labelKey: labelKey },
    });

    this.context.onSetQuestionConfig("graphConfig", {
      section: "lineChart",
      data: { ...this.state.defaultOptions, labelKey: labelKey },
    });
  };

  onPushLineDataArr = (dataKeys = []) => {
    this.setState({
      ...this.state,
      graphData: { ...this.state.graphData, data: {} },
      defaultOptions: { ...this.state.defaultOptions, dataKeys: dataKeys },
    });

    for (const key in dataKeys) {
      this.onPushLineData(dataKeys[key]);
    }
    this.context.onSetQuestionConfig("graphConfig", {
      section: "lineChart",
      data: { ...this.state.defaultOptions, dataKeys: dataKeys },
    });
  };

  onPushLineData = (labelKey) => {
    const currLineData = { ...(this.state.graphData.data || {}) };
    currLineData[labelKey] = this.context.data.map((row) => row[labelKey]);

    this.setState({
      ...this.state,
      graphData: { ...this.state.graphData, data: currLineData },
    });
  };

  render() {
    const headers = Object.keys(this.context.data[0]).map((header, index) => {
      return { value: header, label: header };
    });

    const { labels = [], data = {} } = this.state.graphData;
    const { labelKey, dataKeys } =
      this.context.questionConfig?.graphConfig?.lineChart || {};

    const graphHeaders = Object.keys(data);

    return (
      <div className="mt-10 flex justify-center gap-x-4">
        <div className="text-black  w-4/12 shadow-lg p-4 min-h-60 rounded">
          <div className="w-full">
            <span className="text-xs mb-6 mt-2 block font-bold">
              Select data label
            </span>

            <div className="flex items-center gap-x-3">
              <Select
                className="w-full"
                placeholder="Select labels key"
                defaultValue={labelKey}
                onChange={this.onChageLabelOption}
                options={headers}
              />
            </div>
          </div>

          <div className="w-full mt-8">
            <span className="text-xs mb-6 mt-2 block font-bold">
              Select data columns
            </span>

            <div className="flex items-center gap-x-3">
              <Select
                className="w-full"
                mode="multiple"
                defaultValue={dataKeys}
                allowClear
                placeholder="Select column keys"
                onChange={this.onPushLineDataArr}
                options={headers}
              />
            </div>
          </div>
        </div>
        {graphHeaders && graphHeaders.length > 0 && (
          <div className="mt-10 w-6/12 mx-auto">
            <Line
              options={getChartOption(this.context.question)}
              data={getChartData(labels, graphHeaders, data)}
            />
            ;
          </div>
        )}
        {!graphHeaders ||
          (graphHeaders.length < 1 && (
            <div className="mt-10 w-6/12 mx-auto text-center">
              <WarningFilled className="text-orange-600 text-4xl block mb-4 block" />
              <h1 className="text-black text-sm">
                Select label and data columns to show the line chart
              </h1>
            </div>
          ))}
      </div>
    );
  }
}

LineChart.contextType = QuestionConfigContext;
export default LineChart;
