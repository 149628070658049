import { Input, Modal, Select } from "antd";
import { MButton } from "../../Common/buttons";

export const AppNameModal = ({
  appDescription,
  appName,
  setAppName,
  setAppDescription,
  openModal,
  toggleModal,
  toggleModalAndSave,
}) => {
  return (
    <Modal
      open={openModal}
      onCancel={() => toggleModal(false)}
      footer={null}
      title={"Save app"}
    >
      <div className="p-3 flex flex-col mx-auto gap-y-5">
        <div className={` py-0 gap-x-2 my-3 transition-all duration-200`}>
          <div className="w-full py-4 px-4 bg-gray-100 rounded my-3">
            <span className="block py-2 text-xs">App Name</span>
            <Input
              status={!appName ? "error" : ""}
              required={true}
              value={appName}
              onChange={(input) => setAppName(input.target.value)}
              className={``}
              placeholder={`Enter app name`}
            />
          </div>

          <div className="w-full py-4 px-4 bg-gray-100 rounded">
            <span className="block py-2 text-xs">App description</span>
            <Input.TextArea
              status={!appDescription ? "error" : ""}
              required={true}
              value={appDescription}
              onChange={(input) => setAppDescription(input.target.value)}
              className={``}
              placeholder={`Enter app description`}
            />
          </div>
        </div>

        <div className="w-full">
          <MButton
            onClick={toggleModalAndSave}
            className={"w-full"}
            label={"Save App"}
          />
        </div>
      </div>
    </Modal>
  );
};
