import React, { useContext, useState } from "react";
import EmptyWorkflow from "./empty_apps";
import SavedWorkFlows from "./saved_app/saved_apps";
import { ContextServiceContext } from "../../context/schema_context";
import { truncate } from "../../utils";
import SelectedApp from "./selected_app/selected_app";

const ManageApps = ({ tabId }) => {
  const [selectedApp, setSelectedApp] = useState();
  const [selectedUpdateWorkflow, setSelectedUpdateWorkflow] = useState();
  const [savedWorkflows, setSavedWorkflows] = useState([]);
  const [isNewWorkFlow, setIsNewWorkFlow] = useState(false);
  const contextService = useContext(ContextServiceContext);

  const toggleCreateWorkflow = (status = true) => {
    setIsNewWorkFlow(status);
  };

  const onSelectWorkflow = (workflow) => {
    console.log(workflow);
    setIsNewWorkFlow(false);
    setSelectedApp(workflow);
    setTabTitle(workflow.name);
  };

  const setTabTitle = (tabTitle) => {
    const tabs = [...contextService.tabs.tabs];
    const setTab = contextService.tabs.setTabs;
    tabs.find((tab) => tab.key === tabId).label = truncate(`${tabTitle} - app`);
    setTab(tabs);
  };

  const onToggleSelectedWorkFlow = () => {
    setSelectedApp(undefined);
    setIsNewWorkFlow(false);
    setSelectedUpdateWorkflow(undefined);
  };

  const defaultScreen = (selectedApp === undefined);
  console.log(selectedApp);

  const isEmptyScreen = !savedWorkflows && !selectedApp && !isNewWorkFlow;
  return (
    <div className="relative flex flex-col">
      {/*       {isEmptyScreen && (
        <EmptyWorkflow onCreateWorkflow={toggleCreateWorkflow} />
      )} */}


      {defaultScreen && (
        <SavedWorkFlows
          setSavedWorkflows={setSavedWorkflows}
          savedWorkflows={savedWorkflows}
          onSelectWorkflow={onSelectWorkflow}
          onCreateWorkflow={toggleCreateWorkflow}
        />
      )}

      {selectedApp && (
        <SelectedApp
          onToggleSelectedWorkFlow={onToggleSelectedWorkFlow}
          selectedApp={selectedApp}
        />
      )}
    </div>
  );
};

export default ManageApps;
