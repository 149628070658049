import React, { useContext, useEffect, useState } from "react";
import { MButton } from "../../Common/buttons";
import { DownOutlined, RightCircleOutlined } from "@ant-design/icons";
import {
  makeClientSideApiRequest,
  makeDeleteRequest,
  truncate,
} from "../../../utils";
import { ContextServiceContext } from "../../../context/schema_context";
import { Button, Dropdown, Space, Spin, Typography, message } from "antd";
import ManageAppDrawer from "../manage_app_drawer/index.tsx";
import EmptyWorkflow from "../empty_apps";

const SavedApps = ({ savedWorkflows, setSavedWorkflows, onSelectWorkflow }) => {
  const contextService = useContext(ContextServiceContext);
  const [loading, setLoading] = useState(true);
  const [openDataDrawer, setOpenDataDrawer] = useState(false);

  useEffect(() => {
    loadWorkFlows();
  }, []);

  const loadWorkFlows = async () => {
    try {
      setLoading(true);
      const response = await makeClientSideApiRequest(
        `/api/datasource/${contextService.schema.databaseSchema.datasourceId}/backend/fetchBackendFilters`
      );
      if (!response.data) {
        setSavedWorkflows(undefined);
        message.warning(response.reason);
        return;
      } else if (response.data.data?.length > 0) {
        setSavedWorkflows(response.data.data);
      } else {
        setSavedWorkflows(undefined);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSavedWorkflows(undefined);
    }
  };

  const deleteWorkFlow = async (appId) => {
    try {
      setLoading(true);
      const response = await makeDeleteRequest(
        `/api/datasource/${contextService.schema.databaseSchema.datasourceId}/backend/deleteBackendFilter/${appId}`
      );
      if (response.status == "success") {
        message.success("App deleted");
        loadWorkFlows();
      } else {
        message.warning(response.reason);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Request failed");
    }
  };

  const generateDropDown = (app) => {
    return [
      {
        key: "2",
        label: (
          <span
            className="block w-full cursor-pointer"
            onClick={() => deleteWorkFlow(app.id)}
          >
            {" "}
            Delete
          </span>
        ),
      },
    ];
  };

  const onToggleAppDrawer = (status, needsRefresh) => {
    setOpenDataDrawer(status);
    if (needsRefresh) {
      loadWorkFlows();
    }
  };

  return (
    <>
      <ManageAppDrawer
        onToggleAppDrawer={onToggleAppDrawer}
        openAppDrawer={openDataDrawer}
      />

      {(!savedWorkflows) && (
        <EmptyWorkflow onCreateWorkflow={onToggleAppDrawer} />
      )}

      {savedWorkflows && savedWorkflows.length < 1 && (
        <div className="h-screen h-full  flex flex-col justify-center items-center">
          {" "}
          <div className="">
            {" "}
            <Spin spinning className="text-lg text-center block"></Spin>{" "}
          </div>
        </div>
      )}

      {savedWorkflows && (
        <Spin spinning={loading}>
          <div className="w-11/12 mx-auto">
            <div className="my-8 flex justify-between items-center">
              <h1 className="px-3 py-3 text-[14px] rounded-full bg-gray-100 font-bold">
                Saved apps
              </h1>
              <MButton
                onClick={() => onToggleAppDrawer(true)}
                label={"Create app"}
              />
            </div>

            <div className="flex  flex-wrap	gap-y-4 text-sm">
              {savedWorkflows?.map((appItem) => (
                <div
                  key={appItem.id}
                  className="w-3/12 cursor-pointer flex-wrap relative h-28"
                >
                  <div className="mx-2 relative  h-28">
                    <Button
                      loading={loading}
                      disabled={loading}
                      onClick={() => onSelectWorkflow(appItem)}
                      className="text-gray-500 absolute bottom-1 right-1 z-40 text-xs font-bold mx-2 px-1 flex flex-row gap-x-1 items-center"
                      type="text"
                    >
                      Open app
                      <RightCircleOutlined />
                    </Button>
                    <div className="text-xs flex flex-col p-0 bg-white shadow-sm p-3 rounded gap-y-2 h-full">
                      <div className=""> {truncate(appItem.name)}</div>
                      <div className="h-10 text-gray-400">
                        {" "}
                        {appItem.description}{" "}
                        <Dropdown
                          className="absolute top-1 right-2"
                          menu={{
                            items: generateDropDown(appItem),
                          }}
                        >
                          <Typography.Link>
                            <Space>
                              <DownOutlined />
                            </Space>
                          </Typography.Link>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Spin>
      )}
    </>
  );
};

export default SavedApps;
