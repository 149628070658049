import { Checkbox, Input, Modal, Segmented, Switch, message } from "antd";
import { useContext, useState } from "react";
import { ContextServiceContext } from "../../../context/schema_context";
import { Str } from "../../../utils/constants";
import { BackendPermissions } from "./BackendPermissions";
import { DashboardPermissions } from "./DashboardPermissions";
import { MButton } from "../../Common/buttons";
import { makePostRequest } from "../../../utils";
import { useParams } from "react-router-dom";

const TeamInfoModal = ({ openModal, onToggleModal }) => {
  const [loading, setLoading] = useState(false);
  const [memberInfoInput, setMemberInfoInput] = useState({});
  const schemaContext = useContext(ContextServiceContext);
  const [backendPermissions, setBackendPermissions] = useState({});
  const [savedDashboards, setSavedDashboards] = useState([]);
  const [groupSelect, setGroupSelect] = useState({});
  const [forceSet, onForceSet] = useState(false);
  const [segment, setSegment] = useState("Backend permissions");
  const [dashboardPermissions, setDashboardPermissions] = useState([]);
  const [dashboardGroupSelect, setDashboardGroupSelect] = useState(false);

  const { schema = {} } = schemaContext;
  const { databaseSchema = {} } = schema;
  const { columns = [], tables = [] } = databaseSchema;
  const params = useParams();

  const isBackendPermissionsSegment = segment === "Backend permissions";

  const onChangeInput = (key, value) => {
    const getInputsState = { ...memberInfoInput };
    getInputsState[key] = value;
    setMemberInfoInput(getInputsState);
  };

  const onChangeBackendPermissionInput = (key, value) => {
    const getPermissionState = { ...backendPermissions };
    getPermissionState[key] = [...value];
    setBackendPermissions(getPermissionState);

    groupSelect[Str.backendPermissionOpts.read] = value.includes(
      Str.backendPermissionOpts.read
    )
      ? groupSelect[Str.backendPermissionOpts.read]
      : false;
    groupSelect[Str.backendPermissionOpts.write] = value.includes(
      Str.backendPermissionOpts.write
    )
      ? groupSelect[Str.backendPermissionOpts.write]
      : false;

    groupSelect[Str.backendPermissionOpts.update] = value.includes(
      Str.backendPermissionOpts.update
    )
      ? groupSelect[Str.backendPermissionOpts.update]
      : false;

    groupSelect[Str.backendPermissionOpts.delete] = value.includes(
      Str.backendPermissionOpts.delete
    )
      ? groupSelect[Str.backendPermissionOpts.delete]
      : false;
  };

  const onChangeGroupSelect = (key, value) => {
    //cool stuff
    switch (key) {
      case "Read":
        toggleAllTables(value, Str.backendPermissionOpts.read);
        break;
      case "Write":
        toggleAllTables(value, Str.backendPermissionOpts.write);
        break;
      case "Update":
        toggleAllTables(value, Str.backendPermissionOpts.update);
        break;
      case "Widget":
        toggleAllTables(value, Str.backendPermissionOpts.delete);
        break;
      default:
        break;
    }
  };

  const toggleAllTables = (status, type) => {
    const getPermissionState = { ...backendPermissions };
    const markAllAsRead = (table, status) => {
      // remove read if already available
      const getTablePermissions = getPermissionState[table] ?? [];
      const validated = getTablePermissions.filter((pm) => {
        return pm !== type;
      });

      if (status === true) {
        getPermissionState[table] = [...validated, type];
      } else {
        getPermissionState[table] = validated;
      }

      setBackendPermissions(getPermissionState);
      onForceSet(!forceSet);
    };

    for (const index in tables) {
      const table = tables[index];
      markAllAsRead(table, status);
      groupSelect[type] = status;
      setGroupSelect(groupSelect);
    }
  };

  const onSubmitButtonClicked = async () => {
    if (isBackendPermissionsSegment) {
      setSegment("Dashboard permissions");
    } else {
      if (!memberInfoInput.email) {
        message.error("Email is required");
      } else if (!memberInfoInput.fullname) {
        message.error("Full name is required");
      } else if (!memberInfoInput.user_role_description) {
        message.error("User role description is required");
      } else {
        await submitInvite();
      }
    }
  };

  const onChangeDashboardPermission = (dashboardId, permission) => {
    const index = dashboardPermissions.findIndex((pm) => pm === dashboardId);
    if (!permission) {
      const cloned = [...dashboardPermissions];
      cloned.splice(index, 1);
      setDashboardPermissions(cloned);
      setDashboardGroupSelect(false);
    } else if (index === -1) {
      setDashboardPermissions([...dashboardPermissions, dashboardId]);
    }
  };

  const onChangeDashboardGroupSelect = (status, dashboards) => {
    setDashboardGroupSelect(status);
    if (!status) {
      setDashboardPermissions([]);
    } else {
      setDashboardPermissions(dashboards);
    }
  };

  const onCloseModal = (refresh = false) => {
    setLoading(false);
    setMemberInfoInput({});
    setBackendPermissions({});
    setGroupSelect({});
    onForceSet(false);
    setSegment("Backend permissions");
    setDashboardPermissions([]);
    setDashboardGroupSelect(false);
    onToggleModal(false, refresh);
  };

  const submitInvite = async () => {
    setLoading(true);
    try {
      const response = await makePostRequest(
        `/api/datasource/${params?.id}/team/invite`,
        {
          ...memberInfoInput,
          dashboardPermissions: dashboardPermissions ?? [],
          backendPermissions: backendPermissions ?? {},
        }
      );

      if (response.status === "success") {
        message.success("Member invite sent");
        onCloseModal(true);
      } else {
        setLoading(false);
        message.error(response.reason);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={openModal}
      onCancel={() => onCloseModal(false)}
      footer={null}
      title={"Invite team member"}
      width={700}
      destroyOnClose={true}
    >
      <div className="py-5 flex flex-col gap-x-2 mx-auto">
        <div className="flex flex-wrap gap-y-3 gap-x-4">
          <div className="w-5/12">
            <span className="block py-2 text-xs">Fullname</span>
            <Input
              status={!memberInfoInput.fullname ? "error" : ""}
              required={true}
              value={memberInfoInput["fullname"]}
              onChange={(input) =>
                onChangeInput("fullname", input.target.value)
              }
              className={``}
              placeholder={`Enter member full name`}
            />
          </div>

          <div className="w-5/12">
            <span className="block py-2 text-xs">Email address</span>
            <Input
              status={!memberInfoInput.email ? "error" : ""}
              required={true}
              value={memberInfoInput["email"]}
              onChange={(input) =>
                onChangeInput("email", input.target.value)
              }
              className={``}
              placeholder={`Enter member email address `}
            />
          </div>

          <div className="w-5/12">
            <span className="block py-2 text-xs">User role description</span>
            <Input
              status={!memberInfoInput.user_role_description ? "error" : ""}
              required={true}
              value={memberInfoInput["user_role_description"]}
              onChange={(input) =>
                onChangeInput("user_role_description", input.target.value)
              }
              className={``}
              placeholder={`E.g Customer service rep, Engineering`}
            />
          </div>
        </div>

        <div className="py-2">
          <span className="block text-xs py-3">Member Permissions</span>
          <Segmented
            block
            value={segment}
            onChange={(x) => setSegment(x)}
            options={["Backend permissions", "Dashboard permissions"]}
          />

          {segment === "Backend permissions" && (
            <BackendPermissions
              tables={tables}
              onChangeBackendPermissionInput={onChangeBackendPermissionInput}
              backendPermissions={backendPermissions}
              groupSelect={groupSelect}
              onChangeGroupSelect={onChangeGroupSelect}
            />
          )}
          {segment === "Dashboard permissions" && (
            <DashboardPermissions
              onChangeDashboardPermission={onChangeDashboardPermission}
              dashboardPermissions={dashboardPermissions}
              dashboardGroupSelect={dashboardGroupSelect}
              onChangeDashboardGroupSelect={onChangeDashboardGroupSelect}
              setLoading={setLoading}
              loading={loading}
              savedDashboards={savedDashboards}
              setSavedDashboards={setSavedDashboards}
            />
          )}
        </div>

        <div className="flex justify-center mt-3">
          <MButton
            disabled={loading}
            loading={loading}
            onClick={onSubmitButtonClicked}
            label={
              isBackendPermissionsSegment ? "Continue invite" : "Submit invite"
            }
            className={"py-2"}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TeamInfoModal;
