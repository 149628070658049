import {
  Card,
  Spin,
  Statistic,
  message,
  DatePicker,
  Button,
  Modal,
  Input,
  Select,
  Dropdown,
  Space,
} from "antd";
import {
  CloseCircleOutlined,
  ArrowUpOutlined,
  SettingOutlined,
  WarningOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import {
  formatNum,
  makeClientSideApiRequest,
  makePostRequest,
} from "../../../utils";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { MButton } from "../buttons";
import { ContextServiceContext } from "../../../context/schema_context";
import { Str } from "../../../utils/constants";
import {
  getDateTimeCols,
  getTableColumnsFromSchema,
  getTimestampCol,
} from "../../../utils/schema_utils";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

export const RangeRowCountWidget = ({
  table,
  withSumTotal,
  withCard = true,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [grandTotal, setGrandTotal] = useState(0);
  const [config, setConfig] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);
  const [selectionType, setSelectionType] = useState("month");

  const params = useParams();
  const schemaContext = useContext(ContextServiceContext);
  const [selectedCol, setSelectedCol] = useState();

  const { schema = {} } = schemaContext;
  const { databaseSchema = {} } = schema;
  const { columns = [] } = databaseSchema;

  const selectionItems = [
    {
      label: (
        <span className="" onClick={() => setSelectionType("day")} href="#">
          Yesterday
        </span>
      ),
      title: "Yesterday",
      value: "day",
    },
    {
      label: (
        <span onClick={() => setSelectionType("month")} href="#">
          One month ago
        </span>
      ),
      title: "One month ago",
      value: "month",
    },
    {
      label: (
        <span onClick={() => setSelectionType("week")} href="#">
          One week ago
        </span>
      ),
      title: "One week ago",
      value: "week",
    },
    {
      label: (
        <span onClick={() => setSelectionType("year")} href="#">
          One year ago
        </span>
      ),
      title: "One year ago",
      value: "year",
    },
    {
      label: (
        <span onClick={() => setSelectionType("custom")} href="#">
          Select custom range
        </span>
      ),
      value: "custom",
    },
  ];

  useEffect(() => {
    const today = dayjs(new Date());
    switch (selectionType) {
      case "year":
        onSelectRange(null, [
          today.subtract(1, "year").format(dateFormat),
          today.format(dateFormat),
        ]);
        break;
      case "month":
        onSelectRange(null, [
          today.subtract(1, "month").format(dateFormat),
          today.format(dateFormat),
        ]);
        break;
      case "week":
        onSelectRange(null, [
          today.subtract(1, "week").format(dateFormat),
          today.format(dateFormat),
        ]);
        break;
      case "day":
        onSelectRange(null, [
          today.subtract(1, "day").format(dateFormat),
          today.format(dateFormat),
        ]);
        break;
      case "custom":
        onSelectRange(null, [
          today.subtract(1, "month").format(dateFormat),
          today.format(dateFormat),
        ]);
        break;
      default:
        break;
    }
  }, [selectionType]);

  let percentageDiff = 0;
  if (grandTotal && data !== undefined) {
    percentageDiff = (data / grandTotal) * 100;

    console.log(percentageDiff);
  }

  const today = dayjs(new Date());
  const [rangeFrom, setRangeFrom] = useState(
    today.subtract(1, "month").format(dateFormat)
  );
  const [rangeTo, setRangeTo] = useState(today.format(dateFormat));

  //console.log(tableCols);

  useEffect(() => {
    // console.log(getDateTimeCols(databaseSchema, table));
    fetchConfig();
  }, []);

  const submitConfigAndFetch = async (field) => {
    await saveConfig(field);
    await fetchConfig();
  };

  const toggleModal = async (toggle, selectedCol) => {
    setOpenModal(toggle);
    if (!toggle && selectedCol) {
      await submitConfigAndFetch(selectedCol);
      // refresh config
      // refresh widget
      setOpenModal(toggle);
    } else {
      setOpenModal(toggle);
    }
  };

  const fetchConfig = async () => {
    setLoading(true);
    try {
      if (!config?.id) {
        const defaultTimestamp = getTimestampCol(databaseSchema, table);
        console.log(defaultTimestamp);
        if (defaultTimestamp) {
          const { Field } = defaultTimestamp;
          await saveConfig(Field, true);
        }
      }
      const response = await makePostRequest(
        `/api/datasource/${params?.id}/widgets/getWidgetConfig`,
        {
          id: config?.id,
          table: table,
          section: "backend",
          widget_name: "RangeRowCountWidget",
        }
      );

      if (response.status === "success") {
        setLoading(false);
        console.log(response.data);
        setConfig(response.data);
        fetchRangeRowCountWidget(response.data);
      } else {
        setLoading(false);
        // message.error(response.reason);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const saveConfig = async (timestamp_col, stillLoading) => {
    setLoading(true);
    try {
      const response = await makePostRequest(
        `/api/datasource/${params?.id}/widgets/storeWidgetConfig`,
        {
          id: config?.id,
          table: table,
          section: "backend",
          widget_name: "RangeRowCountWidget",
          config: {
            timestamp_col: timestamp_col,
          },
        }
      );

      if (response.status === "success") {
        if (!stillLoading) {
          setLoading(false);
        }
      } else {
        setLoading(false);
        message.error(response.reason);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const fetchRangeRowCountWidget = async (newConfig) => {
    setLoading(true);
    try {
      const { config: getConfig = "{}" } = newConfig ?? config ?? {};
      const { timestamp_col } = JSON.parse(getConfig);
      if (!timestamp_col) return; // throw an error

      const response = await makePostRequest(
        `/api/datasource/${
          params?.id
        }/backend?table=${table}&offset=${0}&pageSize=${1000}&auto_order_by_desc=true&is_count_only=true`,
        {
          groupFilters: [
            {
              operation: "and",
              filters: [
                {
                  column: timestamp_col,
                  operation: "greater_equal",
                  value: rangeFrom,
                },
                {
                  column: timestamp_col,
                  operation: "lesser_equal",
                  value: rangeTo,
                },
              ],
            },
          ],
        }
      );

      if (response.status === "success") {
        setLoading(false);
        console.log(response.data?.total_results);
        setData(response.data?.total_results ?? 0);
        setGrandTotal(response.data?.table_total ?? 0);
      } else {
        setLoading(false);
        message.error(response.reason);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (config) {
      fetchRangeRowCountWidget();
    }
  }, [rangeFrom, rangeTo]);

  const onSelectRange = (e, s) => {
    console.log(s);
    setRangeFrom(s[0]);
    setRangeTo(s[1]);
  };

  console.log(withSumTotal);
  //
  return (
    <div className="w-full relative z-40">
      <ManageWidgetConfigModal
        table={table}
        toggleModal={toggleModal}
        openModal={openModal}
        colOptions
        existingConfig={config}
      />
      <Button
        size="small"
        className="absolute bottom-3 right-3 z-40"
        icon={<SettingOutlined />}
        onClick={() => toggleModal(true)}
      />

      {!withCard && !config && !loading && (
        <div>
          <WarningOutlined className=" text-lg bottom-3 right-12 z-40 text-red-400" />

          <div className=" text-lg bottom-2 right-20 z-40 text-gray-400">
            <span className="text-xs ">Configure timestamp column</span>
          </div>
        </div>
      )}

      {!withCard && !config && loading && (
        <div>
          <Spin size="small" spinning={loading} />
        </div>
      )}

      {!withCard && config && (
        <Spin size="small" spinning={loading}>
          <div className="flex flex-col justify-between items-start">
            <span className=" text-black opacity-50	mb-2 block  w-40 block overflow-hidden text-ellipsis truncate">
              Added since
            </span>
            {selectionType !== "custom" && (
              <Dropdown
                menu={{
                  items: selectionItems,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <span className="flex items-center gap-x-2 rounded-full bg-orange-100 px-2 border-2 border-orange-300">
                      {
                        selectionItems.find((it) => it.value === selectionType)
                          ?.title
                      }
                      <CaretDownOutlined className="text-xs" />
                    </span>
                  </Space>
                </a>
              </Dropdown>
            )}
            {selectionType === "custom" && (
              <div className="flex justify-between gap-x-2">
                <RangePicker
                  onChange={onSelectRange}
                  className="w-[240px] outline-none"
                  defaultValue={[dayjs(rangeFrom), dayjs(rangeTo)]}
                  format={dateFormat}
                />

                <Button
                  onClick={() => setSelectionType("month")}
                  icon={<CloseCircleOutlined />}
                />
              </div>
            )}
            <div className="mt-3">
              <ArrowUpOutlined />
              <span className="font-normal text-xl">
                {" "}
                {data ?? 0} (+ {percentageDiff.toFixed(2)} %)
              </span>
            </div>

            {withSumTotal && (
              <div>
                <span className="text-gray-500 px-5 text-xs">
                  {" "}
                  Sum total: {formatNum(grandTotal)}{" "}
                </span>
              </div>
            )}
          </div>
        </Spin>
      )}

      {withCard && !config && !loading && (
        <WarningOutlined className="absolute text-lg bottom-3 right-12 z-40 text-red-400" />
      )}

      {withCard && !config && !loading && (
        <div className="absolute text-lg bottom-2 right-20 z-40 text-gray-400">
          <span className="text-xs ">Configure timestamp column</span>
        </div>
      )}

      {withCard && (
        <Card
          loading={!config || loading}
          className="w-full  h-36  relative"
          bordered={false}
        >
          <div className="flex flex-col justify-between items-start">
            <span className=" text-black opacity-50	mb-2 block">
              Total {table} added since
            </span>
            {selectionType !== "custom" && (
              <Dropdown
                menu={{
                  items: selectionItems,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <span className="flex items-center gap-x-2 rounded-full bg-orange-100 px-2 border-2 border-orange-300">
                      {
                        selectionItems.find((it) => it.value === selectionType)
                          ?.title
                      }
                      <CaretDownOutlined className="text-xs" />
                    </span>
                  </Space>
                </a>
              </Dropdown>
            )}
            {selectionType === "custom" && (
              <div className="flex justify-between gap-x-2">
                <RangePicker
                  onChange={onSelectRange}
                  className="w-[240px] outline-none"
                  defaultValue={[dayjs(rangeFrom), dayjs(rangeTo)]}
                  format={dateFormat}
                />

                <Button
                  onClick={() => setSelectionType("month")}
                  icon={<CloseCircleOutlined />}
                />
              </div>
            )}
            <div className="mt-3">
              <ArrowUpOutlined />
              <span className="font-normal text-xl">
                {" "}
                {data ?? 0} (+ {percentageDiff.toFixed(2)} %)
              </span>
            </div>

            {withSumTotal && (
              <div>
                <span className="text-gray-500 px-5 text-xs">
                  {" "}
                  Sum total: {formatNum(grandTotal)}{" "}
                </span>
              </div>
            )}
          </div>
        </Card>
      )}
    </div>
  );
};

const ManageWidgetConfigModal = ({
  table,
  openModal,
  toggleModal,
  existingConfig = {},
}) => {
  const schemaContext = useContext(ContextServiceContext);
  const [selectedCol, setSelectedCol] = useState();

  const { schema = {} } = schemaContext;
  const { databaseSchema = {} } = schema;
  const { columns = [] } = databaseSchema;
  const tableCols = columns[table];
  const formatOptions = tableCols.map((col) => {
    return {
      label: col.Field,
      value: col.Field,
    };
  });

  const { config: getConfig = "{}" } = existingConfig;
  const { timestamp_col } = JSON.parse(getConfig);

  if (timestamp_col && !selectedCol) {
    setSelectedCol(timestamp_col);
  }

  const onInputName = (val) => {
    setSelectedCol(val);
  };

  return (
    <Modal
      open={openModal}
      onCancel={() => toggleModal(false)}
      footer={null}
      title={"Configure widget"}
    >
      <div className="p-3 flex flex-col mx-auto gap-y-5">
        <div className="flex flex-col">
          <span>
            {" "}
            Select created timestamp column for{" "}
            <span className="font-bold"> {table} </span> table
          </span>
          <Select
            className="w-full"
            value={selectedCol}
            onChange={onInputName}
            placeholder="Select data column"
            options={formatOptions}
          />
        </div>

        <MButton
          loading={false}
          disabled={!selectedCol}
          onClick={() => toggleModal(false, selectedCol)}
          className={"py-2"}
          label={"Save config"}
        />
      </div>
    </Modal>
  );
};
