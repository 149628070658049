import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
} from "reactflow";
import { v4 as uuidv4 } from 'uuid';

import "reactflow/dist/style.css";
import Selector from "./Selector";

import "../index.css";
import NodeActionDrawer from "./actions";
import {
  MultipleSelectNode,
  SelectNode,
  SubmitFormNode,
  TextInputNode,
  TitleTextNode,
} from "../nodes";
import { NodesServiceContext } from "../../../context/nodes_context";
import { Str } from "../../../utils/constants";

const getId = () => uuidv4();

const NewWorkFlow = ({ selectedWorkflow = {}, toggleSavedWorkflows }) => {
  const reactFlowWrapper = useRef(null);
  const [dataDrawer, setdataDrawer] = useState({
    node: undefined,
    isOpen: false,
  });

  ///
  const [isExistingNodesActivated, setisExistingNodesActivated] =
    useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  let existingNodes;
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const { forms: selectedWorFlowNodes, id: existingWorkflowId } =
    selectedWorkflow;

  if (!isExistingNodesActivated && selectedWorFlowNodes && existingWorkflowId) {
    existingNodes = JSON.parse(selectedWorFlowNodes).map((node) => {
      return { ...node, data: { ...node.data, disabled: false } };
    });

    if (existingNodes.length > 0 && !isExistingNodesActivated) {
      setNodes((nds) => existingNodes);
      setisExistingNodesActivated(true);
    }
  }

  const onNodeActionComplete = (data) => {
    const currentNode = dataDrawer.node
    setNodes((nds) => {
      const newNodes = nds.filter((nd) => nd.id !== currentNode.id);
      const isExistingNode = currentNode?.data.isExistingInTree
      const isTitleType = currentNode.type === Str.nodeTypes.title.type

      if (!data && !isTitleType && isExistingNode) {
        // remove submit node so that all submit form commands can be re-done,
        // only title tag doesnt need this
        const removedSubmitNodes = newNodes.filter(
          (nd) => nd.type !== Str.nodeTypes.submit.type
        );
        return [...removedSubmitNodes];
      } else if (!data) {
        return [...newNodes];
      } else {
        const modifiedNode = {
          ...dataDrawer.node,
          data: data ? { ...dataDrawer.node.data, ...data, isExistingInTree: true } : {},
        };
        return [...newNodes, modifiedNode];
      }
    });
    onToggleDataDrawer(false);
  };

  const evaluateNodeChanage = (nodeChanges) => {
    onNodesChange(nodeChanges);
  };

  const onToggleDataDrawer = () => {
    setdataDrawer({ isOpen: false });
  };

  const onToggleEdit = (id) => {
    const node = nodes.find((nd) => id === nd.id);
    setdataDrawer({ isOpen: true, node });
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onNodeDrag = (modifiedNode) => {
    const nodeInTree = nodes.filter((nd) => nd.id !== modifiedNode.id);
    setNodes((nds) => [...nodeInTree, modifiedNode]);
  };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: {
          label: `Placeholder`,
        },
      };
      setdataDrawer({ isOpen: true, node: newNode });
      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, setNodes]
  );

  const nodeTypes = useMemo(
    () => ({
      textinput: TextInputNode,
      select: SelectNode,
      selectmultple: MultipleSelectNode,
      submit: SubmitFormNode,
      titletext: TitleTextNode,
    }),
    []
  );

  return (
    <div style={{ height: "90vh" }} className="dndflow w-full relative">
      {dataDrawer.isOpen && (
        <NodesServiceContext.Provider value={nodes}>
          <NodeActionDrawer
            dataDrawer={dataDrawer}
            onNodeActionComplete={onNodeActionComplete}
          />
        </NodesServiceContext.Provider>
      )}

      <NodesServiceContext.Provider value={{ onToggleEdit }}>
        <ReactFlowProvider>
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              onNodeDrag={(_, node) => onNodeDrag(node)}
              onNodesChange={evaluateNodeChanage}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
            >
              {/*  <Background variant="dots" gap={12} size={1} /> */}
            </ReactFlow>
          </div>
          <Selector
            workflowId={existingWorkflowId}
            isExistingNodesActivated={isExistingNodesActivated}
            toggleSavedWorkflows={toggleSavedWorkflows}
            nodes={nodes}
          />
        </ReactFlowProvider>
      </NodesServiceContext.Provider>
    </div>
  );
};

export default NewWorkFlow;
