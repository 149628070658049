export const cascadeSelectorOptions = [
  {
    value: "filter",
    label: "Filters",
    children: [
      {
        value: "input",
        label: "Input type",
        children: [
          {
            value: "select",
            label: "Select",
          },
          {
            value: "text",
            label: "Text",
          },
          {
            value: "number",
            label: "Number",
          },
          {
            value: "email",
            label: "Email",
          },
        ],
      },
    ],
  },
  {
    value: "action",
    label: "Row action",
    children: [
      {
        value: "no_input",
        label: "No input",
      },
      {
        value: "single_input",
        label: "Single input",
      },
      {
        value: "multiple_inputs",
        label: "Multiple inputs",
      },
    ]
  },
];
