import { DatePicker, Input, Select } from "antd";
import dayjs from "dayjs";

const getInputTypeFromCol = (colType) => {
    switch (colType) {
      case "varchar":
        return "text";
      case "int":
        return "number";
      case "double":
        return "number";
      case "tinyint":
        return "number";
      case "float":
        return "number";
  
      default:
        return "text";
    }
  };

const getNumberStep = (colType) => {
    switch (colType) {
      case "int":
        return "1";
      case "double":
        return "0.21";
      case "tinyint":
        return "1";
      case "float":
        return "0.01";
  
      default:
        return "1";
    }
  };

export const ColInputField = ({
    colType,
    value,
    onChange,
    width = "w-4/12",
    required = true,
  }) => {
    const splitColType = colType?.Type?.split("(")[0]?.toLowerCase();
    switch (splitColType) {
      case "varchar":
      case "int":
      case "tinyint":
      case "double":
      case "float":
        return (
          <Input
            status={required && !value ? "error" : ""}
            name={"filter_value"}
            type={getInputTypeFromCol(splitColType)}
            value={value}
            step={getNumberStep(splitColType)}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Enter value"
            className={`${width} text-xs h-8 `}
          />
        );
      case "timestamp":
      case "datetime":
        return (
          <DatePicker
            status={value ? "" : "error"}
            className={`${width} text-xs h-8 `}
            placeholder="Select date "
            value={value ? dayjs(value.toString()) : ""}
            onChange={(e, s) => onChange(s)}
            format="YYYY-MM-DD"
          />
        );
      case "enum":
        let formatEnum = colType.Type.replaceAll("enum", "");
        formatEnum = formatEnum.replaceAll("(", "");
        formatEnum = formatEnum.replaceAll(")", "");
        formatEnum = formatEnum.replaceAll("'", "");
        const options = formatEnum.split(",");
        return (
          <Select
            status={value ? "" : "error"}
            className={`${width} text-xs h-8 `}
            placeholder="Select an option "
            value={value}
            options={options.map((opt) => {
              return { value: opt, label: opt };
            })}
            onChange={(e, s) => onChange(e)}
          />
        );
      default:
        return (
          <Input
            status={required && !value ? "error" : ""}
            disabled={!colType}
            name={"filter_value"}
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Filter value"
            className={`${width} text-xs h-8 `}
          />
        );
    }
  };
  