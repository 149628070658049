import React, { useContext, useState } from "react";
import EmptyWorkflow from "./empty_workflow";
import SelectedWorkflow from "./selected_flow/selected_work_flow";
import SavedWorkFlows from "./saved_workflow";
import { Spin } from "antd";
import { ContextServiceContext } from "../../context/schema_context";
import { truncate } from "../../utils";
import NewWorkFlow from "./new_workflow/new_workflow";

const WorkFlow = ({ tabId }) => {
  const [selectedWorkFlow, setSelectedWorkFlow] = useState();
  const [selectedUpdateWorkflow, setSelectedUpdateWorkflow] = useState();
  const [savedWorkflows, setSavedWorkflows] = useState([]);
  const [isNewWorkFlow, setIsNewWorkFlow] = useState(false);
  const contextService = useContext(ContextServiceContext);

  const toggleCreateWorkflow = (status = true) => {
    setIsNewWorkFlow(status);
  };

  const toggleSavedWorkflows = () => {
    setIsNewWorkFlow(false);
    setSelectedWorkFlow(undefined);
    setSavedWorkflows([]);
  };

  const onSelectWorkflow = (workflow) => {
    setIsNewWorkFlow(false);
    setSelectedWorkFlow(workflow);
    setTabTitle(workflow.name);
  };

  const onSelectUpdatedWorkflow = (workflow) => {
    setIsNewWorkFlow(false);
    setSelectedUpdateWorkflow(workflow);
    setTabTitle(workflow.name);
  };

  const setTabTitle = (tabTitle) => {
    const tabs = [...contextService.tabs.tabs];
    const setTab = contextService.tabs.setTabs;
    tabs.find((tab) => tab.key === tabId).label = truncate(
      `${tabTitle} - workflow`
    );
    setTab(tabs);
  };

  const onToggleSelectedWorkFlow = () => {
    setSelectedWorkFlow(undefined)
    setIsNewWorkFlow(false)
    setSelectedUpdateWorkflow(undefined)
  }

  const defaultScreen = savedWorkflows &&
    !selectedWorkFlow &&
    !isNewWorkFlow &&
    !selectedUpdateWorkflow

  const isEmptyScreen = !savedWorkflows && !selectedWorkFlow && !isNewWorkFlow
  return (
    <div className="relative">

      {!defaultScreen && !isEmptyScreen && <div onClick={onToggleSelectedWorkFlow} className="absolute cursor-pointer hover:bg-gray-100 rounded-full top-3 left-4  shadow p-4 w-12 h-12 bg-white z-40 flex items-center justify-center">
        <img src="/arrow.png" className="" />
      </div>}

      {isEmptyScreen && (
        <EmptyWorkflow onCreateWorkflow={toggleCreateWorkflow} />
      )}

      {defaultScreen && (
        <SavedWorkFlows
          setSavedWorkflows={setSavedWorkflows}
          savedWorkflows={savedWorkflows}
          onSelectWorkflow={onSelectWorkflow}
          onCreateWorkflow={toggleCreateWorkflow}
          onSelectUpdatedWorkflow={onSelectUpdatedWorkflow}
        />
      )}

      {selectedWorkFlow && !isNewWorkFlow && !selectedUpdateWorkflow && (
        <SelectedWorkflow selectedWorkflow={selectedWorkFlow} />
      )}

      {(isNewWorkFlow || selectedUpdateWorkflow) && (
        <NewWorkFlow
          selectedWorkflow={selectedUpdateWorkflow}
          toggleSavedWorkflows={toggleSavedWorkflows}
        />
      )}
    </div>
  );
};

export default WorkFlow;
