import { useState } from "react"
import { RowActionType } from "../../../../interface/iwidget_builder"
import { Modal } from "antd";
import React from "react";
import NoInputRowAction from "./NoInputRowAction.tsx";
import InputsRowAction from "./InputsRowAction.tsx";


const RowAction = (props: {
    toggleAction: boolean,
    closeRowAction: () => void,
    actionType: RowActionType,
    actionObj: any,
    rowData: any
}) => {
    console.log(props.actionType);

    switch (props.actionType) {
        case 'no_input':
            return <NoInputRowAction
                name={props.actionObj.name}
                description={props.actionObj.description}
                actionObj={props.actionObj}
                isOpen={props.toggleAction}
                rowData={props.rowData}
                onCancel={props.closeRowAction}
            />
        case "multiple_inputs":
        case "single_input":
            return <InputsRowAction
                name={props.actionObj.name}
                description={props.actionObj.description}
                actionObj={props.actionObj}
                isOpen={props.toggleAction}
                rowData={props.rowData}
                onCancel={props.closeRowAction}
            />
        default:
            return <div>Invalid row action type</div>
    }


}

export default RowAction