import { fetchUserOrDie, makePostRequest, setUserInfo } from "../utils";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Spin,
} from "antd";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { MButton } from "../components/Common/buttons";
import { Helmet } from "react-helmet";
import GoogleLoginButton from "../components/Common/GoogleLogin";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [cookies, setCookie] = useCookies(["access_token", "refresh_token"]);

  const [data, setData] = useState({
    email: undefined,
    password: undefined,
  });

  const onSignIn = async (isGoogle, idToken) => {
    setLoading(true);
    try {
      let response;
      if (isGoogle) {
        response = await makePostRequest("/api/auth/google", {
          id_token: idToken,
        });
      } else {
        response = await makePostRequest("/api/auth/login", {
          email: data.email,
          password: data.password,
        });
      }

      if (response.status == "success") {
        setLoading(false);
        const userType = response.data.user_type;
        message.success("Login success");
        let expires = new Date();
        expires.setTime(expires.getTime() + 36000 * 100000);
        setCookie("access_token", response.data.token, {
          path: "/",
          expires,
        });
        setCookie("refresh_token", response.data.token, {
          path: "/",
          expires,
        });

        localStorage.setItem("token", response.data.token);
        setUserInfo(response.data);
        window.location = "/";
      } else {
        message.error(response.reason);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const onSubmitLogin = async () => {
    if (!data.email) {
      message.error("Enter a valid email address");
    }
    if (!data.password) {
      message.error("Enter a valid password");
    } else {
      await onSignIn();
    }
  };

  const onInputData = (type, text) => {
    switch (type) {
      case "email":
        data.email = text;
        break;
      case "password":
        data.password = text;
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SequelBase | Sign in to account</title>
        <meta name="description" content="Sequelbase" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        <link rel="icon" href="/database_logo_smaller.png" />
        <link rel="canonical" href="https://app.sequelbase.com/connect" />
      </Helmet>
      <div className="bg-white">
        <main className="h-screen bg-white  mx-auto  w-full flex ">
          <div className="flex flex-col gap-y-4 w-6/12 items-center h-full justify-start py-10">
            <div className="w-10/12 mx-auto h-full">
              <div>
                <div className="flex items-center my-4">
                  <img
                    alt="logo"
                    className="w-8"
                    src="/database_black.png"
                    width={100}
                    height={20}
                  />
                  <h1
                    style={{ fontFamily: "mainFontBold" }}
                    className="text-gray-800 text-lg ml-1 font-black"
                  >
                    {" "}
                    SequelBase
                  </h1>
                </div>
                <span className="text-gray-600 text-sm">
                  Need to create a new organization?{" "}
                  <span className="text-blue-600">
                    <Link to={"/register"}>Signup</Link>{" "}
                  </span>
                </span>
              </div>
              <div className="flex flex-col h-full justify-start mt-40">
                <div className="my-0"></div>
                <h1
                  style={{ fontFamily: "mainFontBold" }}
                  className="font-black text-2xl my-3"
                >
                  Welcome back
                </h1>
                <div className="my-3">
                  <GoogleLoginButton onSuccess={onSignIn} />
                </div>
                <div className="my-4 flex items-center justify-start">
                  <span className="text-gray-500 text-sm">
                    Or continue with email
                  </span>
                </div>
                <Input
                  name={"email_add"}
                  onChange={(val) => onInputData("email", val.target.value)}
                  placeholder="email@example.com"
                  className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                />

                <Input
                  name={"email_add"}
                  type="password"
                  onChange={(val) => onInputData("password", val.target.value)}
                  placeholder="********"
                  className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                />

                <div className="w-full flex mt-4">
                  <MButton
                    onClick={onSubmitLogin}
                    loading={loading}
                    className={"w-full"}
                    label={"Login"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="h-full bg-gray-100 w-6/12 flex flex-col items-center justify-center">
            <img className="" src="/login_img.svg" />
            <div className="px-20 text-center my-6">
              <span className="">
                Modern MySQL & PostgreSQL platform powered by AI.{" "}
                <span className="font-boldd">
                  <span className="font-bold"> Advanced filters, </span>{" "}
                  <span className="font-bold">Data relationships, </span>
                  <span className="font-bold">No code workflows, </span>
                  <span className="font-bold">Seamless imports </span>, out of
                  the box.
                </span>{" "}
                <span className="font-bold">without writing any codes </span>
              </span>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
