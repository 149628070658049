import {
  Button,
  Drawer,
  Spin,
  message,
  Mentions,
  FloatButton,
  Tag,
  Alert,
} from "antd";
import {
  CloseCircleOutlined,
  CheckOutlined,
  PlayCircleOutlined,
  AppstoreAddOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { makePostRequest, makePutRequest } from "../../../utils";
import { useParams } from "react-router-dom";
import { Str } from "../../../utils/constants";
import {
  getTableNamesFromSchemaAsKeywordDoms,
  mentionItemToKeyword,
} from "../../../utils/keyword_utils";
import { RawSQLEditor } from "../../Common/RawSQLEditor";
import { ContextServiceContext } from "../../../context/schema_context";
import { AppNameModal } from "./name_modal";
import WidgetEditorDrawer from "./widget_editor.tsx";
import {
  FilterWidgetObj,
  IWidgetObj,
} from "../../../interface/iwidget_builder";
import React from "react";

const { getMentions } = Mentions;

const ManageAppDrawer = ({
  onToggleAppDrawer,
  onPreviewToggle,
  openAppDrawer,
  existingApp,
}) => {
  const params = useParams();

  interface IApp {
    name: string;
    description: string;
    command: { sql: string };
    widgets: Array<IWidgetObj>;
    defaultInputs: Object | undefined;
  }

  let defaultUserInputs = {};
  let formattedDefaultUserInputs: Object | undefined;

  const [app, setApp] = useState<IApp>({
    name: "",
    description: "",
    command: { sql: "" },
    widgets: [],
    defaultInputs: defaultUserInputs,
  });

  const [loading, setLoading] = useState(false);
  const [sqlEditorError, setSqlEditorError] = useState<string | undefined>(
    undefined
  );

  const [selectedWidget, setSelectedWidget] = useState<IWidgetObj>();
  const [openWidgetEditorDrawer, setOpenWidgetEditorDrawer] =
    useState<boolean>(false);
  const [toggleModal, setToggleModal] = useState<boolean>();

  const contextService = useContext(ContextServiceContext);
  const schema = contextService.schema;

  formattedDefaultUserInputs = app.widgets.map((wdg: IWidgetObj) => {
    return {
      [`@${wdg.type}.${wdg.key}`]: `${
        (wdg.widgetConfig as FilterWidgetObj).defaultValue
      }`,
    };
  });

  app.widgets.forEach((wdg: IWidgetObj) => {
    defaultUserInputs[`${wdg.type}.${wdg.key}`] = (
      wdg.widgetConfig as FilterWidgetObj
    ).defaultValue;
  });

  const widgetsKeywords = app.widgets.map((wdg) => {
    return mentionItemToKeyword(
      `${wdg.type}.${wdg.key}`,
      `${wdg.type}.${wdg.key}`,
      `${wdg.name} type`
    );
  });

  const [sqlKeywords, setSqlKeywords] = useState([
    ...Str.defaultSQLKeywords,
    ...getTableNamesFromSchemaAsKeywordDoms(schema.databaseSchema),
  ]);

  const validateMentions = (sql: string) => {
    if (!sql || sql.length < 1) {
      setSqlEditorError("SQL input cannot be empty");
      return;
    } else {
      let missingKw: string | undefined = undefined;
      const mentions = getMentions(sql);
      mentions.forEach((mention) => {
        const mentionExists = sqlKeywords.find((kw) => {
          return kw.value === mention.value;
        });

        if (!mentionExists) {
          missingKw = mention.value;
          return;
        }
      });

      if (missingKw) {
        setSqlEditorError(
          `${missingKw} is not a valid keyword or have been removed`
        );
      } else {
        setSqlEditorError(undefined);
      }
    }
  };

  useEffect(() => {
    const allKeys = [
      ...Str.defaultSQLKeywords,
      ...widgetsKeywords,
      ...getTableNamesFromSchemaAsKeywordDoms(schema.databaseSchema),
    ];
    setSqlKeywords(allKeys);
  }, [app]);

  useEffect(() => {
    preFillExistingApp();
  }, [existingApp]);

  useEffect(() => {
    preFillExistingApp();
  }, []);

  useEffect(() => {
    validateMentions(app.command.sql);
  }, [sqlKeywords]);

  const preFillExistingApp = () => {
    if (existingApp) {
      const { command, name, description, widgets } = existingApp;
      const parsedWidgets = JSON.parse(widgets ?? "[]") ?? [];
      setApp({
        command: JSON.parse(command),
        name,
        description,
        widgets: parsedWidgets,
        defaultInputs: parsedWidgets.map((wdg: IWidgetObj) => {
          return {
            [`@${wdg.type}.${wdg.key}`]: `${
              (wdg.widgetConfig as FilterWidgetObj).defaultValue
            }`,
          };
        }),
      });
    }
  };

  const onChangeAppData = (key: string, value: any) => {
    switch (key) {
      case "name":
      case "description":
        setApp({ ...app, [key]: value });
        break;
      case "command":
        setApp({ ...app, command: { sql: value } });
        validateMentions(value);
        break;
      case "widget":
        setApp({ ...app, widgets: [...app.widgets, value as IWidgetObj] });
        break;
      case "existing_widget":
        const updatedWidget = value as IWidgetObj;
        const updatedWidgetIndex = app.widgets.findIndex(
          (widg) => widg.key === updatedWidget.key
        );
        const updatedArr = [...app.widgets];
        updatedArr[updatedWidgetIndex] = updatedWidget;
        setApp({
          ...app,
          widgets: updatedArr,
        });
        break;
      case "delete_widget":
        const deletedWidget = value as IWidgetObj;
        const deletedWidgetIndex = app.widgets.findIndex(
          (widg) => widg.key === deletedWidget.key
        );
        const allWidgets = [...app.widgets];
        allWidgets.splice(deletedWidgetIndex, 1);
        setApp({
          ...app,
          widgets: allWidgets,
        });

        break;
      default:
        break;
    }
  };

  const onAppWidgetUpdated = (
    widget: IWidgetObj,
    isExisting: boolean = false,
    isDeleteWidget: boolean = false
  ) => {
    if (isExisting && isDeleteWidget) {
      onChangeAppData("delete_widget", widget);
    } else if (isExisting) {
      onChangeAppData("existing_widget", widget);
    } else {
      onChangeAppData("widget", widget);
    }

    setSelectedWidget(undefined);
    setOpenWidgetEditorDrawer(false);
  };

  const validateApp = () => {
    if (!app.command.sql || app.command.sql.length < 1) {
      message.warning("SQL input cannot be empty");
      return false;
    }

    return true;
  };

  const onSaveAndToggleModal = async () => {
    if (!app.name || !app.description) {
      message.warning("App name and description cannot be empty");
      return false;
    }
    if (validateApp()) {
      setToggleModal(false);
      await onSaveApp();
    }
  };

  const onSaveApp = async () => {
    try {
      setLoading(true);

      let response;
      const unProccesedkeywords = getMentions(app.command.sql);
      const processedKw = unProccesedkeywords.map((kw) => {
        return `@${kw.value}`;
      });
      if (existingApp) {
        const { id } = existingApp;
        response = await makePutRequest(
          `/api/datasource/${params?.id}/backend/saveFilter`,
          {
            backend_id: id,
            datasource_id: params?.id,
            type: "raw_command",
            command: app.command,
            name: app.name,
            description: app.description,
            keywords: processedKw,
            widgets: app.widgets,
            inputs: formattedDefaultUserInputs,
          }
        );
      } else {
        response = await makePostRequest(
          `/api/datasource/${params?.id}/backend/saveFilter`,
          {
            datasource_id: params?.id,
            type: "raw_command",
            command: app.command,
            name: app.name,
            description: app.description,
            keywords: processedKw,
            widgets: app.widgets,
            inputs: formattedDefaultUserInputs,
          }
        );
      }
      if (response.status === "success") {
        message.success("New app saved");
        closeAppDrawer(true);
      } else {
        message.error(response.reason);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const closeWidgetDrawer = () => {
    setOpenWidgetEditorDrawer(false);
    setSelectedWidget(undefined);
  };

  const closeAppDrawer = (withAppObj: boolean = false) => {
    onToggleAppDrawer(false, withAppObj ? getAppObj() : undefined);
  };

  const onOpenWidgetDrawer = (selectedWidget: IWidgetObj) => {
    setOpenWidgetEditorDrawer(true);
    setSelectedWidget(selectedWidget);
  };

  const onBeforeSave = () => {
    if (validateApp()) {
      setToggleModal(true);
    }
  };

  const onPreview = () => {
    if (validateApp()) {
      onPreviewToggle(getAppObj());
    }
  };

  const getAppObj = () => {
    const unProccesedkeywords = getMentions(app.command.sql);
    const processedKw = unProccesedkeywords.map((kw) => {
      return `@${kw.value}`;
    });
    return {
      ...(existingApp ?? {}),
      command: JSON.stringify(app.command),
      name: app.name,
      description: app.description,
      widgets: JSON.stringify(app.widgets),
      keywords: processedKw,
      inputs: defaultUserInputs,
    };
  };
  return (
    <>
      {openWidgetEditorDrawer && (
        <WidgetEditorDrawer
          openDrawer={openWidgetEditorDrawer}
          onToggleDrawer={(status: boolean) => closeWidgetDrawer()}
          existingWidget={selectedWidget}
          onDrawerComplete={onAppWidgetUpdated}
        />
      )}

      <Drawer
        //  destroyOnClose={true}
        title={
          <div className="justify-between flex items-center pt-1">
            <span className="text-sm text-gray-600">{`Manage app`}</span>
            <div className="flex gap-x-2">
              <>
                {" "}
                <Button
                  loading={loading}
                  disabled={loading || sqlEditorError !== undefined}
                  onClick={onBeforeSave}
                  className="text-gray-700 text-xs font-bold mx-2 px-1 flex flex-row gap-x-1 items-center"
                  type="text"
                >
                  {existingApp ? "Update app" : " Submit app"}
                  <CheckOutlined />
                </Button>
              </>

              {existingApp && (
                <Button
                  loading={loading}
                  disabled={loading || sqlEditorError !== undefined}
                  onClick={onPreview}
                  className="text-gray-700 text-xs font-bold mx-2 px-1 flex flex-row gap-x-1 items-center"
                  type="text"
                >
                  Preview app
                  <PlayCircleOutlined />
                </Button>
              )}
            </div>
          </div>
        }
        closeIcon={<CloseCircleOutlined />}
        placement="right"
        width={600}
        onClose={() => closeAppDrawer(false)}
        open={openAppDrawer}
      >
        <AppNameModal
          appDescription={app.description}
          appName={app.name}
          setAppName={(text: string) => onChangeAppData("name", text)}
          setAppDescription={(text: string) =>
            onChangeAppData("description", text)
          }
          openModal={toggleModal}
          toggleModal={(status: boolean) => setToggleModal(status)}
          toggleModalAndSave={onSaveAndToggleModal}
        />

        <FloatButton
          type="primary"
          onClick={() => setOpenWidgetEditorDrawer(true)}
          tooltip="Add widget"
          icon={<AppstoreAddOutlined />}
        />

        <Spin spinning={loading}>
          <div>
            <div className="col pt-3">
              <div className="flex flex-col justify-between py-4 px-4 bg-gray-100 rounded">
                <div className="flex justify-between items-center">
                  <span>Enter raw SQL command here</span>
                </div>
                <div className="my-4 rounded-lg">
                  {sqlEditorError && (
                    <Alert
                      message={sqlEditorError}
                      type="error"
                      className="my-2"
                    />
                  )}
                  <RawSQLEditor
                    isError={sqlEditorError !== undefined}
                    loading={loading}
                    rawSQL={app.command.sql}
                    onChange={(text: string) =>
                      onChangeAppData("command", text)
                    }
                    sqlKeywords={sqlKeywords}
                  />
                </div>
              </div>

              {app.widgets && app.widgets.length > 0 && (
                <div className="mt-8 flex flex-col gap-y-3 ">
                  <div className="flex px-2 items-center">
                    <AppstoreAddOutlined />
                    <span className="px-2  rounded-lg block w-3/12 ">
                      Widgets
                    </span>
                  </div>

                  <div className="flex  w-full flex-wrap">
                    {app.widgets.map((widget: IWidgetObj) => {
                      return (
                        <div
                          onClick={() => onOpenWidgetDrawer(widget)}
                          key={widget.name}
                          className="w-3/12 cursor-pointer flex-wrap relative "
                        >
                          <div className="rounded-lg  border  mx-2 my-2  h-16 p-2 flex flex-col gap-y-2 bg-gray-0 hover:bg-gray-100 transition-all duration-700 ">
                            <div className="font-normal text-xs flex justify-between">
                              <span className="w-10/12 truncate">
                                {" "}
                                {widget.name}{" "}
                              </span>
                              <span className="">
                                {" "}
                                <CaretRightOutlined className="text-gray-500" />
                              </span>
                            </div>
                            <div>
                              <Tag
                                color="geekblue"
                                className="text-xs rounded-lg"
                              >
                                {widget.type}
                              </Tag>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Spin>
      </Drawer>
    </>
  );
};

export default ManageAppDrawer;
