import { Alert, Form, Input, Select, message } from "antd";
import { MButton } from "../../components/Common/buttons";
import { Str } from "../../utils/constants";
import { useState } from "react";
import { DollarOutlined } from "@ant-design/icons";

export const NewMySQL = ({ _onAddDatasource, loading }) => {
  const [selectedRegion, setSelectedRegion] = useState(
    Str.availableDbRegions[0].value
  );

  const [selectedSize, setSelectedSize] = useState(
    Str.availableDbSizes[0].value
  );
  const [datasourceName, setDatasourceName] = useState();
  const [databaseName, setDatabaseName] = useState();

  const selectSizeObj = Str.availableDbSizes.find(
    (sz) => sz.value === selectedSize
  );

  const onSubmitData = () => {
    if (!databaseName || databaseName.length < 1) {
      message.warning("Database name is required");
      return;
    }
    if (!datasourceName || datasourceName.length < 1) {
      message.warning("Datasource name is required");
      return;
    }
    if (!selectedSize || selectedSize.length < 1) {
      message.warning("Select preferred database size ");
      return;
    }
    if (!selectedRegion || selectedRegion.length < 1) {
      message.warning("Select preferred database  region");
      return;
    }

    const formData = {
      database: databaseName,
      datasource_name: datasourceName,
      db_size: selectedSize,
      region: selectedRegion,
    };
    _onAddDatasource("new", formData);
  };

  return (
    <Form>
      <div className="w-full">
        <div className="flex gap-x-9 px-7 w-full">
          <div className=" pt-8 pb-6 flex flex-col">
            <span className="text-gray-500 text-xs my-2">Datasource name</span>
            <Input
              required
              value={datasourceName}
              onChange={(text) => setDatasourceName(text.target.value)}
              placeholder="eg. marketing tools"
              width={280}
              className="h-10"
            />
          </div>
          <div className=" pt-8 pb-6 flex flex-col">
            <span className="text-gray-500 text-xs my-2">Database name</span>
            <Input
              required
              value={databaseName}
              onChange={(text) => setDatabaseName(text.target.value)}
              placeholder="golden-unicorn"
              width={280}
              className="h-10"
            />
          </div>
        </div>

        <div className="flex gap-x-9 px-7 mx-0 w-full">
          <div className=" pt-8 pb-6 flex flex-col w-4/12">
            <span className="text-gray-500 text-xs my-2">Preferred Region</span>
            <Select
              defaultValue={"london"}
              placeholder={"Select preferred region"}
              className="w-full"
              value={selectedRegion}
              onChange={(value) => {
                setSelectedRegion(value);
              }}
              options={Str.availableDbRegions}
            />
          </div>
          <div className=" pt-8 pb-6 flex flex-col w-4/12">
            <span className="text-gray-500 text-xs my-2">Instance size</span>
            <Select
              defaultValue={"london"}
              placeholder={"Select preferred size"}
              className="w-full"
              value={selectedSize}
              onChange={(value) => {
                setSelectedSize(value);
              }}
              options={Str.availableDbSizes}
            />
          </div>
        </div>
        <div className=" w-8/12 pl-7 my-4">
          <Alert
            message="Estimated cost"
            description={`${selectSizeObj.label} database cost - ${selectSizeObj.cost}`}
            type="warning"
            icon={<DollarOutlined />}
            showIcon
          />
        </div>
        <div className="mt-4 mx-6 w-4/12 flex justify-start">
          <MButton
            onClick={onSubmitData}
            loading={loading}
            className={"w-full"}
            label={"Create datasource"}
          />
        </div>
      </div>
    </Form>
  );
};
