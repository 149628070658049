import { truncate } from "../../utils";

const SavedQuestions = ({ savedQuestionsList, selectSavedQuestion }) => {
  return (
    <div className="w-11/12 mx-auto">
      <h1 className="px-3 py-3 font-bold mt-8 mb-4">Saved questions</h1>
      <div className="flex  flex-wrap	gap-y-4 text-sm">
        {savedQuestionsList?.slice(0, 12).map((questionItem) => (
          <div
            key={questionItem.id}
            onClick={() => selectSavedQuestion(questionItem)}
            className="w-3/12 cursor-pointer flex-wrap "
          >
            <div className="mx-2">
              <div className="text-xs flex flex-col p-0 bg-white shadow-sm p-3 rounded gap-y-2">
                <div className=""> {truncate(questionItem.name)}</div>
                <div className="h-10 text-gray-400">
                  {" "}
                  {questionItem.question}{" "}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SavedQuestions;
