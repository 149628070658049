import React, { useContext, useState } from "react";
import { makePostRequest, makePutRequest } from "../../../utils";
import { Spin, Tag, message } from "antd";
import { Str } from "../../../utils/constants";
import { ContextServiceContext } from "../../../context/schema_context";
import WorkflowOperations from "./workflow_operations";

const WorkflowInfo = ({ selectedWorkflow = {} }) => {
  const { forms = "[]" } = selectedWorkflow;
  const parsedForms = JSON.parse(forms);
  const commandNode =
    parsedForms?.find((form) => {
      return form.type === "submit";
    }) || {};

  const { data: nodeData = {} } = commandNode;
  const { onSubmit = [] } = nodeData;

  return (
    <section className="relative w-[350px] shadow p-3 h-full overflow-y-scroll">
      <span className="font-bold mb-4 block mb-10 text-[14px]">
        Workflow info
      </span>

      <div className="my-4 ">
        <span className="text-gray-600 block my-4">Workflow Operations</span>

        {onSubmit?.map((command, index) => {
          const { data: commandData } = command;
          const { commandName } = commandData
          let label;
          if (commandData?.webhook && commandData?.webhook?.url) {
            label = `Send ${command.command} data to url`;
          } else if (commandData?.table !== undefined) {
            label = `Perform ${command.command} on ${commandData.table} `;
          }
          return (
            <Tag className="w-full block relative text-center cursor-pointer h-14 rounded-lg flex flex-col items-start justify-center my-3">
              <span>{commandName}</span>
              <span className="text-gray-500"> {label} </span>
            </Tag>
          );
        })}
      </div>

      <WorkflowOperations selectedWorkflow={selectedWorkflow} />
    </section>
  );
};

export default WorkflowInfo;
