import { Input } from "antd";

const SelectActions = ({ values, onChangeInput }) => {
  console.log(values);
  const existingValues = values ? values : {};

  return (
    <div className="my-3 bg-white rounded-lg border p-2 text-sm shadow-sm">
      <span className="text-gray-500 block my-1 text-xs">
        {" "}
        Enter select options (seperated by comma)
      </span>

      <p className="text-gray-900">
        <Input
          required={true}
          value={existingValues?.options?.join(",")}
          onChange={(input) =>
            onChangeInput("options", input.target.value?.split(","))
          }
          className={`border  h-10 border-blue-400`}
          placeholder={` Enter select options (seperated by comma)`}
        />
      </p>
    </div>
  );
};

export default SelectActions;
