import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Datasource from "./containers/datasource";
import ErrorBoundary from "./components/Common/ErrorBoundary";
import Login from "./containers/login";
import AppSessionLoader from "./containers/sessioned_page";
import ConnectDB from "./containers/connect/connect";
import CompleteInvite from "./containers/complete_invite";
import Register from "./containers/register";
import { GoogleOAuthProvider } from "@react-oauth/google";

const router = createBrowserRouter([
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/connect",
    element: <ConnectDB />,
  },
  {
    path: "/complete_invite/:invite_code",
    element: <CompleteInvite />,
  },
  {
    path: "/",
    element: <AppSessionLoader />,
  },
  {
    path: "/datasource/:id",
    element: (
      <ErrorBoundary isFullScreen>
        <Datasource />
      </ErrorBoundary>
    ),
    children: [
      {
        path: "",
        element: <Navigate replace to="overview" />,
      },
      {
        path: "overview",
        element: <div className="bg-red-300 w-full h-screen"> </div>,
      },
      {
        path: "apps",
        element: <div className="bg-green-300 w-full h-screen"> </div>,
      },
      {
        path: "settings",
        element: <div className="bg-blue-300 w-full h-screen"> </div>,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="801456630743-5rb4c1hh82rvse95fek0bqt623gnclqg.apps.googleusercontent.com">
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
