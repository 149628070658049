import React, { useState } from "react";
import { MButton } from "../Common/buttons";
import { AppstoreFilled } from "@ant-design/icons";
const EmptyWorkflow = ({ onCreateWorkflow }) => {
  return (
    <div className="h-screen">
      <div className="flex mt-40 items-center flex-col h-full gap-y-4">
        <AppstoreFilled className="text-4xl" />
        <span className="text-center text-lg">
          You have not created any workflow yet
        </span>
        <MButton onClick={onCreateWorkflow} label={"Create a new workflow"} />
      </div>
    </div>
  );
};

export default EmptyWorkflow;
