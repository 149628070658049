import { Drawer, Tag } from "antd";
import {
  CloseCircleOutlined,
  CaretRightOutlined,
  AppstoreAddOutlined,
  InsertRowAboveOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { IWidgetObj } from "../../../interface/iwidget_builder";
import RowAction from "./row_actions/row_action.tsx";

const AppRowInfo = ({ rowWidgets, selectedRow, onToggleDataDrawer, openDataDrawer }) => {
  const [selectedRowAction, setSelectedRowAction] = useState<IWidgetObj | undefined>()
  const [toggleAction, setToggleAction] = useState(false);

  const closeRowAction = () => {
    setSelectedRowAction(undefined)
    setToggleAction(false)
  }

  const openRowAction = (widget: IWidgetObj) => {
    setToggleAction(true)
    setSelectedRowAction(widget)
  }

  return (
    <Drawer
      title={
        <div className="justify-between flex items-center pt-1">
          <span className="text-sm text-gray-600">Row detail</span>
        </div>
      }
      closeIcon={<CloseCircleOutlined />}
      placement="right"
      onClose={() => onToggleDataDrawer(false)}
      open={openDataDrawer}
      width={500}
    >
      <div className="p-0 h-10/12">
        {selectedRowAction && <RowAction
          actionType={selectedRowAction?.widgetConfig?.actionType}
          actionObj={selectedRowAction}
          rowData={selectedRow}
          toggleAction={toggleAction}
          closeRowAction={closeRowAction} />
        }

        {rowWidgets && rowWidgets.length > 0 && (
          <div className="mb-4 flex flex-col gap-y-3 max-h-60">
            <div className="flex px-2 items-center">
              <AppstoreAddOutlined />
              <span className="px-2  rounded-lg block w-3/12 ">Row actions</span>
            </div>

            <div className="flex  w-full flex-wrap">
              {rowWidgets && rowWidgets.map((widget: IWidgetObj) => {
                return (
                  <div
                    onClick={() => openRowAction(widget)}
                    key={widget.name}
                    className="w-4/12 cursor-pointer flex-wrap relative "
                  >
                    <div className="rounded-lg  border  mx-2 my-2  h-16 p-2 flex flex-col gap-y-2 bg-gray-0 hover:bg-gray-100 transition-all duration-700 ">
                      <div className="font-normal text-xs flex justify-between">
                        <span className="w-10/12 truncate">
                          {" "}
                          {widget.name}{" "}
                        </span>
                        <span className="">
                          {" "}
                          <CaretRightOutlined className="text-gray-500" />
                        </span>
                      </div>
                      <div>
                        <Tag color="geekblue" className="text-xs rounded-lg">
                          {widget.type}
                        </Tag>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="h-auto overflow-scroll">
          <div className="flex px-2 items-center">
            <InsertRowAboveOutlined />
            <span className="px-2  rounded-lg block w-3/12 ">Row data</span>
          </div>

          {selectedRow &&
            Object.keys(selectedRow).map((field) => {
              /*  if (field == "id") return <span></span>; */
              return (
                <div className="">
                  <div className="my-3 bg-white rounded-lg p-2 text-sm shadow">
                    <span className="text-gray-500 block my-1 text-xs">
                      {" "}
                      {field}
                    </span>

                    <p className="text-gray-900 break-words">
                      {selectedRow[field] || "...."}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Drawer>
  );
};

export default AppRowInfo;
