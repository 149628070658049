import { Tooltip, Button } from "antd";

import {
  HomeFilled,
  LogoutOutlined,
  DatabaseOutlined,
  UsergroupAddOutlined,
  DollarOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { clearUserInfo } from "../../../utils";
import { CollapsNavBtn } from "./CollapseNavBtn";

export const NavMenuItems = ({
  hasAdminPrivileges,
  db_product,
  datasource,
  isDbTablesNotSetup,
  publicCredentials,
  toggleRefreshDBModal,
  toggleConnectDBStatus,
  onPushWorkflowTab,
  onPushQuestionTab,
  onPushAppsTab,
  onPushManageTeamTab,
  onPushManageBillingTab,
  isShwTabl,
  toggleShwTable,
}) => {
  return (
    <div className={` bg-gray-50 h-screen relative`}>
      <CollapsNavBtn toggleShwTable={toggleShwTable} />
      <div className="flex flex-col items-center">
        <div className="pt-4 px-3">
          <Tooltip title="Home">
            <HomeFilled className="text-xl" />
          </Tooltip>
        </div>
        {!db_product && hasAdminPrivileges && (
          <div className="my-3 cursor-pointer">
            <Tooltip title="Manage database connection">
              <Button
                onClick={() => {
                  toggleRefreshDBModal(
                    true,
                    datasource,
                    isDbTablesNotSetup,
                    publicCredentials
                  );
                }}
                type="text"
                className="px-2"
              >
                <DatabaseOutlined className="text-xl" />
              </Button>
            </Tooltip>
          </div>
        )}
        {db_product && hasAdminPrivileges && (
          <div className="my-3 cursor-pointer">
            <Tooltip title="Database info">
              <Button
                onClick={() => {
                  //   console.log(datasource);
                  toggleConnectDBStatus(true, datasource, false, true);
                }}
                type="text"
                className="px-2"
              >
                <DatabaseOutlined className="text-xl" />
                {/* <InfoCircleOutlined className="text-xl" /> */}
              </Button>
            </Tooltip>
          </div>
        )}

        <div className="my-3 cursor-pointer">
          <Tooltip title="Ask your database a question">
            <Button onClick={onPushQuestionTab} type="text" className="px-2">
              <QuestionCircleOutlined className="text-xl" />
            </Button>
          </Tooltip>
        </div>

        <div className="my-3 cursor-pointer">
          <Tooltip title="Manage apps">
            <Button onClick={onPushAppsTab} type="text" className="px-2">
              <AppstoreOutlined className="text-xl" />
            </Button>
          </Tooltip>
        </div>

        {/*     <div className="my-3 cursor-pointer">
          <Tooltip title="Manage workflows">
            <Button onClick={onPushWorkflowTab} type="text" className="px-2">
              <AppstoreOutlined className="text-xl" />
            </Button>
          </Tooltip>
        </div> */}
        {hasAdminPrivileges && (
          <div className="my-3 cursor-pointer">
            <Tooltip title="Manage team">
              <Button
                onClick={onPushManageTeamTab}
                type="text"
                className="px-2"
              >
                <UsergroupAddOutlined className="text-xl" />
              </Button>
            </Tooltip>
          </div>
        )}
        {hasAdminPrivileges && (
          <div className="my-3 cursor-pointer">
            <Tooltip title="Manage billing">
              <Button
                onClick={onPushManageBillingTab}
                type="text"
                className="px-2"
              >
                <DollarOutlined className="text-xl" />
              </Button>
            </Tooltip>
          </div>
        )}
        <div className="my-3 cursor-pointer">
          <Tooltip title="Logout">
            <Button
              onClick={() => {
                clearUserInfo();
                window.location = "/login";
              }}
              type="text"
              className="px-2"
            >
              <LogoutOutlined className="text-xl" />
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
