import React, { useContext, useEffect, useState } from "react";
import { MButton } from "../Common/buttons";
import { DownOutlined } from "@ant-design/icons";
import {
  makeClientSideApiRequest,
  makeDeleteRequest,
  makePutRequest,
  truncate,
} from "../../utils";
import { ContextServiceContext } from "../../context/schema_context";
import { Avatar, Dropdown, Space, Spin, Tag, Typography, message } from "antd";
import TeamInfoModal from "./TeamInfoModal/team_info_modal";
import ViewTeamInfoModal from "./TeamInfoModal/view_team_info_modal";

const SavedTeamMembers = ({ teamMembers, setTeamMembers }) => {
  const contextService = useContext(ContextServiceContext);
  const [loading, setLoading] = useState(true);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [selectedMemberInfo, setSelectedMemberInfo] = useState(undefined);

  useEffect(() => {
    loadTeamMembers();
  }, []);

  const loadTeamMembers = async () => {
    try {
      setLoading(true);
      const response = await makeClientSideApiRequest(`/api/teams`);
      if (!response.data) {
        setTeamMembers(undefined);
        message.warning(response.reason);
        return;
      } else if (response.data.data?.length > 0) {
        setTeamMembers(response.data.data);
      } else {
        setTeamMembers(undefined);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setTeamMembers(undefined);
    }
  };

  const toggleInvite = async (status, refresh) => {
    setOpenInviteModal(status);
    if (refresh) await loadTeamMembers();
  };

  return (
    <>
      {selectedMemberInfo && (
        <ViewTeamInfoModal
          onToggleModal={(refresh) => {
            setSelectedMemberInfo(undefined);
            if (refresh) loadTeamMembers();
          }}
          openModal={selectedMemberInfo}
          memberInfo={selectedMemberInfo}
        />
      )}

      <TeamInfoModal onToggleModal={toggleInvite} openModal={openInviteModal} />
      {(!teamMembers || teamMembers?.length < 1) && (
        <div className="h-screen h-full  flex flex-col justify-center items-center">
          {" "}
          <div className="">
            {" "}
            <Spin spinning className="text-lg text-center block"></Spin>{" "}
          </div>
        </div>
      )}

      {teamMembers && (
        <Spin spinning={loading}>
          <div className="w-11/12 mx-auto">
            <div className="my-8 flex justify-between items-center">
              <h1 className="px-3 py-3 text-[14px] rounded-full bg-gray-100 font-bold">
                Manage team members
              </h1>
              <MButton
                onClick={() => setOpenInviteModal(true)}
                label={"Invite team member"}
              />
            </div>

            <div className="flex  flex-wrap	gap-y-4 text-sm">
              {teamMembers?.slice(0, 12).map((teamMemberItem) => (
                <div
                  onClick={() => setSelectedMemberInfo(teamMemberItem)}
                  key={teamMemberItem.id}
                  className="w-3/12 cursor-pointer flex-wrap  gap-y-4"
                >
                  <div className="mx-2 relative flex  flex-col">
                    <div className="text-xs flex flex-col gap-x-2 p-0 bg-white shadow-sm p-3 rounded gap-y-2">
                      <div className="flex gap-x-2 items-center">
                        <Avatar
                          style={{
                            backgroundColor: "#f56a00",
                            verticalAlign: "middle",
                          }}
                          gap={1}
                        >
                          {teamMemberItem.fullname?.substring(0, 1)}
                        </Avatar>
                        <span className="text-lg">
                          {" "}
                          {teamMemberItem.fullname}
                        </span>
                      </div>

                      <div className="ml-10 flex flex-col gap-y-2">
                        <div>
                          <span className="text-gray-500">
                            {" "}
                            {teamMemberItem.email}
                          </span>
                        </div>
                        <div>
                          <span className="text-gray-500 h-4">
                            {teamMemberItem.user_role_description ??
                              "User role"}
                          </span>
                        </div>
                        <div className="flex gap-x-3">
                          {teamMemberItem.is_owner === 1 && (
                            <Tag
                              className="py-0 px-2"
                              bordered={false}
                              color="magenta"
                            >
                              Admin
                            </Tag>
                          )}
                          {teamMemberItem.is_activated === 0 && (
                            <Tag
                              className="py-0 px-2"
                              bordered={false}
                              color="lime"
                            >
                              Invite sent
                            </Tag>
                          )}
                          {teamMemberItem.is_activated === 1 && (
                            <Tag
                              className="py-0 px-2"
                              bordered={false}
                              color="orange"
                            >
                              Active
                            </Tag>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Spin>
      )}
    </>
  );
};

export default SavedTeamMembers;
