import {
  DeleteFilled,
  EditFilled,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { Button, Table, Tag } from "antd";
import { Component } from "react";
import PropTypes from "prop-types";
import { QuestionConfigContext } from "../../context/question_config_context";

// open modal or open record on the right panel

class TablePresentation extends Component {
  state = { headers: [] };

  static propTypes = {
    onPaginate: PropTypes.func,
    questionConfig: PropTypes.object.isRequired,
    isSavedApp: PropTypes.bool,
    loading: PropTypes.bool,
    data: PropTypes.object,
    total: PropTypes.number.isRequired,
  };

  componentDidMount() {
    const dataAppConfig = this.props.questionConfig?.appConfig || {};
    if (this.props.data.length > 0) {
      const headers = Object.keys(this.props.data[0]).map((header, index) => {
        return {
          title: header,
          dataIndex: header,
          fixed: index < 1 ? "left" : "",
          sorter: true,
          sortDirections: ["descend", "ascend"],
          ellipsis: true,
        };
      });
      this.setState({ ...this.state, headers });
    }
  }

  render() {
    const loading = this.props.loading;
    const data = this.props.data;
    const dataAppConfig = this.props.questionConfig?.appConfig || {};
    const { selectedOperations = [] } = dataAppConfig || {};

    /*     const isUpdateIncluded = this.state.headers.find(
        (header) => header.key == "update-operation"
      );
  
     
      if (selectedOperations.includes("Update") && !isUpdateIncluded) {
        this.setState({
          ...this.state,
          headers: [...this.state.headers, this.updateActionData],
        });
      } else if (isUpdateIncluded && !selectedOperations.includes("Update")) {
        const withUpdateHeader = [...this.state.headers];
        const removedWithUpdateHeader = withUpdateHeader.filter(
          (header) => header.key != "update-operation"
        );
        this.setState({
          ...this.state,
          headers: [...removedWithUpdateHeader],
        });
      } */

    return (
      <div className="">
        {data.length > 0 && (
          <div className="h-[680px] ">
            <Table
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    this.props.onToggleDataDrawer(true, record);
                  }, // click row
                  onDoubleClick: (event) => {
                    //  alert(JSON.stringify(record));
                  }, // double click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {}, // mouse leave row
                };
              }}
              pagination={{
                total: this.props.total,
                pageSize: this.props.pageSize,
              }}
              filtered={true}
              filterSearch={true}
              className="mt-4 p-3"
              loading={loading}
              columns={this.state.headers}
              dataSource={[...data]}
              // actions={["Update"]}
              bordered
              size="small"
              scroll={{
                y: 590,
                x: this.state.headers.length > 6 ? 3300 : undefined,
              }}
              onChange={this.props.onPaginate}
              rowClassName={() => "text-xs p-0 m-0 "}
            />
          </div>
        )}
      </div>
    );
  }
}

TablePresentation.contextType = QuestionConfigContext;

export default TablePresentation;
