import { Checkbox, Input, Modal, Segmented, Switch, message } from "antd";
import { useContext, useState } from "react";
import { ContextServiceContext } from "../../../context/schema_context";
import { Str } from "../../../utils/constants";
import { BackendPermissions } from "./BackendPermissions";
import { DashboardPermissions } from "./DashboardPermissions";
import { MButton } from "../../Common/buttons";
import {
  makeDeleteRequest,
  makePostRequest,
  makePutRequest,
} from "../../../utils";
import { useParams } from "react-router-dom";

const ViewTeamInfoModal = ({ openModal, onToggleModal, memberInfo }) => {
  const [loading, setLoading] = useState(false);
  const schemaContext = useContext(ContextServiceContext);
  const [backendPermissions, setBackendPermissions] = useState({});
  const [savedDashboards, setSavedDashboards] = useState([]);
  const [groupSelect, setGroupSelect] = useState({});
  const [forceSet, onForceSet] = useState(false);
  const [segment, setSegment] = useState("Backend permissions");
  const [dashboardPermissions, setDashboardPermissions] = useState([]);
  const [dashboardGroupSelect, setDashboardGroupSelect] = useState(false);

  const { schema = {} } = schemaContext;
  const { databaseSchema = {} } = schema;
  const { columns = [], tables = [] } = databaseSchema;
  const params = useParams();

  const onChangeBackendPermissionInput = (key, value) => {
    const getPermissionState = { ...backendPermissions };
    getPermissionState[key] = [...value];
    setBackendPermissions(getPermissionState);

    groupSelect[Str.backendPermissionOpts.read] = value.includes(
      Str.backendPermissionOpts.read
    )
      ? groupSelect[Str.backendPermissionOpts.read]
      : false;
    groupSelect[Str.backendPermissionOpts.write] = value.includes(
      Str.backendPermissionOpts.write
    )
      ? groupSelect[Str.backendPermissionOpts.write]
      : false;

    groupSelect[Str.backendPermissionOpts.update] = value.includes(
      Str.backendPermissionOpts.update
    )
      ? groupSelect[Str.backendPermissionOpts.update]
      : false;

    groupSelect[Str.backendPermissionOpts.delete] = value.includes(
      Str.backendPermissionOpts.delete
    )
      ? groupSelect[Str.backendPermissionOpts.delete]
      : false;
  };

  const onChangeGroupSelect = (key, value) => {
    //cool stuff
    switch (key) {
      case "Read":
        toggleAllTables(value, Str.backendPermissionOpts.read);
        break;
      case "Write":
        toggleAllTables(value, Str.backendPermissionOpts.write);
        break;
      case "Update":
        toggleAllTables(value, Str.backendPermissionOpts.update);
        break;
      case "Delete":
        toggleAllTables(value, Str.backendPermissionOpts.delete);
        break;
      default:
        break;
    }
  };

  const toggleAllTables = (status, type) => {
    const getPermissionState = { ...backendPermissions };
    const markAllAsRead = (table, status) => {
      // remove read if already available
      const getTablePermissions = getPermissionState[table] ?? [];
      const validated = getTablePermissions.filter((pm) => {
        return pm !== type;
      });

      if (status === true) {
        getPermissionState[table] = [...validated, type];
      } else {
        getPermissionState[table] = validated;
      }

      setBackendPermissions(getPermissionState);
      onForceSet(!forceSet);
    };

    for (const index in tables) {
      const table = tables[index];
      markAllAsRead(table, status);
      groupSelect[type] = status;
      setGroupSelect(groupSelect);
    }
  };

  const onSubmitButtonClicked = async () => {
    await updateInvite();
  };

  const onChangeDashboardPermission = (dashboardId, permission) => {
    const index = dashboardPermissions.findIndex((pm) => pm === dashboardId);

    if (!permission) {
      const cloned = [...dashboardPermissions];
      cloned.splice(index, 1);
      setDashboardPermissions(cloned);
      setDashboardGroupSelect(false);
    } else if (index === -1) {
      setDashboardPermissions([...dashboardPermissions, dashboardId]);
    }
  };

  const onChangeDashboardGroupSelect = (status, dashboards) => {
    setDashboardGroupSelect(status);
    if (!status) {
      setDashboardPermissions([]);
    } else {
      setDashboardPermissions(dashboards);
    }
  };

  const onCloseModal = (refresh = false) => {
    setLoading(false);
    setBackendPermissions({});
    setGroupSelect({});
    onForceSet(false);
    setSegment("Backend permissions");
    setDashboardPermissions([]);
    setDashboardGroupSelect(false);
    onToggleModal(refresh);
  };

  const updateInvite = async () => {
    setLoading(true);
    try {
      const response = await makePutRequest(
        `/api/datasource/${params?.id}/team/updateMemberPermission/${memberInfo?.id}`,
        {
          dashboardPermissions: dashboardPermissions ?? [],
          backendPermissions: backendPermissions ?? {},
        }
      );

      if (response.status === "success") {
        message.success("Member permissions updated");
        onCloseModal(true);
      } else {
        setLoading(false);
        message.error(response.reason);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const removeInvite = async () => {
    setLoading(true);
    try {
      const response = await makeDeleteRequest(
        `/api/datasource/${params?.id}/team/remove?member_id=${memberInfo?.id}`
      );

      if (response.status === "success") {
        message.success("Member removed");
        onCloseModal(true);
      } else {
        setLoading(false);
        message.error(response.reason);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={openModal}
      onCancel={() => onCloseModal(false)}
      footer={null}
      title={"Manage team member"}
      width={700}
      destroyOnClose={true}
    >
      <div className="py-5 flex flex-col gap-x-2 mx-auto">
        <div className="flex flex-wrap gap-y-3 gap-x-4">
          <div className="w-5/12">
            <span className="block py-2 text-xs">Fullname</span>
            <Input
              required={true}
              value={memberInfo["fullname"]}
              disabled={true}
              className={``}
              placeholder={`Enter member full name`}
            />
          </div>

          <div className="w-5/12">
            <span className="block py-2 text-xs">Email address</span>
            <Input
              required={true}
              value={memberInfo["email"]}
              disabled={true}
              className={``}
              placeholder={`Enter member email address `}
            />
          </div>

          <div className="w-5/12">
            <span className="block py-2 text-xs">User role description</span>
            <Input
              required={true}
              value={memberInfo["user_role_description"]}
              readOnly={true}
              disabled={true}
              className={``}
              placeholder={`E.g Customer service rep, Engineering`}
            />
          </div>

          {memberInfo?.is_owner === 1 && (
            <div className="w-5/12">
              <span className="block py-2 text-xs">User type</span>
              <Input
                required={true}
                value={"Team admin"}
                readOnly={true}
                disabled={true}
                className={``}
                placeholder={`E.g Customer service rep, Engineering`}
              />
            </div>
          )}
        </div>

        {memberInfo?.is_owner !== 1 && (
          <div className="py-2">
            <span className="block text-xs py-3">Member Permissions</span>
            <Segmented
              block
              value={segment}
              onChange={(x) => setSegment(x)}
              options={[
                "Backend permissions",
                "Dashboard permissions",
                "Widget permissions",
              ]}
            />

            {segment === "Backend permissions" && (
              <BackendPermissions
                memberInfo={memberInfo}
                tables={tables}
                onChangeBackendPermissionInput={onChangeBackendPermissionInput}
                setBulkBackendPermissions={setBackendPermissions}
                backendPermissions={backendPermissions}
                groupSelect={groupSelect}
                onChangeGroupSelect={onChangeGroupSelect}
              />
            )}
            {segment === "Dashboard permissions" && (
              <DashboardPermissions
                onChangeDashboardPermission={onChangeDashboardPermission}
                dashboardPermissions={dashboardPermissions}
                dashboardGroupSelect={dashboardGroupSelect}
                onChangeDashboardGroupSelect={onChangeDashboardGroupSelect}
                setLoading={setLoading}
                loading={loading}
                savedDashboards={savedDashboards}
                setSavedDashboards={setSavedDashboards}
              />
            )}
          </div>
        )}
        {memberInfo?.is_owner !== 1 && (
          <div className="flex justify-center mt-3 gap-x-3">
            <MButton
              disabled={loading}
              loading={loading}
              onClick={removeInvite}
              label={"Remove member"}
              className={"py-2"}
            />

            <MButton
              disabled={loading}
              loading={loading}
              onClick={onSubmitButtonClicked}
              label={"Update member"}
              className={"py-2"}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ViewTeamInfoModal;
