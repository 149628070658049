import { Dropdown, Space, Spin, Tag } from "antd";
import { truncate } from "../../../utils";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";

import { CloseCircleFilled, SearchOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { ContextServiceContext } from "../../../context/schema_context";
import { Str } from "../../../utils/constants";

export const DBSchema = ({
  isShwTabl,
  datasources,
  datasourceName,
  datasourceType,
  isMysqlSchema,
  searchInput,
  tree = [],
  cancelSearch,
  onSearch,
  loading,
  onExpandTree,
  onOpenTable,
}) => {
  console.log("ALL TREES", tree);
  const context = useContext(ContextServiceContext);
  const { user = {} } = context;
  const { backend_permissions = [], has_admin_privileges } = user ?? {};
  const permissionTablesKeys = Object.keys(backend_permissions);

  const verifiedRead = permissionTablesKeys.filter((table) => {
    console.log(backend_permissions[table]);
    return backend_permissions[table].includes(Str.backendPermissionOpts.read);
  });

  const permissionTableMaps = verifiedRead.map((tbl) => {
    return tree.find((tr) => tr.title === tbl);
  });

  console.log(tree);
  return (
    <div
      className={`${
        isShwTabl ? "w-10/12" : "w-0"
      } p-2 transition-all duration-200`}
    >
      {isShwTabl && (
        <DbName
          datasourceType={datasourceType}
          datasources={datasources}
          datasourceName={datasourceName}
        />
      )}
      {isShwTabl && (
        <SearchDBNameInput
          searchInput={searchInput}
          cancelSearch={cancelSearch}
          onSearch={onSearch}
          tablesLength={tree.length}
        />
      )}
      <div className="h-[700px] overflow-y-scroll">
        <ErrorBoundary id="datasource_mysql_schema">
          <MYSQLSchema
            onExpand={onExpandTree}
            onOpenTable={onOpenTable}
            tree={has_admin_privileges ? tree : permissionTableMaps}
            loading={loading}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
};

const DbName = ({ datasourceName, datasourceType, datasources }) => (
  <div className="flex items-center gap-x-3 w-full py-3 px-1 justify-between border-gray-200 h-12">
    <div className="flex gap-x-2 items-center justify-between">
      <span className="text-xs">{truncate(`${datasourceName}`, 20)}</span>
      <span className="text-xs"></span>
      <Tag className="rounded-full" color="purple">
        {datasourceType}
      </Tag>
    </div>

    <Dropdown
      className="cursor-pointer"
      menu={{ items: datasources }}
      trigger={["click"]}
      placement="top"
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <img src="/dropdown.png" className="text-xl h-3" />
        </Space>
      </a>
    </Dropdown>
  </div>
);

const MYSQLSchema = ({ tree, loading, onOpenTable }) => {
  return (
    <ErrorBoundary id="mysqlschema">
      <div className={` py-2 transition-all`}>
        {/*         <div className="my-0">
            <h1 className="text-sm">
              {tree.length > 0 ? `Schema -  ${tree.length} ` : ""}
            </h1>
          </div> */}
        <Spin spinning={loading}>
          {tree &&
            tree.map((leaf) => {
              const { title: tableName } = leaf;
              return (
                <div
                  key={tableName}
                  onClick={() => onOpenTable(tableName)}
                  className="bg-gray-100 rounded-md p-3 hover:bg-slate-200 cursor-pointer w-full flex justify-between items-center relative text-center my-3"
                >
                  {tableName}
                  <img src="/share.png" alt="open" className="w-3 h-3" />
                </div>
              );
            })}
        </Spin>
      </div>
    </ErrorBoundary>
  );
};

const SearchDBNameInput = ({
  searchInput,
  onSearch,
  tablesLength,
  cancelSearch,
}) => {
  return (
    <div className="w-full relative my-3">
      <div className="absolute left-3 top-0">
        <SearchOutlined className="text-xs" />
      </div>

      <input
        autoFocus={true}
        onChange={(event) => onSearch(event.target.value)}
        value={searchInput}
        type="text"
        placeholder={`Search ${tablesLength} table(s) ..`}
        className="text-xs pt-2 outline-none text-black focus:ring-0 h-8 rounded-full  bg-white p-2 border border-[#ADB5BD] w-full px-8"
      />
      <div onClick={cancelSearch} className="absolute right-4 top-0">
        <CloseCircleFilled className="text-xs" />
      </div>
    </div>
  );
};
