import { Modal } from "antd";
import { MButton } from "../Common/buttons";

const SaveQuestionModal = ({
  openSaveModal,
  onToggleSaveModal,
  onInputName,
  savingQuestion,
  appNameConfig,
  saveQuestion,
}) => {
  return (
    <Modal
      open={openSaveModal}
      onCancel={() => onToggleSaveModal(false)}
      footer={null}
      title={"Save question"}
    >
      <div className="p-3 flex flex-col mx-auto gap-y-5">
        <input
          onChange={onInputName}
          type="text"
          className="text-md outline-none text-black focus:ring-0 h-14 rounded-full  bg-white p-2 border border-[#ADB5BD] w-full px-5"
        />

        <MButton
          loading={savingQuestion}
          disabled={!appNameConfig || appNameConfig.length < 5}
          onClick={saveQuestion}
          className={"absolutde right-2 top-2"}
          label={"Save question"}
        />
      </div>
    </Modal>
  );
};

export default SaveQuestionModal;
