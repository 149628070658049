import React from "react";
import { MButton } from "./buttons";
import { WarningFilled } from "@ant-design/icons";
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isFullScreen: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    const { isFullScreen } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          className={`${
            isFullScreen ? "h-screen" : "h-40"
          } flex items-center justify-center`}
        >
          <div className="flex flex-col items-center gap-y-4">
            <WarningFilled className="text-2xl" />
            <span className="text-black text-sm">Unable to load component</span>
            <span className="text-black text-sm">{this.props.id}</span>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
