export class ContextService {
  schema;
  tabs;
  activeTab;
  events;

  constructor(schemaState, tabsState, activeTabState, eventState, userState) {
    this.schema = schemaState;
    this.tabs = tabsState;
    this.activeTab = activeTabState;
    this.events = eventState;
    this.user = userState;
  }
}
