const PresentError = ({ errMsg }) => {
  if (errMsg) {
    return (
      <div>
        <div
          style={{ backgroundImage: "url(/home_empty.png)" }}
          className="w-full h-80 bg-contain bg-no-repeat bg-center mt-0"
        >
          <p></p>
        </div>
        <div>
          {errMsg && (
            <p className="text-gray-400 text-center my-4 mx-auto w-6/12 text-sm">
              {errMsg}
            </p>
          )}
        </div>
      </div>
    );
  }
};

export default PresentError;
