import { DatePicker, Input, Select } from "antd";
import dayjs from "dayjs";

export const WidgetInputComponent = ({
    inputType,
    value,
    onChange,
    width = "",
    defaultValue,
    required = true,
    extra,
    disabled = false,
  }) => {
    const isFilled = value || defaultValue;
    switch (inputType) {
      case "text":
      case "number":
      case "decimal":
      case "email":
        return (
          <Input
            disabled={disabled}
            status={required && !isFilled ? "error" : ""}
            name={"filter_value"}
            type={inputType}
            value={value}
            defaultValue={defaultValue}
            step={1}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Enter value"
            className={`${width} text-xs h-8 `}
          />
        );
      case "select":
        return (
          <Select
            disabled={disabled}
            status={isFilled ? "" : "error"}
            className={`${width} text-xs h-8 `}
            placeholder="Select an option "
            defaultValue={defaultValue}
            value={value}
            options={extra}
            onChange={(e, s) => onChange(e)}
          />
        );
      case "date":
      case "datetime":
        return (
          <DatePicker
            disabled={disabled}
            status={isFilled ? "" : "error"}
            className={`${width} text-xs h-8 `}
            defaultValue={defaultValue}
            placeholder="Select date "
            value={value ? dayjs(value.toString()) : ""}
            onChange={(e, s) => onChange(s)}
            format={inputType === "date" ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm"}
          />
        );
      default:
        return <span>Unknown input type</span>;
    }
  };
  