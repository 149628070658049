import { Alert, Form, Input, message } from "antd";
import { MButton } from "../../components/Common/buttons";
import { Str } from "../../utils/constants";
import { useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";

export const ExistingMySQL = ({ _onAddDatasource, loading }) => {
  const [hostName, setHostName] = useState();
  const [dbPort, setDbPort] = useState();
  const [datasourceName, setDatasourceName] = useState();
  const [databaseName, setDatabaseName] = useState();

  const [dbUsername, setDbUsername] = useState();
  const [dbPassword, setDbPassword] = useState();

  const onSubmitData = () => {
    if (!databaseName || databaseName.length < 1) {
      message.warning("Database name is required");
      return;
    }
    if (!datasourceName || datasourceName.length < 1) {
      message.warning("Datasource name is required");
      return;
    }
    if (!hostName || hostName.length < 1) {
      message.warning("Host name (url) is required");
      return;
    }
    if (!dbPort || dbPort.length < 1) {
      message.warning("Database port is required");
      return;
    }

    if (!dbUsername || dbUsername.length < 1) {
      message.warning("Database username is required");
      return;
    }

    if (!dbPassword || dbPassword.length < 1) {
      message.warning("Database password is required");
      return;
    }

    const formData = {
      database: databaseName,
      datasource_name: datasourceName,
      port: dbPort,
      host: hostName,
      password: dbPassword,
      username: dbUsername,
    };
    _onAddDatasource("existing", formData);
  };

  return (
    <Form>
      <div className="w-full">
        <div className="flex gap-x-9 px-7 w-full">
          <div className=" pt-2 pb-6 flex flex-col">
            <span className="text-gray-500 text-xs my-2">Datasource name</span>
            <Input
              required
              onChange={(text) => setDatasourceName(text.target.value)}
              placeholder="eg. marketing tools"
              width={280}
              className="h-10"
            />
          </div>
          <div className=" pt-2 pb-6 flex flex-col">
            <span className="text-gray-500 text-xs my-2">Database name</span>
            <Input
              required
              onChange={(text) => setDatabaseName(text.target.value)}
              placeholder="golden-unicorn"
              width={280}
              className="h-10"
            />
          </div>
        </div>

        <div className="flex gap-x-9 px-7 mx-0">
          <div className=" pt-2 pb-6 flex flex-col">
            <span className="text-gray-500 text-xs my-2">Host name</span>
            <Input
              required
              onChange={(text) => setHostName(text.target.value)}
              placeholder="https://127.0.0.1"
              width={280}
              value={hostName}
              className="h-10"
            />
          </div>
          <div className=" pt-2 pb-6 flex flex-col">
            <span className="text-gray-500 text-xs my-2">Port</span>
            <Input
              required
              onChange={(text) => setDbPort(text.target.value)}
              placeholder="e.g 3306"
              width={280}
              value={dbPort}
              className="h-10"
            />
          </div>
        </div>

        <div className="flex  gap-x-9 px-7 mx-0">
          <div className=" pt-2 pb-6 flex flex-col">
            <span className="text-gray-500 text-xs my-2">
              Database Username
            </span>
            <Input
              required
              onChange={(text) => setDbUsername(text.target.value)}
              placeholder="admin"
              width={280}
              value={dbUsername}
              className="h-10"
            />
          </div>
          <div className=" pt-2 pb-6 flex flex-col">
            <span className="text-gray-500 text-xs my-2">Password</span>
            <Input
              required
              onChange={(text) => setDbPassword(text.target.value)}
              value={dbPassword}
              placeholder="***"
              type="password"
              width={280}
              className="h-10"
            />
          </div>
        </div>
        <div className=" w-8/12 pl-7 my-4">
          <Alert
            message="Whitelisting IP"
            description={` 165.232.32.22 `}
            type="warning"
            icon={<InfoCircleOutlined />}
            showIcon
          />
        </div>
        <div className="mt-4 mx-6 w-4/12 flex justify-start">
          <MButton
            onClick={onSubmitData}
            loading={loading}
            className={"w-full"}
            label={"Connect datasource"}
          />
        </div>
      </div>
    </Form>
  );
};
