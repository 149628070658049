import { Alert, Button, Modal, Select, message } from "antd";
import { Str } from "../../utils/constants";
import { MButton } from "../Common/buttons";
import {
  HourglassFilled,
  CopyOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { makePostRequest } from "../../utils";
import { useParams } from "react-router-dom";
import {
  getTableColumnsFromSchema,
  getTableFromSchema,
} from "../../utils/schema_utils";

const RefreshExistingDBModal = ({
  hasAdminPrivileges,
  openModal,
  toggleModal,
  datasource,
  isTablesNotSetup,
  publicCredentials = {},
  schema,
  highlighted,
}) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [highlightTbl, setHighlightTbl] = useState();

  useEffect(() => {
    setHighlightTbl(highlighted);
  }, []);

  const onHighlighTblsChanged = (e) => {
    if (e?.length > 4) {
      message.warning("Maximum of 4 highlight tables allowed");
      return;
    }
    setHighlightTbl(e);
  };

  const reConnectExistingSource = async (formData) => {
    try {
      const { name, id } = datasource;
      setLoading(true);
      const response = await makePostRequest("/api/datasource", {
        is_update: true,
        datasource_name: name,
        datasource_id: id,
        highlight_tables: highlightTbl,
      });

      if (response.status === "success") {
        setLoading(false);
        window.location = "/";
      } else {
        message.warning(response.reason);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      message.error(error.message);
      setLoading(false);
    }
  };

  const { host, port, password, database, username } = publicCredentials;
  const isHighlightNotSelected = !highlighted || highlighted?.length < 1;

  console.log(highlighted, highlightTbl);
  return (
    <Modal
      open={openModal}
      closable={!isHighlightNotSelected || isTablesNotSetup}
      maskClosable={!isHighlightNotSelected || isTablesNotSetup}
      onCancel={() => toggleModal(false)}
      footer={null}
      title={` ${datasource?.name}`}
    >
      {isHighlightNotSelected && (
        <Alert
          description={"Select 4 tables to be highlighted on the dashboard"}
          type="warning"
          className="text-xs"
          closable
          banner
        />
      )}
      {isTablesNotSetup && (
        <Alert
          description={
            "No tables are setup for this database yet. Please add tables to your database and click on refresh connection to continue"
          }
          type="error"
          className="text-xs"
          closable
          banner
        />
      )}
      {error && (
        <Alert
          description={error}
          type="error"
          className="text-xs"
          closable
          banner
        />
      )}

      <div className="my-8">
        <DBCredItem info={host} title={"DB Host name"} />
        <DBCredItem info={port} title={"DB Port"} />
        <DBCredItem info={password} title={"DB Password"} />
        <DBCredItem info={username} title={"Default user"} />
      </div>

      {schema && !isTablesNotSetup && (
        <div className="my-4">
          <span className="text-xs mb-2">{"Select tables to highlight"}</span>
          <Select
            className="w-4/12 text-xs h-10 mt-2"
            placeholder="Select highlight tables"
            status={isHighlightNotSelected ? "error" : ""}
            style={{ width: "100%" }}
            defaultValue={highlightTbl ?? highlighted}
            value={highlightTbl ?? highlighted}
            mode="multiple"
            maxTagCount={6}
            /* value={item.filter} */
            onChange={(e) => onHighlighTblsChanged(e)}
            options={getTableFromSchema(schema)?.map((tbl) => {
              return {
                label: tbl,
                value: tbl,
              };
            })}
          />
        </div>
      )}

      <div className="flex mt-9 justify-between items-center gap-x-4">
        <div className="gap-x-2 flex items-center">
          <CheckCircleFilled className="text-green-500" />
          active deployment
        </div>
        <MButton
          loading={loading}
          className={"py-1 px-1"}
          onClick={reConnectExistingSource}
          label={"Refresh connection"}
        />
      </div>
    </Modal>
  );
};

export default RefreshExistingDBModal;

const DBCredItem = ({ info, title }) => {
  return (
    <div className="my-4 relative">
      <Button
        onClick={() => {
          navigator.clipboard.writeText(info);
          message.info(`Copied  ${title}`);
        }}
        type="text"
        className="text-xs absolute right-0 top-8"
        icon={<CopyOutlined />}
      />
      <span className="text-xs">{title}</span>
      <div className="bg-gray-200 rounded-md my-2 p-2 ">
        <span className="text-gray-700 text-xs"> {info}</span>
      </div>
    </div>
  );
};
