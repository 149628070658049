import { Drawer, Input, Select, Tag, Popconfirm, Button } from "antd";
import { useContext, useEffect, useState } from "react";
import { ContextServiceContext } from "../../../../../context/schema_context";
import { v4 as uuidv4 } from "uuid";
import { CloseOutlined } from "@ant-design/icons";

import { Str } from "../../../../../utils/constants";
import CommandDataDrawer from "./command_data_drawer";

const commandPlaceHolder = {
  command: undefined,
  data: undefined,
  commandIndex: undefined,
  isOpen: false,
};

const OnSubmitActions = ({ values, onFormSetupComplete }) => {
  const existingValues = values ? values : {};
  const { onSubmit = [] } = existingValues;

  const [orderedCommands, setOrderedCommands] = useState([]);
  const contextService = useContext(ContextServiceContext);

  const [commandDataDrawer, setCommandDataDrawer] = useState({});

  if (onSubmit && onSubmit.length > 0 && orderedCommands.length < 1) {
    setOrderedCommands(onSubmit);
  }

  const onCompleteCommandData = (command, commandIndex, data) => {
    console.log(command, commandIndex, data);
    if (command === Str.submitCommandTypes.insert.key) {
      // do something with insert
      onDataDrawerComplete(commandIndex, command, data);
    } else if (command === Str.submitCommandTypes.update.key) {
      // do something with update
      onDataDrawerComplete(commandIndex, command, data);
    } else if (command === Str.submitCommandTypes.delete.key) {
      // do something with delete
      onDataDrawerComplete(commandIndex, command, data);
    } else if (command === Str.submitCommandTypes.webhook.key) {
      // do something with webhook
      console.log(data);
      onDataDrawerComplete(commandIndex, command, data);
    }

    // do something with the data
    setCommandDataDrawer({ isOpen: false });
  };

  const onChangeCommand = (index, command) => {
    if (command === "pop") {
      orderedCommands.splice(index, 1);
      setOrderedCommands([...orderedCommands]);
    } else {
      setCommandDataDrawer({
        isOpen: true,
        command: command,
        commandIndex: index,
      });
    }
  };

  const onDataDrawerComplete = (commandIndex, command, data) => {
    if (data.isComplete === true) {
      orderedCommands[commandIndex] = {
        command: command,
        data: data, // data from data drawer
      };
    } else {
      // was not completed, remove it
      orderedCommands.splice(commandIndex, 1)
    }

    setOrderedCommands([...orderedCommands]);
    // change this to onformsetupcomplete
    onFormSetupComplete(
      Str.nodeTypes.submit.attributes.onSubmit.key,
      orderedCommands
    );
    // change this to onformsetupcomplete
  };

  const onAddCommand = () => {
    setOrderedCommands([...orderedCommands, commandPlaceHolder]);
  };

  const onRemoveCommand = (event, commandIndex) => {
    event.stopPropagation();
    const clonedCommands = [...orderedCommands];
    clonedCommands.splice(commandIndex, 1);
    console.log(clonedCommands);
    setOrderedCommands(clonedCommands);
    onFormSetupComplete(
      Str.nodeTypes.submit.attributes.onSubmit.key,
      clonedCommands
    );
  };

  const getCommandSummary = (command, data) => {
    switch (command) {
      case Str.submitCommandTypes.webhook.key:
        return data["webhook"];
      case Str.submitCommandTypes.insert.key:
        return { pairs: data.pairs };
      case Str.submitCommandTypes.delete.key:
        return { filter: data.filter };
      case Str.submitCommandTypes.update.key:
        return { filter: data.filter, pairs: data.pairs };
      default:
        return {};
    }
  };

  return (
    <>
      {commandDataDrawer?.isOpen && (
        <CommandDataDrawer
          dataDrawer={commandDataDrawer}
          onDataDrawerComplete={onDataDrawerComplete}
          onCompleteCommandData={onCompleteCommandData}
          orderedCommands={orderedCommands}
        />
      )}
      <div className="my-3 bg-white rounded-lg  p-2 text-sm ">
        <span className="text-gray-500 block my-1 text-xs">
          {" "}
          Add commands on submit
        </span>

        <div className="text-gray-900 ">
          {orderedCommands.map((orderedCommand, index) => {
            const key = uuidv4();
            const { data = {}, command } = orderedCommand;
            const { commandName } = data;
            console.log(commandName);
            if (data?.table !== undefined || data?.webhook) {
              let label;
              if (data?.webhook && data?.webhook?.url) {
                label = `Send ${command} data to url`;
              } else if (data?.table !== undefined) {
                label = `Perform ${command} on ${data.table} `;
              }
              return (
                <div className="my-4">
                  <Popconfirm
                    title={label}
                    description={
                      <div>
                        <p className="font-bold mt-2 mb-4">command data</p>
                        <div className="w-96 flex flex-wrap text-wrap">
                          {JSON.stringify(getCommandSummary(command, data))}
                        </div>
                      </div>
                    }
                    onConfirm={null}
                    onCancel={(event) => onRemoveCommand(event, index)}
                    okText="Close"
                    cancelText="Remove"
                    okButtonProps={{ classNames: "bg-blue-200" }}
                  >
                    <CompletedCommandItem
                      key={key}
                      title={label}
                      name={commandName}
                      onClick={() => console.log("edit command")}
                      onRemove={(event) => onRemoveCommand(event, index)}
                      isRemoveable={true}
                    />
                  </Popconfirm>
                </div>
              );
            }

            return (
              <Select
                key={key}
                onChange={(command) => onChangeCommand(index, command)}
                className="w-full my-4 shadow-sm border"
                placeholder={"Select a command"}
                value={orderedCommands[index]?.command}
                options={Str.commandOpt}
              ></Select>
            );
          })}
          <CompletedCommandItem
            title={"Tap to add a command"}
            onClick={onAddCommand}
          />
        </div>
      </div>
    </>
  );
};

export default OnSubmitActions;

const CompletedCommandItem = ({ title, name, onClick, onRemove, isRemoveable }) => {
  return (
    <Tag
      className="w-full block relative text-center cursor-pointer h-14 rounded-lg flex flex-col justify-center"
      onClick={onClick}
    >
      {name &&
        <div className="flex flex-col justify-center items-start">
          <span>{name}</span>
          <span className="text-gray-500"> {title}</span>
        </div>
      }

      {!name && <span>{title}</span>}
      {isRemoveable && (
        <div
          onClick={onRemove}
          className="absolute z-2 top-2 right-2 w-4 h-4 bg-gray-300 rounded-full flex items-center justify-center pt-[0.5px]"
        >
          <CloseOutlined className="text-[8px]" />
        </div>
      )}
    </Tag>
  );
};
