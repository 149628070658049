import { message } from "antd";

export const validateValue = (
  key: string | null | undefined,
  value: any,
  isExisting: boolean = false
) => {
  switch (key) {
    case "type":
      const allowedFilters = ["filter", "form", "action"];
      if (!allowedFilters.includes(value as string)) {
        message.warning(
          `Filter type must be either ${allowedFilters.join(" , ")}`
        );
        return false;
      }
      break;
    case "key":
      if (isExisting) {
        message.warning(`Key cannot be updated once widget is created`);
        return false;
      }
      break;
    case "inputType":
      const allowedInputTypes = [
        "select",
        "text",
        "email",
        "phone",
        "date",
        "number",
      ];
      if (!allowedInputTypes.includes(value as string)) {
        message.warning(
          `Input type must be either ${allowedInputTypes.join(" , ")}`
        );
        return false;
      }
      break;
    default:
      break;
  }
  return true;
};

export const validateObjRemoval = (name: string) => {
  try {
    const keyAttr = [
      "key",
      "name",
      "type",
      "widgetConfig",
      "step",
      "inputType",
      "defaultValue",
      "inputOptions",
      "label",
      "value",
    ];
    if (keyAttr.includes(name)) {
      return false;
    }
  } catch (error) {}

  return false;
};
