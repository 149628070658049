import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ContextServiceContext } from "../../../context/schema_context";
import { useContext } from "react";
import { Str } from "../../../utils/constants";

const DrawerTitle = ({
  selectedRow,
  isCreateRow,
  primaryKeyField,
  setUserAction,
  table,
}) => {
  const contextService = useContext(ContextServiceContext);
  const { user = {} } = contextService;
  const { backend_permissions = [], has_admin_privileges } = user ?? {};
  const tablePermission = backend_permissions[table];
  let isUpdatePermission = false;

  if (
    has_admin_privileges ||
    (tablePermission &&
      tablePermission.includes(Str.backendPermissionOpts.write))
  ) {
    isUpdatePermission = true;
  }

  console.log(tablePermission);

  return (
    <>
      <div className="justify-between flex items-center pt-1">
        <span className="text-sm text-gray-600">
          {isCreateRow ? "Create new row" : "Row detail"}
        </span>
        {!isCreateRow &&
          selectedRow &&
          (isUpdatePermission) && (
            <div className="text-sm flex gap-x-6 items-center">
              <EditOutlined
                onClick={() =>
                  primaryKeyField ? setUserAction("update") : undefined
                }
                className={` cursor-pointer ${
                  primaryKeyField ? "text-blue-600" : "text-gray-400"
                }`}
              />
              <DeleteOutlined
                disabled={!primaryKeyField}
                onClick={() =>
                  primaryKeyField ? setUserAction("delete") : undefined
                }
                className={` cursor-pointer ${
                  primaryKeyField ? "text-red-600" : "text-gray-400"
                }`}
              />
            </div>
          )}
      </div>
    </>
  );
};

export default DrawerTitle;
