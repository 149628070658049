import { mentionItemToKeyword } from "./keyword_utils";

export const FlagOption = ({ title, flag }) => {
  return (
    <div className="flex gap-x-2 items-center">
      <img
        src={`/${flag}.png`}
        alt="london flag"
        className="w-4  self-center"
      />
      <span>{title}</span>
    </div>
  );
};

export const Str = {
  paystackLinks: {
    Hobby: "https://paystack.com/pay/3auqdxuyay",
    Business: "https://paystack.com/pay/x74pp2u0lh",
    Startup: "https://paystack.com/pay/3644wqq6f3",
  },
  rowDisplayOptions: {
    row_data: "Data",
    actions: "Actions",
    relationships: "Relationships",
  },
  nodeTypes: {
    title: {
      type: "titletext",
      description: "Display texts ",
      name: "Text",
      attributes: {
        label: {
          title: "Label",
          key: "label",
        },
      },
    },
    textinput: {
      type: "textinput",
      description: "Control other components or queries with texts",
      name: "Text input",
      attributes: {
        textinputtype: {
          title: "Input type",
          key: "textinputtype",
        },
        label: {
          title: "Label",
          key: "label",
        },
      },
    },
    select: {
      type: "select",
      description: "Select one value from a list of options",
      name: "Select dropdown",
      attributes: {
        options: {
          title: "Input options",
          key: "options",
        },
        label: {
          title: "Label",
          key: "label",
        },
      },
    },
    table: {
      type: "table",
      description: "Display tabular data with pagination",
      name: "Table",
      attributes: {
        query: {
          title: "Table query",
          key: "query",
        },
        label: {
          title: "Label",
          key: "label",
        },
      },
    },
    submit: {
      type: "submit",
      description: "Trigger actions like run queries, export data, etc.",
      name: "Submit button",
      attributes: {
        onSubmit: {
          title: "After submit",
          key: "onSubmit",
        },
        label: {
          title: "Label",
          key: "label",
        },
      },
    },
  },
  submitCommandTypes: {
    insert: {
      label: "Insert to table",
      key: "insert",
    },
    delete: {
      label: "Delete from table",
      key: "delete",
    },
    update: {
      label: "Update table row",
      key: "update",
    },
    webhook: {
      label: "Send data to webhook",
      key: "webhook",
    },
  },
  commandOpt: [
    {
      value: "hint",
      label: "Select a command",
      disabled: true,
    },
    { value: "insert", label: "Insert into table" },
    { value: "update", label: "Update row in table" },
    { value: "delete", label: "Delete from  table" },
    {
      value: "webhook",
      label: "Submit data to a webhook",
    },
    {
      value: "pop",
      label: "Remove command",
    },
  ],
  filterOperators: [
    { value: "contains", label: "Contains" },
    { value: "equal", label: "Equal to" },
    { value: "greater", label: "Greater than" },
    { value: "lesser", label: "Less than" },
    { value: "lesser_equal", label: "Less than or equal" },
    { value: "greater_equal", label: "Greater than or equal" },
  ],
  datasourceTypesNew: [
    { value: "mysql", label: "MySQL (V8)" },
    {
      value: "postgresql",
      label: "PostgreSQL",
      disabled: true,
    },
  ],
  datasourceTypesExisting: [
    { value: "mysql", label: "MySQL" },
    {
      value: "postgresql",
      label: "PostgreSQL",
    },
  ],
  availableDbRegions: [
    {
      value: "lon1",
      label: <FlagOption title={"London"} flag={"england"} />,
    },
    {
      value: "fra1",
      label: <FlagOption title={"Frankfurt"} flag={"germany"} />,
    },
    {
      value: "nyc1",
      label: <FlagOption title={"New york"} flag={"usa"} />,
    },
    {
      value: "tor1",
      label: <FlagOption title={"Toronto"} flag={"canada"} />,
    },
    {
      value: "syd1",
      label: <FlagOption title={"Sydney"} flag={"australia"} />,
    },
  ],
  availableDbSizes: [
    {
      value: "db-s-1vcpu-1gb",
      label: "1GB ram / 10 GB SSD",
      cost: "$15 USD monthly",
    },
    {
      value: "db-s-1vcpu-2gb",
      label: "2GB ram / 30 GB SSD",
      disabled: false,
      cost: "$40 USD monthly",
    },
    {
      value: "db-s-2vcpu-4gb",
      label: "4GB ram / 60 GB SSD",
      disabled: false,
      cost: "$60 USD monthly",
    },
  ],

  backendPermissionOpts: {
    read: "Read",
    write: "Write",
    update: "Update",
    delete: "Delete",
  },

  defaultSQLKeywords: [
    mentionItemToKeyword("limit", "limit", "return x rows"),
    mentionItemToKeyword("Max limit", "maxlimit", "return max rows (15k)"),
    mentionItemToKeyword("Current day", "date", "Format: 1970-08-21"),
    mentionItemToKeyword(
      "Current date and time",
      "datetime",
      "Format: 1970-08-21 07:56:08"
    ),
  ],
};
