import { MButton } from "../../Common/buttons";
import { WarningFilled } from "@ant-design/icons";

export const NavError = ({ loadDataSourceSchema }) => {
  return (
    <div className="h-full flex items-center justify-center">
      <div className="flex flex-col items-center gap-y-4">
        <WarningFilled className="text-2xl" />
        <span className="text-black text-sm">
          Unable to load the datasource
        </span>
        <MButton
          onClick={loadDataSourceSchema}
          label={"Try again"}
          className={"py-1"}
        />
      </div>
    </div>
  );
};
