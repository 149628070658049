import { Button, Drawer, Input, Select, Spin, Switch, message } from "antd";
import { PlusCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Str } from "../../utils/constants";
import { getTableColumnsFromSchema } from "../../utils/schema_utils";
import { v4 as uuidv4 } from "uuid";
import { MButton } from "../Common/buttons";

const TriggerDrawer = ({
  onToggleDataDrawer,
  openDataDrawer,
  table,
  schema,
  appEvent,
}) => {
  const params = useParams();
  const tabColumns = getTableColumnsFromSchema(schema.databaseSchema, table);

  const dummyTriggers = [
    {
      action: "signup",
      name: "Send new user data to Sendgrid and Mixpanel",
      filter: [],
      delay: 0,
    },
    {
      action: "phone update",
      name: "Send new phone number to Mixpanel",
      filter: [],
      delay: 5,
    },
    {
      action: "cart abandonment",
      name: "Trigger Zapier re-targeting workflow",
      filter: [],
      delay: 10,
    },

    {
      action: "new order received",
      name: "Call Bolt delivery endpoint",
      filter: [],
      delay: 0,
    },
    {
      action: "update",
      name: "Transaction abandoned trigger",
      filter: [],
      delay: 60,
    },

    {
      action: "create",
      name: "New payment method added trigger",
      filter: [],
      delay: 0,
    },
    {
      action: "update",
      name: "Existing payment method updated trigger",
      filter: [],
      delay: 0,
    },
  ];

  const addTriggerClicked = () => {
    console.log("Hello world");
  };

  return (
    <Drawer
      title={
        <div className="justify-between flex items-center pt-1">
          <span className="text-xs text-gray-600">Manage {table} triggers</span>
          <div className="flex gap-x-2">
            <Button
              onClick={addTriggerClicked}
              className="text-gray-700  text-lg font-bold mx-2 px-1 flex flex-row gap-x-2 items-center"
              type="text"
            >
              <PlusCircleFilled />
            </Button>
          </div>
        </div>
      }
      closeIcon={<CloseCircleOutlined />}
      placement="right"
      onClose={() => onToggleDataDrawer(false)}
      open={openDataDrawer}
    >
      <div className="sidebar-box">
        {dummyTriggers.map((trigger) => {
          return (
            <TriggerItem
              action={trigger.action}
              delay={trigger.delay}
              title={trigger.name}
            />
          );
        })}

        <div className="read-more relative">
          <div className="flex flex-col  absolute center bottom-10">
            <span className="my-2 font">
              Trigger external APIs and workflows based on set filters, when a
              table data is added or updated within or outside Sequelbase.
            </span>
            <MButton className={"px-1 py-1"} label={" Coming soon"} />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default TriggerDrawer;

const TriggerItem = ({ title, delay, action, tabColumns = [] }) => {
  return (
    <div className="flex cursor-pointer hover:opacity-30 transition-all duration-200 flex-col gap-x-2 p-2 rounded-lg shadow-sm my-4  border border-gray-200 text-xs">
      <div className="my-2 flex justify-between items-center">
        <span className="font-bol">{title}</span>
        <img src="/share.png" alt="open" className="w-3 h-3" />
      </div>

      <div className="">
        <span
          className={`px-2 py-1  ${
            delay ? "bg-purple-100" : "bg-orange-100"
          } rounded-lg inline-block`}
        >
          {delay
            ? `${delay} minutes after ${action}`
            : `Immediately after ${action}`}
        </span>
      </div>
    </div>
  );
};
