import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const MButton = ({
  onClick,
  loading,
  label,
  icon,
  className,
  disabled,
  type = "text",
}) => {
  return (
    <button
      disabled={loading || disabled}
      onClick={onClick}
      className={` bg-blue-500 text-white p-3 px-5 rounded-full ${className}  bg-gradient-to-r from-[#2263E6] to-[#9747FF]`}
    >
      <div className="relative">
        <span> {label}</span>
        <span className=" absolute bottom-[1px] ">
          {loading && <LoadingOutlined />}
        </span>
      </div>
    </button>
  );
};

export const MButtonWhite = ({
  onClick,
  loading,
  label,
  icon,
  className,
  type = "text",
}) => {
  return (
    <button
      className={`text-black p-3 px-5 rounded-full ${className}  bg-white`}
    >
      {label}
    </button>
  );
};
