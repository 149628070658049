import React, { useContext, useEffect, useState } from "react";
import { MButton } from "../Common/buttons";
import { DownOutlined } from "@ant-design/icons";
import {
  makeClientSideApiRequest,
  makeDeleteRequest,
  makePutRequest,
  truncate,
} from "../../utils";
import { ContextServiceContext } from "../../context/schema_context";
import { Dropdown, Space, Spin, Typography, message } from "antd";

const SavedWorkFlows = ({
  savedWorkflows,
  setSavedWorkflows,
  onSelectWorkflow,
  onCreateWorkflow,
  onSelectUpdatedWorkflow,
}) => {
  const contextService = useContext(ContextServiceContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadWorkFlows();
  }, []);

  const loadWorkFlows = async () => {
    try {
      setLoading(true);
      const response = await makeClientSideApiRequest(
        `/api/datasource/${contextService.schema.databaseSchema.datasourceId}/workflows`
      );
      if (!response.data) {
        setSavedWorkflows(undefined);
        message.warning(response.reason);
        return;
      } else if (response.data.data?.length > 0) {
        setSavedWorkflows(response.data.data);
      } else {
        setSavedWorkflows(undefined);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSavedWorkflows(undefined);
    }
  };

  const deleteWorkFlow = async (workflowId) => {
    try {
      setLoading(true);
      const response = await makeDeleteRequest(
        `/api/datasource/${contextService.schema.databaseSchema.datasourceId}/workflows/${workflowId}`,
        { worflow_id: workflowId }
      );
      if (response.status == "success") {
        message.success("Workflow deleted");
      } else {
        message.warning(response.reason);
      }
      setLoading(false);
      loadWorkFlows();
    } catch (error) {
      setLoading(false);
      message.error("Request failed");
    }
  };

  const generateDropDown = (workflow) => {
    console.log(workflow);
    return [
      {
        key: "1",
        label: (
          <span
            className="block w-full cursor-pointer"
            onClick={() => onSelectUpdatedWorkflow(workflow)}
          >
            {" "}
            Edit
          </span>
        ),
      },
      {
        key: "2",
        label: (
          <span
            className="block w-full cursor-pointer"
            onClick={() => deleteWorkFlow(workflow.id)}
          >
            {" "}
            Delete
          </span>
        ),
      },
      {
        key: "3",
        label: (
          <span
            className="block w-full cursor-pointer"
            onClick={() => onSelectWorkflow(workflow)}
          >
            {" "}
            Enter workflow
          </span>
        ),
      },
    ];
  };

  const handleOption = (e, option) => {
    console.log(option);
  };

  return (
    <>
      {savedWorkflows && savedWorkflows.length < 1 && (
        <div className="h-screen h-full  flex flex-col justify-center items-center">
          {" "}
          <div className="">
            {" "}
            <Spin spinning className="text-lg text-center block"></Spin>{" "}
          </div>
        </div>
      )}
      {savedWorkflows && (
        <Spin spinning={loading}>
          <div className="w-11/12 mx-auto">
            <div className="my-8 flex justify-between items-center">
              <h1 className="px-3 py-3 text-[14px] rounded-full bg-gray-100 font-bold">
                Saved workflows
              </h1>
              <MButton onClick={onCreateWorkflow} label={"create workflow"} />
            </div>

            <div className="flex  flex-wrap	gap-y-4 text-sm">
              {savedWorkflows?.slice(0, 12).map((workflowItem) => (
                <div
                  key={workflowItem.id}
                  className="w-3/12 cursor-pointer flex-wrap "
                >
                  <div className="mx-2 relative">
                    <div className="text-xs flex flex-col p-0 bg-white shadow-sm p-3 rounded gap-y-2">
                      <div className=""> {truncate(workflowItem.name)}</div>
                      <div className="h-10 text-gray-400">
                        {" "}
                        {workflowItem.question}{" "}
                        <Dropdown
                          onClick={handleOption}
                          className="absolute top-1 right-2"
                          menu={{
                            items: generateDropDown(workflowItem),
                          }}
                        >
                          <Typography.Link>
                            <Space>
                              <DownOutlined />
                            </Space>
                          </Typography.Link>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Spin>
      )}
    </>
  );
};

export default SavedWorkFlows;
