export const objAsKeywords = (obj, keyWord) => {
    const allKeyWords = []
    const objKeys = Object.keys(obj)
    for (const index in objKeys) {
        const key = objKeys[index]

        const newKeyword = `@${keyWord}.${key}`
        allKeyWords.push({ [newKeyword]: obj[objKeys[index]] })
    }
    return allKeyWords
}

export const textAsKeyword = (text, keyWord) => {
    return { [keyWord]: text }
}

export const interpolateTextWithKeywords = (keyWords, text = '') => {

    let proccessedString = text;
    for (const index in keyWords) {
        const keyword = keyWords[index]
        const objKey = Object.keys(keyword)
        proccessedString = proccessedString.replace(objKey[0], keyword[objKey[0]])
    }

    return proccessedString;
}

export const getTableNamesFromSchemaAsKeywordDoms = (schema) => {
    return schema?.tables?.map((table) => {
        return mentionItemToKeyword(table, table, "Table name");
    });
};

export const getTableNamesFromSchemaAsKeywords = (schema) => {
    return schema?.tables?.map((table) => {
        return { [`@${table}`]: table };
    });
};

export const getTableColumnsFromSchemaAsKeywordDoms = (schema, table) => {
    return schema?.columns?.[table]?.map((col) => {
        return mentionItemToKeyword(col.Field, col.Field, col.Type);
    });
};

export const mentionItemToKeyword = (label, value, description) => {
    return {
        value: value,
        label: (
            <div className="flex flex-row gap-x-2 gap-y-2 items-center ">
                <div> {label} </div>
                <div className="text-xs text-gray-400">{description}</div>
            </div>
        ),
    };
};