import {
  fetchUserOrDie,
  makeClientSideApiRequest,
  makePostRequest,
  setUserInfo,
} from "../utils";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useParams } from "react-router-dom";
import { MButton } from "../components/Common/buttons";
import { Helmet } from "react-helmet";

export default function CompleteInvite() {
  const [loading, setLoading] = useState(true);
  const [submittingInvite, setSubmittingInvite] = useState(false);
  const [inviteData, setInviteData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [cookies, setCookie] = useCookies(["access_token", "refresh_token"]);
  const [password, setPassword] = useState(undefined);
  const params = useParams();

  useEffect(() => {
    getInvite();
  }, []);

  const getInvite = async () => {
    try {
      setLoading(true);
      const response = await makeClientSideApiRequest(
        `/api/onboarding/invite/${params?.invite_code}`
      );
      if (response && response.status === "success" && response.data != null) {
        setLoading(false);
        setInviteData(response.data);
      } else {
        setLoading(false);
        setError(response.reason);
      }
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  const onSubmitInvite = async () => {
    setSubmittingInvite(true);
    try {
      const response = await makePostRequest("/api/onboarding/invite/complete", {
        password: password,
        invite_code: params?.invite_code
      });

      if (response.status == "success") {
        setSubmittingInvite(false);
        message.success("Login success");
        let expires = new Date();
        expires.setTime(expires.getTime() + 36000 * 100000);
        setCookie("access_token", response.data.token, {
          path: "/",
          expires,
        });
        setCookie("refresh_token", response.data.token, {
          path: "/",
          expires,
        });

        localStorage.setItem("token", response.data.token);
        setUserInfo(response.data);
        window.location = "/";
      } else {
        message.error(response.reason);
        setSubmittingInvite(false);
      }

    } catch (error) {
      message.error(error.message);
      setSubmittingInvite(false);
    }
  };

  const onSubmitLogin = async () => {
    if (!password) {
      message.error("Enter a valid password");
    } else {
      await onSubmitInvite();
    }
  };

  const onInputData = (type, text) => {
    setPassword(text);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SequelBase | Complete invite</title>
        <meta name="description" content="Sequelbase" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        <link rel="icon" href="/database_logo_smaller.png" />
        <link rel="canonical" href="https://app.sequelbase.com/connect" />
      </Helmet>
      <div className="bg-white">
        <main className="h-screen bg-white  mx-auto  w-full flex ">
          {!inviteData && loading && (
            <div className="flex items-center justify-center h-full w-6/12">
              {" "}
              <Spin spinning={loading} />
            </div>
          )}
          {!inviteData && error && !loading && (
            <div className="flex flex-col items-center justify-center h-full w-6/12">
              {" "}
              <span className="text-sm block text-center my-5">{error}</span>
              <MButton
                className={"py-2"}
                onClick={getInvite}
                label={"Try again"}
              />
            </div>
          )}
          {inviteData && (
            <div className="flex flex-col gap-y-4 w-6/12 items-center h-full justify-start py-10">
              <div className="w-10/12 mx-auto h-full">
                <div>
                  <div className="flex items-center my-4">
                    <img
                      alt="logo"
                      className="w-8"
                      src="/database_black.png"
                      width={100}
                      height={20}
                    />
                    <h1
                      style={{ fontFamily: "mainFontBold" }}
                      className="text-gray-800 text-lg ml-1 font-black"
                    >
                      {" "}
                      SequelBase
                    </h1>
                  </div>
                  <span className="text-gray-600 text-sm">
                    Need to create a new organization?{" "}
                    <span className="text-blue-600">
                      <Link to={"/login"}>Signup</Link>{" "}
                    </span>
                  </span>
                </div>
                <div className="flex flex-col h-full justify-start mt-10">
                  <div className="my-0"></div>
                  <h1
                    style={{ fontFamily: "mainFontBold" }}
                    className="font-black text-2xl my-3"
                  >
                    Complete invite to join ({inviteData.company_name})
                  </h1>

                  <div className="my-2">
                    <span className="text-xs">Email address</span>
                    <Input
                      name={"email_add"}
                      readOnly={true}
                      disabled={true}
                      value={inviteData.email}
                      onChange={(val) => onInputData("email", val.target.value)}
                      placeholder="email@example.com"
                      className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                    />
                  </div>

                  <div className="my-2">
                    <span className="text-xs">Full name</span>
                    <Input
                      name={"fullname"}
                      readOnly={true}
                      disabled={true}
                      value={inviteData.fullname}
                      onChange={(val) =>
                        onInputData("fullname", val.target.value)
                      }
                      placeholder="John fred"
                      className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                    />
                  </div>

                  <div className="my-2">
                    <span className="text-xs">Role description</span>
                    <Input
                      name={"role"}
                      readOnly={true}
                      disabled={true}
                      value={inviteData.role}
                      onChange={(val) => onInputData("role", val.target.value)}
                      placeholder="Engineering"
                      className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                    />
                  </div>

                  <div className="my-2">
                    <span className="text-xs">Create password</span>
                    <Input
                      name={"password"}
                      type="password"
                      onChange={(val) =>
                        onInputData("password", val.target.value)
                      }
                      placeholder="********"
                      className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                    />
                  </div>

                  <div className="w-full flex mt-4">
                    <MButton
                      onClick={onSubmitLogin}
                      loading={submittingInvite}
                      className={"w-full"}
                      label={`Join ${inviteData.company_name} on Sequelbase`}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="h-full bg-gray-100 w-6/12 flex flex-col items-center justify-center">
            <img className="" src="/login_img.svg" />
            <div className="px-20 text-center my-6">
              <span className="">
                Modern MySQL & PostgreSQL platform powered by AI.{" "}
                <span className="font-boldd">
                  <span className="font-bold"> Advanced filters, </span>{" "}
                  <span className="font-bold">Data relationships, </span>
                  <span className="font-bold">No code workflows, </span>
                  <span className="font-bold">Seamless imports </span>, out of
                  the box.
                </span>{" "}
                <span className="font-bold">without writing any codes </span>
              </span>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
