import { Drawer, Input, Popconfirm, Select, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Str } from "../../../../utils/constants";
import InputActions from "./input_actions";
import SelectActions from "./select_actions";
import GeneralInput from "./general_actions";
import OnSubmitActions from "./submit_actions/submit_actions";
import { NodesServiceContext } from "../../../../context/nodes_context";
import { MButton } from "../../../Common/buttons";

const NodeActionDrawer = ({ dataDrawer = {}, onNodeActionComplete }) => {
  const [formConfig, setFormConfig] = useState({ label: undefined });
  const nodesContext = useContext(NodesServiceContext);
  const { type, id, data: existingData } = dataDrawer.node || {};

  useEffect(() => {
    // set up the required inputs depending on the action type
    switch (type) {
      case Str.nodeTypes.title.type:
        console.log(existingData);
        setFormConfig({
          ...formConfig,
          ...existingData,
        });
        break;
      case Str.nodeTypes.textinput.type:
        setFormConfig({
          ...formConfig,
          ...existingData,
        });
        break;
      case Str.nodeTypes.select.type:
        console.log("Existing data", existingData);
        setFormConfig({
          ...formConfig,
          ...existingData,
        });
        break;
      case Str.nodeTypes.submit.type:
        setFormConfig({
          ...formConfig,
          ...existingData,
        });
        break;
      default:
        break;
    }
  }, []);

  const resetState = () => {
    setFormConfig({});
  };

  const isStateEmpty = () => {
    return Object.keys(formConfig).length < 1;
  };

  if (!type && !isStateEmpty()) {
    resetState();
  }

  const onFormSetupComplete = (key, value) => {
    console.log(key, value);
    setFormConfig({ ...formConfig, [key]: value });
  };

  const validateSelectFieldMustHaveOptions = () => {
    // submit form must have onSubmit
    if (
      dataDrawer.node.type === Str.nodeTypes.select.type &&
      (!formConfig["options"] || formConfig["options"]?.length < 1)
    ) {
      message.warning(` Add select options to continue`);
      return false;
    }
    // submit form must have onSubmit
    return true;
  };

  const validateSubmitMustHaveOnSubmit = () => {
    // submit form must have onSubmit
    // remove all nodes without submit command data
    if (
      dataDrawer.node.type === Str.nodeTypes.submit.type
    ) {
      const allOnSubmitCommands = formConfig["onSubmit"] ?? [];
      if (!allOnSubmitCommands || allOnSubmitCommands?.length < 1) {
        message.warning(` Setup at least one command to continue`);
        return false;
      }
    }
    // submit form must have onSubmit
    return true;
  };

  const validateAllFieldFormMustHaveOnLabel = () => {
    if (!formConfig.label || formConfig.label.length < 1) {
      message.warning("Enter a label name");
      return false;
    }
    // all forms  form must have a label
    return true;
  };

  const validateConfig = () => {
    if (!validateAllFieldFormMustHaveOnLabel()) return false;
    if (!validateSubmitMustHaveOnSubmit()) return false;
    if (!validateSelectFieldMustHaveOptions()) return false;

    return true;
  };

  const confirm = (e) => {
    onNodeActionComplete(undefined)
  };

  const cancel = (e) => {
    console.log(e);
  };

  console.log(type);

  return (
    <Drawer
      title={
        <div className="justify-between flex items-center pt-1">
          <span className="text-sm text-gray-600">Set up field</span>
        </div>
      }
      closeIcon={
        type !== Str.nodeTypes.title.type ? <Popconfirm
          title="Delete the component?"
          description="The submit button and any on-submit configuration will also be removed"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined className="text-red-600" />
        </Popconfirm> :
          <DeleteOutlined className="text-red-600" />
      }
      placement="right"
      onClose={() => type !== Str.nodeTypes.title.type ? console.log() : onNodeActionComplete(undefined)}
      closable={true}
      maskClosable={false}
      open={dataDrawer.isOpen}
      push={false}
      keyboard={false}
      destroyOnClose={true}
    >
      {type && (
        <div className="flex flex-col ">
          <GeneralInput
            onChangeInput={onFormSetupComplete}
            value={formConfig["label"]}
          />

          {type === Str.nodeTypes.textinput.type && (
            <InputActions
              values={formConfig}
              onChangeInput={onFormSetupComplete}
            />
          )}

          {type === Str.nodeTypes.select.type && (
            <SelectActions
              values={formConfig}
              onChangeInput={onFormSetupComplete}
            />
          )}

          {type === Str.nodeTypes.submit.type && (
            <OnSubmitActions
              values={formConfig}
              onFormSetupComplete={onFormSetupComplete}
            />
          )}

          <MButton
            label={" Set up"}
            className={"my-6"}
            onClick={() => {
              if (validateConfig()) {
                onNodeActionComplete({
                  ...formConfig,
                });
              }
            }}
          ></MButton>
        </div>
      )}
    </Drawer >
  );
};

export default NodeActionDrawer;
