import {
  AppstoreFilled,
  QuestionCircleFilled,
  SettingFilled,
  WarningFilled,
  HomeOutlined,
  CreditCardFilled,
  UserSwitchOutlined,
  DatabaseFilled,
} from "@ant-design/icons";
import { Spin, message } from "antd";
import { v4 as uuidv4 } from "uuid";
import ErrorBoundary from "../Common/ErrorBoundary";
import WorkFlow from "../workflow";
import { useContext } from "react";
import { ContextServiceContext } from "../../context/schema_context";
import Question from "../Question";
import { RangeRowCountWidget } from "../Common/widgets/RangeRowCountWidget";
import { TotalRowCountWidget } from "../Common/widgets/TotalRowCountWidget";
import { DashboardRangeCountWidget } from "../Common/widgets/DashboardRangeCountWidget";

export const Selector = () => {
  const context = useContext(ContextServiceContext);
  const isReady = context.schema.databaseSchema;
  const schema = context.schema.databaseSchema;
  const highlightTables =
    isReady && schema.highlightTables ? JSON.parse(schema.highlightTables) : [];

  const _comingSoon = () => {
    message.warning("Feature coming soon");
  };

  const _onPushQuestionTab = () => {
    const key = uuidv4();
    context.events.setAppEvent({
      type: "pushTabEvent",
      tabData: {
        label: "New Question ",
        type: "question",
        children: (
          <ErrorBoundary id="question_tab">
            <Question tabId={key} />
          </ErrorBoundary>
        ),
        key: key,
      },
      tabKey: key,
      tabName: "New Question ",
    });
  };

  const _onPushWorkflowTab = () => {
    const key = uuidv4();
    context.events.setAppEvent({
      type: "pushTabEvent",
      tabData: {
        label: "Workflows ",
        type: "workflow",
        children: (
          <ErrorBoundary id="workflow_tab">
            <WorkFlow tabId={key} />
          </ErrorBoundary>
        ),
        key: key,
      },
      tabKey: key,
      tabName: "Workflows",
    });
  };

  return (
    <>
      {!isReady && (
        <div className="h-screen flex justify-center items-center">
          {" "}
          <Spin spinning={true} />
        </div>
      )}

      {isReady && (
        <div className="flex  gap-y-8  mt-20 mx-auto w-11/12 flex-wrap">
          {highlightTables.map((tbl) => {
            return (
              <div className="px-2 flex w-6/12">
                <DashboardRangeCountWidget table={tbl} />
              </div>
            );
          })}
        </div>
      )}

      {isReady && (
        <div
          className={` mt-6 transition-all h-screen flex items-center mt-40 mx-auto w-6/12 flex flex-col`}
        >
          <div className="p-2 px-3 mt-10 mb-2 text-3xl">
            {" "}
            <span className="text-xl font-bold">Quick actions</span>{" "}
          </div>

          <div className="flex p-2 items-center justify-center flex-wrap gap-y-4 gap-x-4">
            <MenuItem
              onClick={_onPushQuestionTab}
              name={"Questions"}
              icon={<QuestionCircleFilled />}
            />
            <MenuItem
              onClick={() => {
                _onPushWorkflowTab();
              }}
              name={"Workflows"}
              icon={<AppstoreFilled />}
            />
            <MenuItem
              onClick={_comingSoon}
              name={"Settings "}
              icon={<SettingFilled />}
            />
            <MenuItem
              onClick={_comingSoon}
              name={"Schema config "}
              icon={<DatabaseFilled />}
            />
            <MenuItem
              onClick={_comingSoon}
              name={"Team setting "}
              icon={<UserSwitchOutlined />}
            />
            <MenuItem
              onClick={_comingSoon}
              name={"Billing "}
              icon={<CreditCardFilled />}
            />
          </div>
        </div>
      )}
    </>
  );
};

const MenuItem = ({ onClick, name, icon }) => {
  return (
    <button
      onClick={onClick}
      color="magenta"
      className="rounded-full transition-all duration-200 text-sm bg-white hover:text-white hover:border-[#7183d7] hover:bg-[#7183d7] text-black p-2 border-2 flex items-center gap-x-2 shadow"
    >
      {icon}
      <span className="text-xl">{name}</span>
    </button>
  );
};
