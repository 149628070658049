import { displayAsOptions } from "../../utils";
import PropTypes from "prop-types";
import { Button, Dropdown, Input, Modal } from "antd";
import {
  CaretDownFilled,
  CloseCircleFilled,
  PlayCircleFilled,
  SettingFilled,
} from "@ant-design/icons";
import React from "react";
import { presentationTypes } from ".";
import { MButton } from "../Common/buttons";
import { QuestionConfigContext } from "../../context/question_config_context";

class RecordHighlight extends React.Component {
  static propTypes = {
    onTogglePresentation: PropTypes.func,
    loading: PropTypes.func.isRequired,
    isSavedApp: PropTypes.bool,
    savingQuestion: PropTypes.bool.isRequired,
  };

  state = {
    presentationType: presentationTypes.TABLE,
  };

  toggleDisplayedAs = (type) => {
    const selected = displayAsOptions.find((option) => option.key == type.key);
    this.setState({ ...this.state, presentationType: selected.type });
    this.props.onTogglePresentation(selected.type);
  };

  render() {
    const {
      total,
      timeTaken,
      isCreateToolOpen,
      createToolLoading,
      onCreateTool,
      noData,
      errMsg,
    } = this.props;

    console.log(this.props.saveQuestion);
    const { presentationType } = this.state;
    const displayedAs = displayAsOptions.find(
      (option) => option.type == presentationType
    );

    if (noData || errMsg) {
      return <div></div>;
    }
    return (
      <>
        <div className=" pt-2 pr-3 relative  w-full flex justify-between">
          <div className="text-black flex gap-x-6 p-5">
            <HighlighItem
              tooltip={"Total records returned"}
              icon={"/row.png"}
              label={` ${total} Records returned`}
            />
            <HighlighItem
              tooltip="Time taken to run query"
              icon={"/clock.png"}
              label={` ${parseFloat(timeTaken).toFixed(2)} Seconds`}
            />

            <Dropdown
              menu={{
                items: displayAsOptions,
                onClick: this.toggleDisplayedAs,
              }}
            >
              <a
                href="#"
                className="text-gray-700 text-xs flex gap-x-2 items-center"
              >
                <img
                  className="w-4"
                  src={displayedAs?.img}
                  width={100}
                  height={40}
                />
                {`Displayed as ${displayedAs?.title}`}
                <CaretDownFilled />
              </a>
            </Dropdown>
          </div>
          <div className="flex gap-x-1 items-center  justify-end ">
            {/*    <MButton
            onClick={onSaveQuestion}
            loading={saveQuestionLoading}
            className={` ${
              questionLog.name ? "text-green-700" : "text-blue-700"
            }`}
            icon={
              questionLog.name ? <CheckCircleFilled /> : <PlusCircleFilled />
            }
            label={` ${questionLog.name ? "Saved question" : "Save question"}`}
          /> */}
            {/* <MButton
          className={"text-blue-700 "}
          icon={<SendOutlined />}
          label="Share"
        /> */}

            <MButton
              onClick={() =>
                this.context.isSavedQuestion
                  ? this.props.saveQuestion()
                  : this.context.onToggleSaveModal(true)
              }
              loading={this.props.loading}
              className={"text-white"}
              icon={
                this.context.isSavedQuestion ? (
                  <SettingFilled />
                ) : (
                  <PlayCircleFilled />
                )
              }
              label={`${
                this.context.isSavedQuestion
                  ? "Update question"
                  : "Save question"
              }`}
            />
          </div>
        </div>
      </>
    );
  }
}

RecordHighlight.contextType = QuestionConfigContext;
export default RecordHighlight;

const HighlighItem = ({ label, icon, tooltip }) => {
  return (
    <a
      href="#"
      title={tooltip}
      className="text-gray-700 text-xs flex gap-x-2 items-center"
    >
      <img className="w-4" src={icon} width={100} height={40} />
      {label}
    </a>
  );
};
