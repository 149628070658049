import {
  AppstoreFilled,
  QuestionCircleFilled,
  SettingFilled,
  WarningFilled,
  HomeOutlined,
  CreditCardFilled,
  UserSwitchOutlined,
  DatabaseFilled,
} from "@ant-design/icons";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";

import { Card, Dropdown, Space, Spin, message } from "antd";

import { useContext, useEffect, useState } from "react";
import { ContextServiceContext } from "../../context/schema_context";
import { MButton } from "../Common/buttons";
import { makeClientSideApiRequest, makePutRequest } from "../../utils";
import { useParams } from "react-router-dom";
import { Str } from "../../utils/constants";
import dayjs from "dayjs";

export const Billing = () => {
  const context = useContext(ContextServiceContext);
  const isReady = context.schema.databaseSchema;
  const user = context.user;
  const params = useParams();
  const [loading, setLoading] = useState();
  const [billingData, setBillingData] = useState(undefined);
  const [selectedPlan, setSelectedPlan] = useState(undefined);
  const [currentPlan, setCurrentPlan] = useState(undefined);

  useEffect(() => {

  })


  useEffect(() => {
    onLoadBillingInfo();
  }, []);

  const onLoadBillingInfo = async () => {
    setLoading(true);
    try {
      const response = await makeClientSideApiRequest(
        `/api/${params?.id}/billing`
      );

      if (response.status === "success") {
        setLoading(false);
        setBillingData(response.data);
        setSelectedPlan(response.data.billing?.plan)
        setCurrentPlan(response.data.billing?.plan)
      } else {
        setLoading(false);
        message.error(response.reason);
      }
      // this.setState({ ...this.state, loading: false });
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const onChangePlan = async (planId) => {
    try {
      const getPlan = billingData?.other_plans?.find((plan) => plan.id == planId)
      setSelectedPlan(getPlan)
      /*  setLoading(true);
       const response = await makePutRequest(
         `/api/datasource/${params?.id}/billing`,
         {
           datasource_id: params?.id,
           plan_id: planId,
         }
       );
 
       if (response.status == "success") {
         await onLoadBillingInfo();
       } else {
         message.error(response.reason);
         setLoading(false);
       } */
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const subscriptionActive = billingData?.billing?.is_active == 1
  const showPayBtn = !subscriptionActive || currentPlan.id !== selectedPlan.id || (billingData.is_billing_grace_period === true)
  const paymentLink = `${selectedPlan?.stripe_payment_link}?prefilled_email=${user?.email}`
  return (
    <>
      {isReady && (
        <div
          className={`  transition-all h-screen  items-start mt-6 mx-auto  px-12  flex-col`}
        >
          <div className="my-8 flex justify-between items-center">
            <h1 className="px-3 py-3 text-[14px] rounded-full bg-gray-100 font-bold">
              Manage billing
            </h1>
          </div>

          <div className="block w-full">
            <Card loading={loading} className="w-8/12">
              <div className="flex justify-between items-center">
                <MenuItem
                  name={"Plan "}
                  value={selectedPlan?.name}
                />
                <Dropdown
                  menu={{
                    items:
                      billingData?.other_plans?.map((plan) => {
                        return {
                          key: plan?.id,
                          label: (
                            <span onClick={() => onChangePlan(plan?.id)}>
                              {plan?.name}
                            </span>
                          ),
                        };
                      }) ?? [],
                  }}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      Change plan
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </div>

              <MenuItem
                name={"Usage "}
                value={` ${billingData?.total_usage} team members`}
              />
              <MenuItem
                name={"Monthly "}
                value={`${selectedPlan?.base_amount} EUR`}
              />

              <MenuItem
                name={"Next billing date "}
                value={dayjs(billingData?.billing?.next_billing_date).format('DD MMMM YYYY')}
              />

              <div className="flex justify-between items-center">
                <MenuItem
                  name={"Status "}
                  value={
                    billingData?.billing?.is_active === 0
                      ? "Subscription  inactive"
                      : "Subscription active"
                  }
                />
                {showPayBtn && <MButton
                  onClick={() =>
                    window.open(paymentLink, "blank")
                  }
                  label={"Make payment to activate plan"}
                  className={"px-2 py-1"}
                />
                }
              </div>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

const MenuItem = ({ onClick, name, value, icon }) => {
  return (
    <div className="flex flex-col my-5">
      <span className="text-xs text-gray-400">{name}</span>
      <span className="font-bold">{value} </span>
    </div>
  );
};
