import { Card, Spin, Statistic, message } from "antd";
import { OrderedListOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { makeClientSideApiRequest } from "../../../utils";
import { useParams } from "react-router-dom";

export const TotalRowCountWidget = ({ table }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const params = useParams();

  useEffect(() => {
    fetchCount();
  }, []);

  const fetchCount = async () => {
    setLoading(true);
    try {
      const response = await makeClientSideApiRequest(
        `/api/datasource/${params?.id}/widgets/totalRowCountFetch?table=${table}`
      );

      if (response.status === "success") {
        setLoading(false);
        setData(response.data);
      } else {
        setLoading(false);
        message.error(response.reason);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  //
  return (
    <Card loading={loading} className="w-full h-36" bordered={false}>
      <Statistic
        className="w-full"
        title={<span className="">{`Total ${table} count`}</span>}
        prefix={<OrderedListOutlined />}
        value={data}
      />
    </Card>
  );
};
