import {
  fetchUserOrDie,
  makeClientSideApiRequest,
  makePostRequest,
  setUserInfo,
} from "../utils";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { MButton } from "../components/Common/buttons";
import { Helmet } from "react-helmet";
import { InfoCircleOutlined } from "@ant-design/icons";
import GoogleLoginButton from "../components/Common/GoogleLogin";
export default function Register() {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [plans, setPlans] = useState([]);
  const [cookies, setCookie] = useCookies(["access_token", "refresh_token"]);

  const [data, setData] = useState({
    email: undefined,
    password: undefined,
    plan_id: undefined,
    phone: undefined,
    company_name: undefined,
    fullname: undefined,
  });

  useEffect(() => {
    loadPlans();
  }, []);

  const loadPlans = async () => {
    try {
      setLoading(true);
      const response = await makeClientSideApiRequest(`/api/plans`);
      if (!response.data) {
        setPlans(undefined);
        message.warning(response.reason);
        return;
      } else {
        setPlans(response.data);
        onInputData("plan_id", 2); // pro
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onSignUp = async (isGoogle, idToken) => {
    setLoading(true);
    try {
      let response;
      if (isGoogle) {
        response = await makePostRequest("/api/auth/google", {
          id_token: idToken,
        });
      } else {
        response = await makePostRequest("/api/auth/register", { ...data });
      }

      if (response.status == "success") {
        setLoading(false);
        message.success("Login success");
        let expires = new Date();
        expires.setTime(expires.getTime() + 36000 * 100000);
        setCookie("access_token", response.data.token, {
          path: "/",
          expires,
        });
        setCookie("refresh_token", response.data.token, {
          path: "/",
          expires,
        });

        localStorage.setItem("token", response.data.token);
        setUserInfo(response.data);
        window.location = "/";
      } else {
        message.error(response.reason);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const onSubmitSignup = async () => {
    if (!data.email) {
      message.error("Enter a valid email address");
    }

    if (!data.company_name) {
      message.error("Enter a company name");
    }

    if (!data.fullname) {
      message.error("Enter fullname");
    }

    if (!data.password) {
      message.error("Enter a valid password");
    } else {
      await onSignUp();
    }
  };

  const onInputData = (type, text) => {
    switch (type) {
      case "email":
        data.email = text;
        data.phone = text;
        break;
      case "password":
        data.password = text;
        break;
      case "fullname":
        data.fullname = text;
        break;
      case "company_name":
        data.company_name = text;
        break;
      case "plan_id":
        data.plan_id = text;
        break;
      default:
        break;
    }
  };

  const mappedPlans = plans?.map((pl) => {
    return { label: `${pl.name} `, value: pl.id };
  });
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SequelBase | Create SequelBase account</title>
        <meta name="description" content="Sequelbase" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        <link rel="icon" href="/database_logo_smaller.png" />
        <link rel="canonical" href="https://app.sequelbase.com/connect" />
      </Helmet>
      <div className="bg-white">
        <main className="h-screen bg-white  mx-auto  w-full flex ">
          <div className="flex flex-col gap-y-4 w-6/12 items-center h-full justify-start py-10">
            <div className="w-10/12 mx-auto h-full">
              <div>
                <div className="flex items-center my-4">
                  <img
                    alt="logo"
                    className="w-8"
                    src="/database_black.png"
                    width={100}
                    height={20}
                  />
                  <h1
                    style={{ fontFamily: "mainFontBold" }}
                    className="text-gray-800 text-lg ml-1 font-black"
                  >
                    {" "}
                    SequelBase
                  </h1>
                </div>
                <span className="text-gray-600 text-sm">
                  Already have an account? ?{" "}
                  <span className="text-blue-600">
                    <Link to={"/login"}>Login</Link>{" "}
                  </span>
                </span>
              </div>
              <div className="flex flex-col h-full justify-start mt-6">
                <div className="my-0"></div>
                <h1
                  style={{ fontFamily: "mainFontBold" }}
                  className="font-black text-2xl my-3"
                >
                  Create a new account
                </h1>

                <div className="my-3  items-center justify-start">
                  <GoogleLoginButton onSuccess={onSignUp} />
                </div>
                <div className="my-4 flex items-center justify-start">
                  <span className="text-gray-500 text-sm">
                    Or continue with email
                  </span>
                </div>

                <div className="my-2">
                  <span className="text-black text-sm">Fullname</span>
                  <Input
                    name={"fullname"}
                    onChange={(val) =>
                      onInputData("fullname", val.target.value)
                    }
                    placeholder="John Bench"
                    className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                  />
                </div>

                <div className="my-2">
                  <span className="text-black text-sm">Company name</span>
                  <Input
                    name={"company_name"}
                    onChange={(val) =>
                      onInputData("company_name", val.target.value)
                    }
                    placeholder="Fancy company"
                    className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                  />
                </div>

                <div className="my-2">
                  <span className="text-black text-sm">Email</span>
                  <Input
                    name={"email_add"}
                    onChange={(val) => onInputData("email", val.target.value)}
                    placeholder="email@example.com"
                    className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                  />
                </div>

                <div className="mt-2 mb-4">
                  <div className="flex gap-x-3 items-center">
                    <span className="text-black text-sm block">
                      Select Plan
                    </span>

                    {/*  <div className="flex items-center gap-x-2">
                      <InfoCircleOutlined color="blue" />
                      <a target="_blank" className="text-blue-700 underline text-sm block" href="https://sequelbase.com/pricing" rel="noreferrer">2 weeks free trial. Learn more</a>
                    </div> */}
                  </div>
                  <div className="mt-2">
                    <Select
                      onChange={(plan) => onInputData("plan_id", plan)}
                      className="w-full"
                      defaultValue={2}
                      options={mappedPlans}
                      loading={loading}
                    />
                  </div>
                </div>

                <div className="my-2">
                  <span className="text-black text-sm">Password</span>
                  <Input
                    name={"email_add"}
                    type="password"
                    onChange={(val) =>
                      onInputData("password", val.target.value)
                    }
                    placeholder="********"
                    className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                  />
                </div>

                <div className="w-full flex mt-4">
                  <MButton
                    onClick={onSubmitSignup}
                    loading={loading}
                    className={"w-full"}
                    label={"Create SequelBase account"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="h-full bg-gray-100 w-6/12 flex flex-col items-center justify-center">
            <img className="" src="/login_img.svg" />
            <div className="px-20 text-center my-6">
              <span className="">
                Modern MySQL & PostgreSQL platform powered by AI.{" "}
                <span className="font-boldd">
                  <span className="font-bold"> Advanced filters, </span>{" "}
                  <span className="font-bold">Data relationships, </span>
                  <span className="font-bold">No code workflows, </span>
                  <span className="font-bold">Seamless imports </span>, out of
                  the box.
                </span>{" "}
                <span className="font-bold">without writing any codes </span>
              </span>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
