import { Component } from "react";
import { Spin, message } from "antd";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import { PlusCircleOutlined } from "@ant-design/icons";

import { makeClientSideApiRequest } from "../../../utils";
import ErrorBoundary from "../../Common/ErrorBoundary";
import { ContextServiceContext } from "../../../context/schema_context";
import Backend from "../../Backend";
import Workflow from "../../workflow";
import ConnectNewDBModal from "../ConnectNewDBModal";
import RefreshExistingDBModal from "../RefreshExistingDBModal";
import { DBSchema } from "./DBSchema";
import { NavMenuItems } from "./MenuItems";
import { NavError } from "./NavError";
import Question from "../../Question";
import TeamManagement from "../../Team";
import { Billing } from "../../Billing";
import ManageApps from "../../apps";

class DataSourceSideNav extends Component {
  static propTypes = {
    setDatabaseSchema: PropTypes.func,
    onAskTableQuestion: PropTypes.func,
    isOpen: PropTypes.bool,
    router: PropTypes.any,
  };

  state = {
    errorMsg: undefined,
    datasourceName: undefined,
    datasource: undefined,
    pendingDataSource: undefined,
    datasources: [],
    loading: false,
    isShwTabl: true,
    searchInput: "",
    filtered: [],
    openConnectDbModal: false,
    openRefreshDbModal: false,
    publicCredentials: undefined,
    isDbTablesNotSetup: false,
    highlightTables: undefined,
    db_product: undefined,
    schema: {
      tables: undefined,
      columns: undefined,
      datasourceType: null,
      datasourceName: undefined,
    },
    tree: [],
    displayTree: [],
    expandedLeafs: [],
  };

  componentDidUpdate() {
    // alert("Updated");
  }

  componentDidMount() {
    if (typeof window != undefined) {
      this._loadDataSourceSchema(window.location.pathname.split("/")[2]);
    }
  }

  toggleConnectDBStatus = (
    status,
    data = undefined,
    isDbTablesNotSetup = false,
    isCloseable = false
  ) => {
    this._setState({
      openConnectDbModal: status,
      pendingDataSource: data,
      isDbTablesNotSetup: isDbTablesNotSetup,
      isCloseable,
    });
  };

  toggleRefreshDBModal = (
    status,
    data = undefined,
    isDbTablesNotSetup = false,
    publicCredentials
  ) => {
    this._setState({
      openRefreshDbModal: status,
      datasource: data ? data : this.state.datasource,
      isDbTablesNotSetup: isDbTablesNotSetup
        ? isDbTablesNotSetup
        : this.state.isDbTablesNotSetup,
      publicCredentials: publicCredentials
        ? publicCredentials
        : this.state.publicCredentials,
    });
  };

  setLoading = (status) => {
    this._setState({
      loading: status,
      errorMsg: status ? undefined : this.state.errorMsg,
    });
  };

  _setState = (newState, func = undefined) => {
    this.setState({ ...this.state, ...newState }, () => {
      if (func) func();
    });
  };

  _loadDataSourceSchema = async (datasource_id) => {
    this.setLoading(true);
    const { params } = this.props;
    try {
      const response = await makeClientSideApiRequest(
        `/api/datasource/${params.id}`
      );
      if (!response.data) {
        this._setState({
          errorMsg: "An error occured. Please contact support",
          loading: false,
        });
        return;
      }

      if (
        response.data.db_product &&
        response.data.db_product.status !== "active"
      ) {
        this.toggleConnectDBStatus(true, response.data, false, false);
        return;
      } else if (response.data.db_product) {
        setTimeout(() => {
          this.setState({
            ...this.state,
            db_product: response.data.db_product,
          });
        }, 200);
      }

      const {
        public_credentials: existingDbAttributes,
        db_product: newDbAttributes,
        highlight_tables: highlightTables,
      } = response.data;

      const tables = JSON.parse(response.data.tables);
      const columns = JSON.parse(response.data.columns);
      const tbl_relationships = JSON.parse(response.data.tbl_relationships);

      if (newDbAttributes && newDbAttributes.status && tables.length < 1) {
        // it's a new db provided by sequelbase and has no tables yet
        this.toggleConnectDBStatus(true, response.data.datasource, true, false);
        return;
      } else if (tables.length < 1) {
        // it's an existing db provided by the user but no tables yet
        setTimeout(() => {
          this.toggleRefreshDBModal(
            true,
            response.data.datasource,
            true,
            existingDbAttributes
          );
        }, 200);
      } else if (!highlightTables || JSON.parse(highlightTables)?.length < 1) {
        // it's an existing db provided by the user but no highlight db selected
        setTimeout(() => {
          this.toggleRefreshDBModal(
            true,
            response.data.datasource,
            false,
            existingDbAttributes
          );
        }, 200);
      }

      console.log("ALL TABLES", tables);
      const tree = tables.map((table) => {
        const tableColumns = columns[table]?.map((column) => {
          return {
            title: `${column.Field} [${column.Type}]`,
            key: `${column.Field}-${Math.random()}`,
            isLeaf: true,
          };
        });

        return {
          title: table,
          key: table,
          children: [tableColumns],
        };
      });
      const datasources = response.data.others.map((source) => {
        return {
          key: source.id,
          label: <a href={`/datasource/${source.id}`}>{source.name}</a>,
        };
      });

      // create new db
      datasources.push({
        key: "create",
        label: (
          <div className="flex items-center gap-x-1 cursor-pointer hover:text-black">
            {" "}
            <PlusCircleOutlined />{" "}
            <a className="hover:text-black" href={`/connect`}>
              {" "}
              New connection
            </a>
          </div>
        ),
      });
      // create new db

      console.log(response.data.public_credentials);
      const schema = {
        tables: tables,
        columns: columns,
        tbl_relationships: tbl_relationships,
        datasourceName: response.data.name,
        datasourceType: response.data.type,
        datasourceId: params.id,
        highlightTables: highlightTables,
      };

      console.log(existingDbAttributes);
      // set current schema in global context
      const schemaContext = this.context;
      schemaContext.schema.setDatabaseSchema(schema);
      this._setState(
        {
          schema,
          tree: tree,
          filtered: tree,
          datasourceType: response.data.type,
          datasourceName: response.data.name,
          datasourceId: params.id,
          datasources: datasources,
          datasource: response.data.datasource,
          publicCredentials: existingDbAttributes,
          hasAdminPrivileges: response.data.has_admin_privileges,
          highlightTables: highlightTables
            ? JSON.parse(highlightTables)
            : undefined,
        },

        () => this.setLoading(false)
      );
    } catch (error) {
      this._setState({ errorMsg: error.message, loading: false });
      message.error(error.message);
    }
  };

  _comingSoon = () => {
    message.warning("Feature coming soon");
  };

  _onPushManageTeamTab = () => {
    const key = uuidv4();
    this.context.events.setAppEvent({
      type: "pushTabEvent",
      tabData: {
        label: "Manage team members ",
        type: "team_management",
        children: (
          <ErrorBoundary id="manage_team_tab">
            <TeamManagement tabId={key} />
          </ErrorBoundary>
        ),
        key: key,
      },
      tabKey: key,
      tabName: "Manage team members ",
    });
  };

  _onPushQuestionTab = () => {
    const key = uuidv4();
    this.context.events.setAppEvent({
      type: "pushTabEvent",
      tabData: {
        label: "New Question ",
        type: "question",
        children: (
          <ErrorBoundary id="question_tab">
            <Question tabId={key} />
          </ErrorBoundary>
        ),
        key: key,
      },
      tabKey: key,
      tabName: "New Question ",
    });
  };

  _onPushAppsTab = () => {
    const key = uuidv4();
    this.context.events.setAppEvent({
      type: "pushTabEvent",
      tabData: {
        label: "Manage apps ",
        type: "apps",
        children: (
          <ErrorBoundary id="workflow_tab">
            <ManageApps tabId={key} />
          </ErrorBoundary>
        ),
        key: key,
      },
      tabKey: key,
      tabName: "Workflows",
    });
  };

  _onPushWorkflowTab = () => {
    const key = uuidv4();
    this.context.events.setAppEvent({
      type: "pushTabEvent",
      tabData: {
        label: "Workflows ",
        type: "workflow",
        children: (
          <ErrorBoundary id="workflow_tab">
            <Workflow tabId={key} />
          </ErrorBoundary>
        ),
        key: key,
      },
      tabKey: key,
      tabName: "Workflows",
    });
  };

  _onPushBillingsTab = () => {
    const key = uuidv4();
    this.context.events.setAppEvent({
      type: "pushTabEvent",
      tabData: {
        label: "Manage billing ",
        type: "billing",
        children: (
          <ErrorBoundary id="billing_tab">
            <Billing tabId={key} />
          </ErrorBoundary>
        ),
        key: key,
      },
      tabKey: key,
      tabName: "Manage billing",
    });
  };

  _onExpandTree = (expanded, event) => {
    const currentLeafs = this.state.expandedLeafs;
    if (event.expanded) {
      this.setState({
        ...this.state,
        expandedLeafs: [
          ...currentLeafs,
          { leaf: event.node.key, children: event.node.children },
        ],
      });
    } else {
      const removeCollapsed = currentLeafs.filter((leaf) => {
        return leaf.leaf !== event.node.key;
      });
      this.setState({
        ...this.state,
        expandedLeafs: [...removeCollapsed],
      });
    }
  };

  _onOpenTable = (tableName) => {
    const tabKey = uuidv4();
    this.context.events.setAppEvent({
      type: "pushTabEvent",
      tabData: {
        key: tabKey,
        label: `${tableName} table`,
        type: `${tableName}-table`,
        children: (
          <ErrorBoundary id="backend_tab">
            <Backend tableName={tableName} tabId={tabKey} />,
          </ErrorBoundary>
        ),
      },
      tabKey,
    });
  };

  _onSearch = (value) => {
    const filtered = [...this.state.tree].filter((tr) =>
      tr.title.toLowerCase().includes(value.toLowerCase())
    );
    this.setState({ ...this.state, filtered: filtered, searchInput: value });
  };

  _cancelSearch = () => {
    this.setState({ ...this.state, searchInput: "" });
    this._onSearch("");
  };

  render() {
    const { isShwTabl, toggleShwTable } = this.props;

    const {
      errorMsg,
      loading,
      datasources,
      schema,
      datasourceName,
      datasourceType,
    } = this.state;

    const isMysqlSchema = datasourceType?.toLowerCase() === "mysql";
    const isReady = datasourceName != undefined;

    return (
      <ErrorBoundary id="datasource_side_nav">
        <div
          className={`pb-6 text-black ${
            isReady ? "bg-white" : ""
          }  "w-full"  border-r-[1.5px] border-gray-200  h-full transition-all duration-200 `}
        >
          {this.state.hasAdminPrivileges && (
            <RefreshExistingDBModal
              hasAdminPrivileges={this.state.hasAdminPrivileges}
              datasource={this.state.datasource}
              toggleModal={this.toggleRefreshDBModal}
              openModal={this.state.openRefreshDbModal}
              isTablesNotSetup={this.state.isDbTablesNotSetup}
              publicCredentials={this.state.publicCredentials}
              schema={this.state.schema}
              highlighted={this.state.highlightTables}
            />
          )}

          <ConnectNewDBModal
            datasource={this.state.pendingDataSource}
            togglePendingModal={this.toggleConnectDBStatus}
            openPendingModal={this.state.openConnectDbModal}
            isTablesNotSetup={this.state.isDbTablesNotSetup}
            isCloseable={this.state.isCloseable}
            schema={this.state.schema}
          />

          {errorMsg && !loading && (
            <NavError loadDataSourceSchema={this._loadDataSourceSchema} />
          )}

          {loading && (
            <div className="h-full flex items-center justify-center">
              <Spin className="text-black" />
            </div>
          )}

          {!errorMsg && isReady && (
            <div className="h-full ">
              <div className="flex gap-x-1 w-full items-start h-full relative">
                <NavMenuItems
                  hasAdminPrivileges={this.state.hasAdminPrivileges}
                  db_product={this.state.db_product}
                  datasource={this.state.datasource}
                  isDbTablesNotSetup={this.state.isDbTablesNotSetup}
                  publicCredentials={this.state.publicCredentials}
                  toggleRefreshDBModal={this.toggleRefreshDBModal}
                  toggleConnectDBStatus={this.toggleConnectDBStatus}
                  onPushWorkflowTab={this._onPushWorkflowTab}
                  onPushAppsTab={this._onPushAppsTab}
                  onPushQuestionTab={this._onPushQuestionTab}
                  onPushManageBillingTab={this._onPushBillingsTab}
                  onPushManageTeamTab={this._onPushManageTeamTab}
                  isShwTabl={this.state.isShwTabl}
                  toggleShwTable={toggleShwTable}
                />

                {isShwTabl && (
                  <DBSchema
                    hasAdminPrivileges={this.state.hasAdminPrivileges}
                    datasourceType={datasourceType}
                    datasources={datasources}
                    datasourceName={datasourceName}
                    searchInput={this.state.searchInput}
                    cancelSearch={this._cancelSearch}
                    onSearch={this._onSearch}
                    tablesLength={this.state.tree.length}
                    onExpand={this._onExpandTree}
                    onOpenTable={this._onOpenTable}
                    tree={this.state.filtered ?? []}
                    loading={this.state.loading}
                    isMysqlSchema={isMysqlSchema}
                    isShwTabl={this.state.isShwTabl}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </ErrorBoundary>
    );
  }
}

DataSourceSideNav.contextType = ContextServiceContext;
export default DataSourceSideNav;
