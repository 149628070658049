import { Cascader, Drawer, Input, Select, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Str } from "../../../../../utils/constants";
import { useContext, useEffect, useState } from "react";
import { ContextServiceContext } from "../../../../../context/schema_context";
import { NodesServiceContext } from "../../../../../context/nodes_context";
import { getPrimaryFieldCol, isColListOmmited } from "../../../../../utils/schema_utils";
import { MButton } from "../../../../Common/buttons";

const { SHOW_CHILD } = Cascader;


const CommandDataDrawer = ({ dataDrawer, onCompleteCommandData, orderedCommands }) => {
  const contextService = useContext(ContextServiceContext);
  const { databaseSchema = {} } = contextService.schema;

  // used by all commands
  const [commandName, setCommandName] = useState('');
  // used by all commands


  // used by webhook command
  const [webhookUrl, setWebhookUrl] = useState();
  const [webHookData, setWebHookData] = useState([]);
  // used by webhook command

  // used by update insert delete command
  const [selectedTable, setSelectedTable] = useState();
  const [options, setOptions] = useState([]);
  const [selectedTableColumn, setSelectedTableColumn] = useState();
  // used by update insert delete command

  // used by update insert command
  const [pairs, setPair] = useState([]);
  // used by update insert command

  //used by update and delete command
  const [filters, setFilters] = useState([]);
  const [currentFilterColummn, setCurrentFilterColumn] = useState(undefined);
  //used by update and delete command

  const nodesContext = useContext(NodesServiceContext);

  const { tables = [], columns } = databaseSchema;
  const { command, commandIndex } = dataDrawer;

  useEffect(() => {
    console.log();
    const allOptions = []
    // Setup form values available for input
    const nodes = nodesContext.filter((nd) => nd.type !== Str.nodeTypes.submit.type && nd.type !== Str.nodeTypes.title.type);
    const formOptions = nodes.map((node) => {
      return {
        label: node.data?.label,
        value: node.data?.label,
      };
    });
    if (formOptions?.length > 0) {
      allOptions.push({
        value: "form",
        label: "form",
        children: formOptions,
      })
    }

    // Setup form values available for input

    // Setup artifact values available for input
    console.log(orderedCommands);
    for (const index in orderedCommands) {
      const getCommand = orderedCommands[index]
      const { data: commandData = {} } = getCommand
      const { commandName = '', artifact = [] } = commandData
      console.log(commandData, 'jbnjmk,lhjnm,');
      if (artifact?.length > 0) {
        allOptions.push({
          value: commandName,
          label: commandName,
          children: artifact,
        })
      }

    }
    // Setup artifactValues valuesavailable for input

    setOptions(allOptions)
  }, []);

  useEffect(() => {
    if (selectedTable) {
      setPair([]);
      const tablecols = columns[selectedTable];
      setSelectedTableColumn(tablecols);
    }
  }, [columns, selectedTable]);

  const onWebhookDataSelected = (cascaderData) => {
    const data = cascaderData.map((dt) => {
      return { [dt[0]]: dt[1] };
    });
    setWebHookData(data);
  };

  const onSelectPair = (value, col) => {
    console.log([...pairs, { [value[1]]: col.Field }]);
    setPair([
      ...pairs,
      { type: value[0], type_label: value[1], field: col.Field },    ]);
  };

  const onAddCommand = () => {
    if (isValidated()) {
      onCompleteCommandData(command, commandIndex, {
        table: selectedTable,
        pairs: pairs,
        isComplete: true,
        filter: filters,
        commandName,
        artifact: generateCommandArtifact(selectedTable, command),
        webhook: { url: webhookUrl, data: webHookData },
      });
    }
  };


  const generateCommandArtifact = (commandTable, commandType) => {
    if (commandType === Str.submitCommandTypes.webhook.key) {
      return []
    }

    const { columns } = databaseSchema
    const tableCols = columns[commandTable]
    const allColArtifacts = tableCols.map((col) => {
      return {
        value: col.Field,
        label: col.Field,
      }
    })
    const primaryKeyField = getPrimaryFieldCol(databaseSchema, commandTable);
    let primaryKeyOnlyArtifacts = [];
    if (primaryKeyField) {
      primaryKeyOnlyArtifacts = [
        {
          value: primaryKeyField.Field,
          label: primaryKeyField.Field,
        }
      ]
    }


    switch (commandType) {
      case Str.submitCommandTypes.insert.key:
        return allColArtifacts
      case Str.submitCommandTypes.update.key:
        return primaryKeyOnlyArtifacts
      case Str.submitCommandTypes.delete.key:
        return primaryKeyOnlyArtifacts
      default:
        break;
    }
  }

  const isValidated = () => {

    // validate command name  
    if (!commandName || commandName.length < 1) {
      message.warning(`Add command name to continue`);
      return false;
    }


    // validate webhook  only
    if (command === Str.submitCommandTypes.webhook.key) {
      if (!webhookUrl || webhookUrl.length < 1) {
        message.warning(`Add webhook URL to continue`);
        return false;
      }
    }

    // validate update and delete only
    if (
      command === Str.submitCommandTypes.update.key ||
      command === Str.submitCommandTypes.delete.key
    ) {
      if (filters.length < 1) {
        message.warning(`${command} command needs at least one filter`);
        return false;
      }
    }

    // validate update and insert only
    if (
      command === Str.submitCommandTypes.update.key ||
      command === Str.submitCommandTypes.insert.key
    ) {
      if (
        !selectedTable ||
        !selectedTableColumn ||
        !Array.isArray(selectedTableColumn)
      ) {
        message.warning(`Select a table to continue`);
        return false;
      }

      for (const index in selectedTableColumn) {
        const col = selectedTableColumn[index];
        if (
          col.Null !== "YES" &&
          !isColListOmmited(col) &&
          pairs.findIndex((p) => p.field === col.Field) === -1
        ) {
          message.warning(`Value for ${col.Field} is required`);
          return false;
        }
      }
    }

    // validate webhook only
    return true;
  };

  const onCancelCommandDrawer = () => {
    onCompleteCommandData(command, commandIndex, {
      isComplete: false,
    });
  };

  const onChangeFilterColumn = (column) => {
    setCurrentFilterColumn(column);
    setFilters([]);
  };

  const onChangeFilterValue = (value) => {
    const currentFilters = [...filters].filter(
      (fil) => fil.name !== currentFilterColummn
    );
    console.log([...currentFilters, { [currentFilterColummn]: value }]);
    setFilters([...currentFilters, { [currentFilterColummn]: value }]);
  };

  return (
    <Drawer
      title={
        <div className="justify-between flex items-center pt-1">
          <span className="text-sm text-gray-600">
            {Str.submitCommandTypes[command]?.label}
          </span>
        </div>
      }
      closeIcon={<CloseCircleOutlined />}
      placement="right"
      onClose={() => onCancelCommandDrawer(false)}
      destroyOnClose={true}
      closable={true}
      maskClosable={false}
      open={dataDrawer.isOpen}
    >
      <div className="mb-10 border-b">
        <div className="text-gray-500 text-xs">
          Command name
        </div>
        <Input
          name={"command_name"}
          onChange={(val) => setCommandName(val.target.value)}
          placeholder="Name to identify this command"
          className={`h-10 text-xs text-gray-800  bg-white border-gray-200 border my-3`}
        />
      </div>
      {command === Str.submitCommandTypes.webhook.key && (
        <div className="flex flex-col gap-y-4">
          <div className="">
            <div className="text-gray-500">
              Enter webhook url to send data to
            </div>
            <Input.TextArea
              onChange={(val) => setWebhookUrl(val.target.value)}
              placeholder="https://example.com/endpoint"
              className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
            />
          </div>

          <div className="">
            <div className="text-gray-500">
              Select data to send to the webhook
            </div>
            <Cascader
              style={{ width: "100%" }}
              options={options}
              onChange={(selected) => onWebhookDataSelected(selected)}
              multiple
              maxTagCount="responsive"
              className="h-10 text-gray-800 font-bold bg-white  my-3"
              showCheckedStrategy={SHOW_CHILD}
            />
          </div>
        </div>
      )}

      {/* is update, delete or insert command */}
      {(command === Str.submitCommandTypes.insert.key ||
        command === Str.submitCommandTypes.update.key ||
        command === Str.submitCommandTypes.delete.key) && (
          <div>
            <div className="text-gray-500">
              Select table to perform {command} on{" "}
            </div>
            <Select
              className="w-full my-4"
              placeholder={"Select table"}
              value={selectedTable}
              onChange={(input) => setSelectedTable(input)}
              options={[
                ...tables.map((table) => {
                  return { value: table, label: table };
                }),
              ]}
            ></Select>
          </div>
        )}

      {/* is delete or update command */}
      {selectedTableColumn &&
        (command === Str.submitCommandTypes.delete.key ||
          command === Str.submitCommandTypes.update.key) && (
          <div className="my-7">
            <div className="my-4 text-gray-500">Filter row to {command}</div>

            <div className="flex gap-x-3">
              <div className="w-7/12">
                <span className="text-xs text-gray-500">
                  Select filter column
                </span>
                <Select
                  className="w-full"
                  onChange={onChangeFilterColumn}
                  placeholder={"Select column"}
                  options={[...selectedTableColumn].map((col) => {
                    return { value: col.Field, label: col.Field };
                  })}
                ></Select>
              </div>

              <div className="mx-4">
                <span className="text-xs text-gray-500">
                  Select filter value
                </span>
                <Cascader
                  disabled={!currentFilterColummn}
                  onChange={(value) => onChangeFilterValue({type_label: value[0], field: value[1]})}
                  className="w-full"
                  options={options}
                  placeholder="Please select"
                />
              </div>
            </div>
          </div>
        )}

      {/* is insert or update command */}
      {selectedTableColumn &&
        (command === Str.submitCommandTypes.insert.key ||
          command === Str.submitCommandTypes.update.key) && (
          <div>
            <div className="my-4 text-gray-500">Pair columns and values</div>
            {selectedTableColumn.map((col) => {
              if (isColListOmmited(col)) {
                return <span></span>;
              }
              return (
                <div className="flex w-full gap-x-4 my-2">
                  <Cascader
                    onChange={(value) => onSelectPair(value, col)}
                    options={options}
                    placeholder="Please select"
                  />
                  <span className="border rounded w-6/12 block p-1">
                    {col.Field}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      <div className="absolute w-full bottom-1 left-0 right-0">
        <div className="w-full mx-4 flex justify-center">
          <MButton
            label={" Add command"}
            className={"my-6 w-10/12 mx-auto"}
            onClick={onAddCommand}
          ></MButton>
        </div>
      </div>
    </Drawer>
  );
};

export default CommandDataDrawer;
