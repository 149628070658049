import { useLocation, useParams } from "react-router-dom";
import Tab from "../components/tab/tab";
import { makeClientSideApiRequest, useWindowSize } from "../utils";
import React, { useEffect, useState } from "react";
import { ContextServiceContext } from "../context/schema_context";
import { ContextService } from "../utils/contextservice/ContextService";
import { Helmet } from "react-helmet";
import DataSourceSideNav from "../components/Datasource/SideNav.js/DatasSourceSideNav";
import { Spin, message } from "antd";
import { LinkOutlined } from '@ant-design/icons';
import moment from "moment";

function Datasource() {
  const router = useLocation();
  const params = useParams();
  const size = useWindowSize();
  const [databaseSchema, setDatabaseSchema] = useState(undefined);
  const [isShwTabl, setIsShwTabl] = useState(true);
  const [tabs, setTabs] = useState([]);
  const [appEvent, setAppEvent] = useState(undefined);
  const [activeTab, setActiveTab] = useState(undefined);

  const [loadingUser, setLoadingUser] = useState(true);
  const [user, setUser] = useState(undefined);

  const contextService = new ContextService(
    {
      databaseSchema,
      setDatabaseSchema,
    },
    { tabs, setTabs },
    { activeTab, setActiveTab },
    { appEvent, setAppEvent },
    user
  );

  document.onmouseover = function () {
    //User's mouse is inside the page.
    window.innerDocClick = true;
  };

  document.onmouseleave = function () {
    //User's mouse has left the page.
    window.innerDocClick = false;
  };

  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function (e) {
    e.preventDefault();
    // message.info('Are you sure you want to leave this page?')
    window.history.go(1);
  };

  const _toggleShwTable = () => {
    setIsShwTabl(!isShwTabl);
  };

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    try {
      setLoadingUser(true);
      const response = await makeClientSideApiRequest("/api/user");
      if (response && response.status === "success" && response.data != null) {
        setLoadingUser(false);
        setUser(response.data);
      }
    } catch (error) {
      setLoadingUser(false);
      message.error(error.message);
    }
  };

  const asideWidthClass = `${isShwTabl ? "w-[300px]" : "w-[46px]"}`;
  const mainWidthOffset = isShwTabl ? 300 : 46;
  const { billingActive, gracePeriodTill, billingGracePeriod, billingCurrentPlan } = user || {};
  const requiresPayment = user && (!billingActive || billingGracePeriod)
  const paymentLink = `${billingCurrentPlan?.stripe_payment_link}?prefilled_email=${user?.email}`
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          SequelBase | {` Datasource - ${databaseSchema?.datasourceName} `}
        </title>
        <meta name="description" content="Sequelbase" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        <link rel="icon" href="/database_logo_smaller.png" />
        <link rel="canonical" href="https://app.sequelbase.com" />
      </Helmet>
      {!user && loadingUser && (
        <div className="h-screen flex justify-center items-center">
          {" "}
          <Spin spinning={loadingUser} />{" "}
        </div>
      )}
      {user && (
        <>
          {requiresPayment &&
            <section onClick={() => window.open(paymentLink, '__blank')} className="h-10 bg-orange-500 hover:bg-orange-300 flex justify-center items-center cursor-pointer">
              {billingGracePeriod && <span className="text-white text-sm flex items-center gap-x-2"> Your payment is overdue, please make payment before <span className="font-bold"> {moment(gracePeriodTill).format('DD MMMM YYYY   ')}. </span>  <a href={paymentLink} target="__blank" className="underline flex items-center gap-x-1 cursor-pointer"> Click here to pay  <LinkOutlined />  </a> </span>}

              {!billingGracePeriod && <span className="text-white text-sm  flex items-center gap-x-2"> Your payment is overdue, please make payment to continue using SequelBase  <a href={paymentLink} target="__blank" className="underline flex items-center gap-x-1 cursor-pointer"> Click here to pay  <LinkOutlined />  </a></span>}
            </section>
          }
          <div className="w-full flex" style={{ height: `${size.height}px` }}>

            <section className={asideWidthClass}>
              <ContextServiceContext.Provider value={contextService}>
                <DataSourceSideNav
                  toggleShwTable={_toggleShwTable}
                  isShwTabl={isShwTabl}
                  router={router}
                  params={params}
                />
              </ContextServiceContext.Provider>
            </section>
            <section style={{ width: `${size.width - mainWidthOffset}px` }}>
              <ContextServiceContext.Provider value={contextService}>
                <Tab />
              </ContextServiceContext.Provider>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default Datasource;
