import { displayAsOptions } from "../../utils";
import PropTypes from "prop-types";
import { Button, Dropdown, Input, Modal, Tooltip, message } from "antd";
import { CloseCircleFilled, SearchOutlined } from "@ant-design/icons";
import React from "react";
import { presentationTypes } from ".";
import { QuestionConfigContext } from "../../context/question_config_context";
import { ContextServiceContext } from "../../context/schema_context";
import { Str } from "../../utils/constants";

class RecordHighlight extends React.Component {
  static propTypes = {
    onFilterRow: PropTypes.func,
    onTogglePresentation: PropTypes.func,
    onSearchTable: PropTypes.func,
    loading: PropTypes.func.isRequired,
    isSavedApp: PropTypes.bool,
    savingQuestion: PropTypes.bool.isRequired,
    onShowHistory: PropTypes.func.isRequired,
    tableName: PropTypes.string,
  };
  searchTimeout;

  state = {
    presentationType: presentationTypes.TABLE,
    searchInput: "",
  };

  onSearch = (value) => {
    // debounce input for 500 millisecond
    this.setState({ ...this.state, searchInput: value });
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.props.onSearchTable(value);
    }, 1000);
  };

  cancelSearch = () => {
    this.setState({ ...this.state, searchInput: "" });
    this.onSearch("");
  };

  render() {
    const {
      total,
      timeTaken,
      tableName,
      onAddRow,
      onShowHistory,
      onRefresh,
      noData,
      errMsg,
      onFilterRow,
    } = this.props;

    console.log(this.props.saveQuestion);
    const { presentationType } = this.state;
    const displayedAs = displayAsOptions.find(
      (option) => option.type == presentationType
    );

    const { user = {} } = this.context;
    const { backend_permissions = [], has_admin_privileges } = user ?? {};
    const tablePermission = backend_permissions[this.props.tableName];
    let isCreatePermission = false;

    if (
      has_admin_privileges ||
      (tablePermission &&
        tablePermission.includes(Str.backendPermissionOpts.write))
    ) {
      isCreatePermission = true;
    }

    if (noData || errMsg) {
      return <div></div>;
    }
    return (
      <>
        <div className=" pt-2 pr-3 relative  w-full flex flex-col justify-between">
          <div className="justify-between flex items-center">
            <div className="text-black flex gap-x-3 p-5">
              <HighlighItem
                tooltip={"Total records returned"}
                icon={"/row.png"}
                label={` ${total} Records returned`}
              />
              <HighlighItem
                tooltip="Time taken to run query"
                icon={"/clock.png"}
                label={` ${parseFloat(timeTaken).toFixed(2)} Seconds`}
              />
              <HighlighItem
                onClick={onRefresh}
                tooltip={`Clear filters & Refresh ${tableName}`}
                icon={"/refresh.png"}
                label={`Refresh`}
              />
            </div>

            <div className="w-5/12 relative">
              <div className="absolute left-3 top-2">
                <SearchOutlined />
              </div>

              <input
                autoFocus={true}
                onChange={(event) => this.onSearch(event.target.value)}
                value={this.state.searchInput}
                type="text"
                placeholder={`type to search entire ${tableName} table ..`}
                className="text-md pt-2 outline-none text-black focus:ring-0 h-8 rounded-full  bg-white p-2 border border-[#ADB5BD] w-full px-8"
              />
              <div
                onClick={this.cancelSearch}
                className="absolute right-4 top-2"
              >
                <CloseCircleFilled />
              </div>
            </div>
          </div>

          <div className="text-black flex gap-x-6 px-5">
            {isCreatePermission && (
              <HighlighItem
                onClick={onAddRow}
                tooltip={`Add new row to ${tableName}`}
                icon={"/plus.png"}
                label={`Add new row`}
              />
            )}
            <HighlighItem
              onClick={onShowHistory}
              tooltip={`Show ${tableName} query logs`}
              icon={"/restore.png"}
              label={`Operation logs`}
            />
            <HighlighItem
              onClick={onFilterRow}
              tooltip={`Filter table rows`}
              icon={"/filter.png"}
              label={`Filter rows`}
            />
            <HighlighItem
              onClick={() => message.warning("Coming soon!")}
              tooltip={`Perform Bulk actions on selected rows.`}
              icon={"/bulk.png"}
              label={`Bulk actions`}
            />
          </div>
        </div>
      </>
    );
  }
}

RecordHighlight.contextType = ContextServiceContext;
export default RecordHighlight;

const HighlighItem = ({ label, icon, tooltip, onClick }) => {
  return (
    <Tooltip title={tooltip}>
      <a
        href="#"
        onClick={onClick}
        className="text-gray-700 text-xs flex gap-x-2 items-center"
      >
        <img className="w-4" src={icon} width={100} height={40} />
        {label}
      </a>
    </Tooltip>
  );
};
