import { Select } from "antd";
import { Str } from "../../../../utils/constants";

const InputActions = ({ values, onChangeInput }) => {
  const existingValues = values ? values : {};
  return (
    <div className="my-3 bg-white rounded-lg border p-2 text-sm shadow-sm">
      <span className="text-gray-500 block my-1 text-xs">
        {" "}
        Select input type
      </span>

      <p className="text-gray-900">
        <Select
          required={true}
          value={
            existingValues[Str.nodeTypes.textinput.attributes.textinputtype.key]
          }
          defaultValue={
            existingValues[Str.nodeTypes.textinput.attributes.textinputtype.key]
          }
          options={[
            { value: "text", label: "Text" },
            { value: "email", label: "Email" },
            { value: "number", label: "Number" },
            { value: "password", label: "Password" },
            { value: "tel", label: "Phone" },
          ]}
          onChange={(input) =>
            onChangeInput(
              Str.nodeTypes.textinput.attributes.textinputtype.key,
              input
            )
          }
          className={`  h-10  w-full`}
          placeholder={` Select input type `}
        />
      </p>
    </div>
  );
};

export default InputActions;
