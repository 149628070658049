import { Modal } from "antd";
import CommonTable from "../Common/common_table";

const LogDetailsModal = ({ openLogModal, onToggleLogModal, log }) => {
  let affectedRows = [];
  console.log(log?.query, 'opned logs');
  try {
    affectedRows = JSON.parse(log?.rows_affected ?? "{}");
  } catch (error) {
    console.log(error);
  }

  return (
    <Modal
      open={openLogModal}
      onCancel={() => onToggleLogModal(false)}
      footer={null}
      title={"Operation details"}
    >
      <div className="p-3 flex flex-col mx-auto gap-y-2">
        {affectedRows?.length > 0 && (
          <div>
            <span>Affected rows </span>
            <CommonTable data={affectedRows} loading={false} />
          </div>
        )}

        <div>
          <span>Operation query</span>
          <div className="bg-gray-200 rounded-md my-4 p-2">
            <span className="text-gray-700 text-xs"> {log?.query}</span>
          </div>
        </div>

        <div>
          <span>Operation data</span>
          <div className="bg-gray-200 rounded-md my-4 p-2">
            <span className="text-gray-700 text-xs"> {log?.query_data}</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LogDetailsModal;
