import { WarningOutlined } from "@ant-design/icons";
import { MButton } from "../../Common/buttons";
import { ColInputField } from "../../Common/widgets/ColInputField";

const RowData = ({
  selectedRow,
  isCreateRow,
  primaryKeyField,
  getTableColumnsFromSchema,
  table,
  fieldInputs,
  onDataInput,
  userAction,
  sendingCommand,
  sendCommand,
  isRequiredColumn,
}) => {
  return (
    <>
      {!isCreateRow && selectedRow && !primaryKeyField && (
        <div className="text-xs text-gray-400">
          <WarningOutlined className="text-xs" />
          <span>
            {" "}
            Table rows without primary key cannot be updated or deleted. Add
            primary key to enable delete and update actions
          </span>
        </div>
      )}
      {isCreateRow && (
        <div className="px-2 my-2 pb-7">
          <div className="flex justify-between gap-x-2 items-center"></div>
          <div className="p-1 h-10/12">
            {getTableColumnsFromSchema(table).map((column) => {
              if (column.Field === primaryKeyField) return <span></span>;
              return (
                <div className="w-full">
                  <div className="my-3 bg-white rounded-lg border p-2 text-sm shadow-sm">
                    <span className="text-gray-500 block my-1 text-xs">
                      {" "}
                      {column.Field}
                    </span>

                    <p className="text-gray-900">
                      <ColInputField
                        width="w-full"
                        required={column?.Null?.toLowerCase() !== "yes"}
                        colType={column}
                        onChange={(input) => onDataInput(column.Field, input)}
                        value={
                          fieldInputs.find((fi) => fi.field === column.Field)
                            ?.data
                        }
                        placeholder={`Enter ${column.Field}`}
                      />
                      {/*      <Input
                        required={column.Null.toLowerCase() !== "yes"}
                        onChange={(input) => onDataInput(column.Field, input)}
                        className={`border  h-10 ${
                          column.Null.toLowerCase() !== "yes"
                            ? "border-blue-400"
                            : "border-gray-400"
                        }`}
                        placeholder={`Enter ${column.Field}`}
                      /> */}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-4 pb-0 fixed bottom-2  right-8 mx-4 w-72">
            <MButton
              onClick={() => sendCommand("insert", fieldInputs)}
              loading={sendingCommand}
              type="primary"
              className="w-full bg-blue-700 h-10"
              label={"Create row"}
            ></MButton>
          </div>
        </div>
      )}

      {!isCreateRow && selectedRow && (
        <div className="p-1 h-10/12 pb-7">
          {Object.keys(selectedRow).map((field) => {
            if (field === primaryKeyField && userAction === "update")
              return <span></span>;
            const cols = getTableColumnsFromSchema(table);
            const col = cols.find((c) => c.Field === field);
            console.log(col);
            return (
              <div className="">
                <div className="my-4 bg-white rounded-lg p-2 text-sm shadow-sm border">
                  <span className="text-gray-500 block my-1 text-xs">
                    {" "}
                    {field}
                  </span>
                  {userAction != "update" && (
                    <p className="text-gray-900 break-words">
                      {selectedRow[field] || "...."}
                    </p>
                  )}
                  {userAction == "update" && (
                    <p className="text-gray-900">
                      <ColInputField
                        width="w-full"
                        required={isRequiredColumn(field)}
                        colType={col}
                        onChange={(input) => onDataInput(col.Field, input)}
                        value={
                          fieldInputs.find((input) => input.field == field)
                            ?.data
                        }
                        placeholder={`Enter ${col.Field}`}
                      />

                      {/*               <Input
                        onChange={(input) => onDataInput(field, input)}
                        className={`border  h-10 ${
                          isRequiredColumn(field)
                            ? "border-blue-400"
                            : "border-gray-400"
                        }`}
                        placeholder={`Enter ${field}`}
                        value={
                          fieldInputs.find((input) => input.field == field)
                            ?.data
                        }
                      /> */}
                    </p>
                  )}
                </div>
              </div>
            );
          })}

          {userAction == "delete" && (
            <div className="mt-4 pb-0 fixed bottom-0  right-8 mx-4 w-72">
              <div className="flex mb-2">
                <MButton
                  onClick={() => sendCommand("delete", fieldInputs)}
                  loading={sendingCommand}
                  disabled={sendingCommand}
                  type="primary"
                  label={"Delete row"}
                  className="w-full bg-red-700 h-10"
                ></MButton>
              </div>
            </div>
          )}

          {userAction == "update" && (
            <div className="mt-4 pb-0 fixed bottom-0  right-8 mx-4 w-72">
              <div className="flex mb-2">
                <MButton
                  onClick={() => sendCommand("update", fieldInputs)}
                  loading={sendingCommand}
                  disabled={sendingCommand}
                  type="primary"
                  label={"Update row"}
                  className="w-full bg-blue-700 h-10"
                ></MButton>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RowData;
