import { Drawer } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const RowInfo = ({ selectedRow, onToggleDataDrawer, openDataDrawer }) => {
  return (
    <Drawer
      title={
        <div className="justify-between flex items-center pt-1">
          <span className="text-sm text-gray-600">Row detail</span>
        </div>
      }
      closeIcon={<CloseCircleOutlined />}
      placement="right"
      onClose={() => onToggleDataDrawer(false)}
      open={openDataDrawer}
    >
      <div className="p-1 h-10/12">
        {Object.keys(selectedRow).map((field) => {
          /*  if (field == "id") return <span></span>; */
          return (
            <div className="">
              <div className="my-3 bg-white rounded-lg p-2 text-sm shadow">
                <span className="text-gray-500 block my-1 text-xs">
                  {" "}
                  {field}
                </span>

                <p className="text-gray-900 break-words">
                  {selectedRow[field] || "...."}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </Drawer>
  );
};

export default RowInfo;
