import axios from "axios";
export { default as useWindowSize } from "./use_window_size";

export const baseRoute = process.env.BASE_ROUTE || "https://api.sequelbase.com";
export const formatMoney = (amount, currency) => {
  if (currency == "gbp") {
    return gbpMoneyFormatter.format(amount);
  } else if (currency == "ngn") {
    return ngnMoneyFormatter.format(amount);
  } else if (currency == "usd") {
    return usdMoneyFormatter.format(amount);
  } else if (currency == "eur") {
    return eurMoneyFormatter.format(amount);
  }
};

export const formatNum = (number) =>
  new Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 3,
  }).format(number);

export const formatPairTitle = (pair) => {
  if (pair == "ngn_gbp") {
    return "Naira → Pounds";
  } else if (pair == "gbp_ngn") {
    return "Pounds → Naira";
  } else if (pair == "ngn_eur") {
    return "Naira → Euro";
  } else if (pair == "eur_ngn") {
    return "Euro → Naira";
  }
};

export const formatWalletTitle = (currency) => {
  if (currency == "gbp") {
    return "GBP balance";
  } else if (currency == "ngn") {
    return "Naira balance";
  } else if (currency == "usd") {
    return "USD balance";
  } else if (currency == "eur") {
    return "Euro balance";
  }
};

export const formatIcon = (currency) => {
  if (currency == "credit") {
    return "/in_trx.png";
  } else if (currency == "debit") {
    return "/out_trx.png";
  }
};

export const whatsappSupport =
  "https://api.whatsapp.com/send/?phone=447823564439&text&type=phone_number&app_absent=0";
export const formatCurrencyIcon = (currency) => {
  if (currency == "gbp") {
    return "/gbp.png";
  } else if (currency == "ngn") {
    return "/naira.png";
  } else if (currency == "usd") {
    return "/usa.png";
  } else if (currency == "eur") {
    return "/euro.png";
  }
};

export async function makeServerSideApiRequest(authToken, path) {
  let dataResponse = null;
  let errorResponse = null;

  try {
    const response = await axios.get(`${baseRoute}${path}`, {
      headers: {
        //   "Accept-Encoding": "gzip,deflate,compress",
        "x-client": "lemonDAO",
        Accept: "application/json",
        Authorization: `Bearer ${decodeURIComponent(authToken)}`,
      },
    });
    dataResponse = response.data;

    if (dataResponse.status || dataResponse.status === "success") {
      return dataResponse;
    } else {
      return undefined;
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location = "/login";
    }
    return undefined;
  }
}

export async function makeClientSideApiRequest(url) {
  let dataResponse = null;
  let errorResponse = null;

  try {
    const response = await axios.get(`${baseRoute}${url}`, {
      headers: {
        //   "Accept-Encoding": "gzip,deflate,compress",
        "x-client": "lemonDAO",
        Accept: "application/json",
        // Authorization: "Bearer 55|aLiay0zCgMDWSifecERCEeO2Nu5Jakq5VN8dxfL6",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    dataResponse = response.data;

    if (dataResponse.status || dataResponse.status === "success") {
      return dataResponse;
    } else {
      throw new Error("Unable to contact the server --");
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      window.location = "/login";
    } else {
      throw new Error("Unable to contact the server -- " + error.message);
    }
  }
}

export async function makeApiRequest(context, url) {
  let dataResponse = null;
  let errorResponse = null;

  try {
    const response = await axios.get(`${baseRoute}${url}`, {
      headers: {
        "x-client": "lemonDAO",
        "Accept-Encoding": "gzip,deflate,compress",
        Accept: "application/json",
      },
    });
    dataResponse = response.data;

    if (dataResponse.status || dataResponse.status === "success") {
      return {
        props: {
          responseData: dataResponse,
          errorResponse: errorResponse,
        },
        revalidate: 1,
      };
    } else {
      throw new Error("Unable to contact the server --");
    }
  } catch (error) {
    throw new Error("Unable to contact the server -- " + error.message);
  }
}

export async function makeDeleteRequest(url, data = {}) {
  let dataResponse = null;

  try {
    const response = await axios.delete(`${baseRoute}${url}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: "Bearer 55|aLiay0zCgMDWSifecERCEeO2Nu5Jakq5VN8dxfL6",

        // Authorization: `Bearer ${localStorage.getItem("token")}`,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    dataResponse = response.data;

    if (dataResponse.status || dataResponse.status === "success") {
      return dataResponse;
    } else {
      throw new Error("Unable to contact the server");
    }
  } catch (error) {
    const { response = {} } = error;
    const { data, status } = response;
    const { message } = data;

    switch (status) {
      case 403:
        throw new Error(
          message || "You are not authorized to perform this action"
        );
      default:
        throw new Error(message || "Unknown error occurred");
    }
  }
}

export async function makePutRequest(url, data = {}) {
  let dataResponse = null;

  try {
    const response = await axios.put(`${baseRoute}${url}`, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: "Bearer 55|aLiay0zCgMDWSifecERCEeO2Nu5Jakq5VN8dxfL6",

        // Authorization: `Bearer ${localStorage.getItem("token")}`,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    dataResponse = response.data;

    if (dataResponse.status || dataResponse.status === "success") {
      return dataResponse;
    } else {
      throw new Error("Unable to contact the server");
    }
  } catch (error) {
    const { response = {} } = error;
    const { data, status } = response;
    const { message } = data;

    switch (status) {
      case 403:
        throw new Error(
          message || "You are not authorized to perform this action"
        );
      default:
        throw new Error(message || "Unknown error occurred");
    }
  }
}

export async function makePostRequest(url, data = {}) {
  let dataResponse = null;

  try {
    const response = await axios.post(`${baseRoute}${url}`, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: "Bearer 55|aLiay0zCgMDWSifecERCEeO2Nu5Jakq5VN8dxfL6",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        // Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    dataResponse = response.data;

    if (dataResponse.status || dataResponse.status === "success") {
      return dataResponse;
    } else {
      throw new Error("Unable to contact the server --");
    }
  } catch (error) {
    const { response = {} } = error;
    const { data = {}, status } = response;
    const { message } = data;

    switch (status) {
      case 403:
        throw new Error(
          message || "You are not authorized to perform this action"
        );
      default:
        throw new Error(message || "Unknown error occurred");
    }
  }
}

export const truncate = (input, max = 20) =>
  input.length > 20 ? `${input.substring(0, max - 3)}..` : input;

// Create our number formatter.
export const ngnMoneyFormatter = new Intl.NumberFormat("en-NG", {
  style: "currency",
  currency: "NGN",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const usdMoneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const eurMoneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "EUR",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const gbpMoneyFormatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const rateOperators = {
  ngn_gbp: "/",
  eur_ngn: "*",
  gbp_ngn: "*",
  ngn_gbp: "/",
};

export const destinationOptions = {
  ngn: ["gbp"],
  eur: [],
  gbp: ["ngn"],
  ngn: ["gbp"],
};

export const setUserInfo = (user) => {
  localStorage.setItem("user_info", JSON.stringify(user));
};

export const clearUserInfo = (user) => {
  localStorage.clear();
};

export const getUserInfo = () => {
  try {
    const user = localStorage.getItem("user_info");
    return user ? JSON.parse(user) : undefined;
  } catch (error) {
    return undefined;
  }
};

export const setRecentTrx = (trx) => {
  localStorage.setItem("recent_trx", JSON.stringify(trx));
};

export const getRecentTrx = () => {
  const trx = localStorage.getItem("recent_trx");
  try {
    return trx && trx != undefined ? JSON.parse(trx) : [];
  } catch (error) {
    return [];
  }
};

export const setDefaultWallet = (wallet) => {
  localStorage.setItem("default_wallet", wallet.symbol);
};

export const getDefaultWallet = (wallets) => {
  const symbol = localStorage.getItem("default_wallet");
  return wallets.find((wallet) => wallet.symbol == symbol);
};

export const orderAsOptions = [
  {
    key: "asc",
    type: "asc",
    title: "Order in descending order",
    img: "/analytics.png",
    label: (
      <div className="flex items-center gap-x-2">
        <image className="w-3" src={"/analytics.png"} width={100} height={40} />
        <a className="text-gray-600 text-xs h-8 " href="#">
          Descending order
        </a>
      </div>
    ),
  },
  {
    key: "desc",
    title: "Order in ascending order",
    type: "desc",
    img: "/chart.png",
    label: (
      <div className="flex items-center gap-x-2">
        <image className="w-3" src={"/chart.png"} width={100} height={40} />
        <a className="text-gray-600 text-xs h-8 " href="#">
          Ascending order
        </a>
      </div>
    ),
  },
];

export const displayAsOptions = [
  {
    key: "1",
    type: "table",
    title: "Table",
    img: "/cells.png",
    label: (
      <div className="flex items-center gap-x-2">
        <image className="w-3" src={"/cells.png"} width={100} height={40} />
        <a className="text-gray-600 text-xs h-8 " href="#">
          Table
        </a>
      </div>
    ),
  },
  {
    key: "2",
    type: "piechart",
    title: "Pie chart",
    img: "/analytics.png",
    label: (
      <div className="flex items-center gap-x-2">
        <image className="w-3" src={"/analytics.png"} width={100} height={40} />
        <a className="text-gray-600 text-xs h-8 " href="#">
          Pie chart
        </a>
      </div>
    ),
  },
  {
    key: "3",
    title: "Line chart",
    type: "linechart",
    img: "/chart.png",
    label: (
      <div className="flex items-center gap-x-2">
        <image className="w-3" src={"/chart.png"} width={100} height={40} />
        <a className="text-gray-600 text-xs h-8 " href="#">
          Line chart
        </a>
      </div>
    ),
  },
];

export const getRandomColor = () => {
  return `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
    Math.random() * 256
  )}, ${Math.floor(Math.random() * 256)})`;
};

export const PageLoading = () => {
  return (
    <div className="bg-[#f3f3f1] p-3 h-screen flex justify-center items-center">
      {" "}
      <span className="text-black">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </span>
    </div>
  );
};

export const ComponentLoading = () => {
  return (
    <div className="bg-[#f3f3f1] p-3 h-5 flex justify-center items-center">
      {" "}
      <span className="text-black">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </span>
    </div>
  );
};

export const getInputExtraWidgetObj = (widget) => {
  const { inputType } = widget.widgetConfig;

  switch (inputType) {
    case "select":
      return widget.widgetConfig.inputOptions;

    default:
      return "";
  }
};
