import Pie_chart_presentation from "./pie_chart_presentation";
import Line_chart_presentation from "./line_chart_presentation";
import TablePresentation from "./table_presentation_no_edit";

const PresentData = ({
  question,
  errMsg,
  loading,
  type,
  data = [],
  setUserOperationData,
  questionConfig,
}) => {
  if (errMsg) {
    return (
      <p className="text-gray-700 text-center my-20 mx-auto w-6/12 text-sm">
        {errMsg}
      </p>
    );
  }
  if (data.length < 1) {
    return (
      <div>
        <div
          style={{ backgroundImage: "url(/home_empty.png)" }}
          className="w-full h-80 bg-contain bg-no-repeat bg-center mt-12"
        >
          <p></p>
        </div>
        <div>
          <p className="text-black text-center font-black text-xl">
            No data returned
          </p>

          {!loading && !errMsg && (
            <p className="text-gray-400 text-center my-4 mx-auto w-6/12 text-sm">
              No records returned from your question
            </p>
          )}
        </div>
      </div>
    );
  }

  if (errMsg || !data || data.length < 1) {
    return (
      <div>
        <div
          style={{ backgroundImage: "url(/home_empty.png)" }}
          className="w-full h-80 bg-contain bg-no-repeat bg-center mt-12"
        >
          <p></p>
        </div>
        <div>
          <p className="text-black text-center font-black text-xl">
            {loading ? "Asking Question... " : "Ask a question"}
          </p>

          {!loading && !errMsg && (
            <p className="text-gray-400 text-center my-4 mx-auto w-6/12 text-sm">
              Type in a question using the natural english language to get
              answers from your database. To get the best response, ensure that
              your questions are as clear as possible
            </p>
          )}
          {errMsg && (
            <p className="text-gray-400 text-center my-4 mx-auto w-6/12 text-sm">
              {errMsg}
            </p>
          )}
        </div>
      </div>
    );
  }
  switch (type) {
    case "table":
      return <TablePresentation data={data} />;
    case "piechart":
      return <Pie_chart_presentation />;
    case "linechart":
      return <Line_chart_presentation />;

    default:
      return <div></div>;
  }
};

export default PresentData;
