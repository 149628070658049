import { useContext } from "react";
import { NodesServiceContext } from "../../../context/nodes_context";
import { Button } from "antd";

export const TextInputNode = (props) => {
  const context = useContext(NodesServiceContext);
  const { onToggleEdit, onNodeValueChange } = context;

  const { id, data } = props;
  const { label, disabled, textinputtype = "text" } = data;
  const cursor = disabled === false ? "" : "cursor-move";
  return (
    <div onClick={() => onToggleEdit(id)} className="flex flex-col gap-y-1">
      <span>{label}</span>
      <input
        className={`${cursor} border w-[280px] shadow border-2 border-gray-400 h-10 rounded-md my-3 p-2`}
        placeholder={label}
        disabled={disabled == undefined ? true : disabled}
        autoFocus
        onChange={(e) =>
          onNodeValueChange
            ? onNodeValueChange(label, e.target.value)
            : console.log(e)
        }
        type={textinputtype}
      />
    </div>
  );
};

export const SelectNode = ({ id, data }) => {
  const context = useContext(NodesServiceContext);
  const { onToggleEdit, onNodeValueChange } = context;

  const { options = [], label, disabled } = data;
  const cursor = disabled == false ? "" : "cursor-move";
  return (
    <div onClick={() => onToggleEdit(id)} className="flex flex-col gap-y-1">
      <span>{label}</span>
      <select
        className={`${cursor} border shadow border-2 border-gray-400 h-10 rounded-md my-3 p-2 w-[280px]`}
        placeholder="Enter a text"
        disabled={disabled === undefined ? true : disabled}
        onChange={(e) =>
          onNodeValueChange
            ? onNodeValueChange(label, e.target.value)
            : console.log(e)
        }
      >
        {options.map((option) => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export const MultipleSelectNode = ({ id, data }) => {
  const context = useContext(NodesServiceContext);
  const { onToggleEdit, onNodeValueChange } = context;

  const { options = [], label, disabled } = data;
  const cursor = disabled == false ? "" : "cursor-move";
  return (
    <select
      onClick={() => onToggleEdit(id)}
      className={`${cursor} border   shadow border-2 border-gray-400 h-10 rounded-md my-3 p-2 w-[280px]`}
      placeholder="Enter a text"
      disabled={disabled === undefined ? true : disabled}
      onChange={(e) =>
        onNodeValueChange ? onNodeValueChange(label) : console.log(e)
      }
      multiple
    >
      {options.map((option) => {
        return (
          <option key={option} value={option}>
            {option}
          </option>
        );
      })}
    </select>
  );
};

export const SubmitFormNode = ({ id, data }) => {
  const context = useContext(NodesServiceContext);
  const { onToggleEdit, onSubmitForm, loading } = context;

  const { label, disabled } = data;
  console.log(loading);
  const cursor = disabled == false ? "" : "cursor-move";

  return onSubmitForm ? (
    <Button
      type="submit"
      onClick={onSubmitForm}
      loading={loading}
      className={`cursor-pointer border text-center   shadow border-2 text-white bg-blue-700 h-10 rounded-md my-3 p-2 w-[280px]`}
      placeholder="Submit form"
      disabled={false}
    >
      {label || "Button"}
    </Button>
  ) : (
    <div
      onClick={() => onToggleEdit(id)}
      className={`${cursor} border text-center   shadow border-2 text-white bg-blue-700 h-10 rounded-md my-3 p-2 w-[280px]`}
      placeholder="Submit form"
      disabled={disabled === undefined ? true : disabled}
    >
      {label || "Button"}
    </div>
  );
};

export const TitleTextNode = ({ id, data }) => {
  const context = useContext(NodesServiceContext);
  const { onToggleEdit } = context;

  const { label } = data;
  return (
    <div
      onClick={() => onToggleEdit(id)}
      className="text-center h-10 rounded-md my-3 p-2 w-[280px] text-2xl font-bold"
    >
      <span>{label || "Text"} </span>
    </div>
  );
};
