import { useContext } from "react"
import { Modal } from "antd";
import React from "react";
import { getTableNamesFromSchemaAsKeywords, interpolateTextWithKeywords, objAsKeywords } from "../../../../utils/keyword_utils";
import { ContextServiceContext } from "../../../../context/schema_context";
import { Str } from "../../../../utils/constants";


const NoInputRowAction = (props: {
    name: string,
    description: string,
    isOpen: boolean,
    actionObj: any,
    rowData: any,
    onCancel: () => void,
}) => {
    const contextService = useContext(ContextServiceContext);
    const schema = contextService.schema;

    const { onCancel, actionObj, isOpen, name } = props
    const { widgetConfig } = actionObj;
    const { actions = [] } = widgetConfig;

    const onPerformRowAction = async () => {
        await console.log(actionObj);
        onCancel()
    }


    const keyWords =
        [
            ...objAsKeywords(props.rowData, 'rowData'),
            ...Str.defaultSQLKeywords,
            ...getTableNamesFromSchemaAsKeywords(schema.databaseSchema)
        ]

    const getDescription = () => {
        return interpolateTextWithKeywords(keyWords, widgetConfig.description)
    }

    const getAction = (action: string) => {
        return interpolateTextWithKeywords(keyWords, action)
    }

    return (
        <Modal open={isOpen} title={name}
            okText={widgetConfig?.okText ?? 'Submit action'}
            onOk={onPerformRowAction} onCancel={onCancel} >
            <div className="text-black text-sm">
                {getDescription()}
            </div>

            <span className="block mt-8 mb-4 text-gray-500">Actions performed in the following order:</span>
            <div className="bg-gray-200 py-2 px-3 rounded-sm mb-10">
                {actions.map((action, index) => {
                    return <span className="text-gray-600 text-xs block my-2">({index + 1}) {getAction(action?.action)}</span>
                })}
            </div>
        </Modal>
    )
}

export default NoInputRowAction