import { WarningFilled } from "@ant-design/icons";
import { Input, Select } from "antd";
import "chart.js/auto";
import { Component } from "react";
import { Pie } from "react-chartjs-2";
import PropTypes from "prop-types";
import { QuestionConfigContext } from "../../context/question_config_context";

const getChartData = (data = {}) => {
  data.data = data.data || [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  data.labels = data.labels || data.data.map((x) => `dummy ${x}`);

  return {
    labels: data.labels,
    datasets: [
      {
        label: data.title,
        data: data.data,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };
};

class PieChart extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {}

  componentDidMount() {
    if (
      this.context.questionConfig?.graphConfig?.pieChart &&
      this.context.questionConfig?.graphConfig?.pieChart?.labelKey &&
      this.context.questionConfig?.graphConfig?.pieChart?.dataKey
    ) {
      this.onAddDefaults();
    }
  }

  state = {
    defaultOptions: { labelKey: undefined, dataKey: undefined },
    graphData: { data: undefined, labels: undefined },
  };

  onAddDefaults = () => {
    const { labelKey, dataKey } =
      this.context.questionConfig?.graphConfig?.pieChart || {};
    const labelPicked = this.context.data.map((row) => row[labelKey]);
    const dataPicked = this.context.data.map((row) => row[dataKey]);
    this.setState({
      ...this.state,
      graphData: {
        ...this.state.graphData,
        labels: labelPicked,
        data: dataPicked,
      },
    });
  };

  onChageLabelOption = (labelKey) => {
    const picked = this.context.data.map((row) => row[labelKey]);
    this.setState({
      ...this.state,
      graphData: { ...this.state.graphData, labels: picked },
      defaultOptions: { ...this.state.defaultOptions, labelKey: labelKey },
    });
    this.context.onSetQuestionConfig("graphConfig", {
      section: "pieChart",
      data: { ...this.state.defaultOptions, labelKey: labelKey },
    });
  };

  onChageDataOption = (dataKey) => {
    const picked = this.context.data.map((row) => row[dataKey]);
    this.setState({
      ...this.state,
      graphData: { ...this.state.graphData, data: picked },
      defaultOptions: { ...this.state.defaultOptions, dataKey: dataKey },
    });

    this.context.onSetQuestionConfig("graphConfig", {
      section: "pieChart",
      data: { ...this.state.defaultOptions, dataKey: dataKey },
    });
  };

  render() {

    const headers = Object.keys(this.context.data[0]).map((header, index) => {
      return { value: header, label: header };
    });
    const { data, labels } = this.state.graphData;
    const { labelKey, dataKey } =
      this.context.questionConfig?.graphConfig?.pieChart || {};

    return (
      <div className="mt-10 flex justify-center gap-x-4">
        <div className="text-black  w-4/12 shadow-lg p-4 h-60 rounded">
          <div className="w-full">
            <span className="text-xs mb-2 mt-2 block font-bold">
              Select label column
            </span>
            <Select
              defaultValue={labelKey}
              className="w-full"
              placeholder="Select label column"
              onChange={this.onChageLabelOption}
              options={headers}
            />
          </div>
          <div className="w-full mt-8">
            <span className="text-xs mb-2 mt-2 block font-bold">
              Select data column
            </span>
            <Select
              defaultValue={dataKey}
              className="w-full"
              placeholder="Select data column"
              onChange={this.onChageDataOption}
              options={headers}
            />
          </div>
        </div>
        {data && data.length > 0 && (
          <div className=" w-6/12 mx-auto ">
            <Pie data={getChartData(this.state.graphData)} />
          </div>
        )}
        {!data && (
          <div className="mt-10 w-6/12 mx-auto text-center">
            <WarningFilled className="text-orange-600 text-4xl block mb-4 block" />
            <h1 className="text-black text-sm">
              Select label and data column to show the pie chart
            </h1>
          </div>
        )}
      </div>
    );
  }
}

PieChart.contextType = QuestionConfigContext;
export default PieChart;
