import { Checkbox, Spin, Switch, message } from "antd";
import { Str } from "../../../utils/constants";
import { useEffect, useState } from "react";
import { makeClientSideApiRequest } from "../../../utils";
import { useParams } from "react-router-dom";

const backendPermissionOptions = [
  { label: "Read", value: Str.backendPermissionOpts.read },
  { label: "Write", value: Str.backendPermissionOpts.write },
  { label: "Update", value: Str.backendPermissionOpts.update },
  { label: "Delete", value: Str.backendPermissionOpts.delete },
];

export const BackendPermissions = ({
  tables,
  onChangeBackendPermissionInput,
  setBulkBackendPermissions,
  backendPermissions,
  groupSelect,
  onChangeGroupSelect,
  memberInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const [existingBackendPermissions, setExistingBackendPermissions] = useState(
    []
  );
  const params = useParams();

  const loadExistingPermissions = async () => {
    try {
      setLoading(true);
      const response = await makeClientSideApiRequest(
        `/api/datasource/${params?.id}/team/permissions/${memberInfo?.id}`
      );
      if (response.status === "success") {
        const existingPerms = JSON.parse(
          response.data?.backend_permissions ?? "{}"
        );

        setBulkBackendPermissions(existingPerms);
        setLoading(false);
        return;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setExistingBackendPermissions(undefined);
    }
  };

  useEffect(() => {
    if (memberInfo) {
      loadExistingPermissions();
    }
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="w-full">
        <div className="flex gap-x-3 py-3 bg-gray-100 rounded-md my-3 px-2">
          <Switch
            checkedChildren="Read all"
            unCheckedChildren="Select table read"
            className="bg-gray-500"
            onChange={(x) =>
              onChangeGroupSelect(Str.backendPermissionOpts.read, x)
            }
            checked={groupSelect[Str.backendPermissionOpts.read]}
          />

          <Switch
            checkedChildren="Write all"
            unCheckedChildren="Select table write"
            className="bg-gray-500"
            onChange={(x) =>
              onChangeGroupSelect(Str.backendPermissionOpts.write, x)
            }
            checked={groupSelect[Str.backendPermissionOpts.write]}
          />

          <Switch
            checkedChildren="Update all"
            unCheckedChildren="Select table update"
            className="bg-gray-500"
            onChange={(x) =>
              onChangeGroupSelect(Str.backendPermissionOpts.update, x)
            }
            checked={groupSelect[Str.backendPermissionOpts.update]}
          />

          <Switch
            checkedChildren="Delete all"
            unCheckedChildren="Select table delete"
            className="bg-gray-500"
            onChange={(x) =>
              onChangeGroupSelect(Str.backendPermissionOpts.delete, x)
            }
            checked={groupSelect[Str.backendPermissionOpts.delete]}
          />
        </div>

        {tables && (
          <div className="h-96 overflow-y-scroll mt-2">
            {tables.map((table) => {
              return (
                <div className="flex my-4 justify-between">
                  <span className="">{table}</span>
                  <div className="bg-blue-100 rounded-lg px-2 py-1">
                    <Checkbox.Group
                      options={backendPermissionOptions}
                      value={backendPermissions[table]}
                      defaultValue={[]}
                      onChange={(x) => onChangeBackendPermissionInput(table, x)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Spin>
  );
};
