import { Collapse, Drawer, Input, Radio, Segmented, Spin, message } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { MButton } from "../../Common/buttons";
import {
  makePostRequest,
} from "../../../utils";
import { useParams } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";
import CommonTable from "../../Common/common_table";
import { useEffect, useState } from "react";
import { Str } from "../../../utils/constants";

const RowRelationships = ({ table, filter, table_relationships = {} }) => {
  console.log(table_relationships);
  const { key, relationships = [] } = table_relationships ?? {};

  const getItems = () => {
    return relationships.map((relationship, index) => {
      return {
        key: index.toString(),
        label: relationship,
        children: (
          <RelationshipTable
            table={table}
            relation={relationship}
            filter={filter}
            foreignKey={key}
          />
        ),
      };
    });
  };

  return (
    <>
      <div className="my-6">
        <Collapse
          bordered={false}
          accordion={true}
          destroyInactivePanel={true}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          items={getItems()}
        />
      </div>
    </>
  );
};

export default RowRelationships;

const RelationshipTable = ({ foreignKey, table, relation, filter }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [timeTaken, setTimeTaken] = useState(undefined);

  const [orderBy, setOrderBy] = useState(undefined);
  const [order, setOrder] = useState(undefined);

  const params = useParams();

  useEffect(() => {
    if (!data) onLoadCommandHistory();
  }, []);

  const onLoadCommandHistory = async () => {
    setLoading(true);
    try {
      const isSorting = order && orderBy;

      const response = await makePostRequest(
        `/api/datasource/${params?.id
        }/backend?table=${relation}&offset=${0}&pageSize=${1000}${isSorting ? `&orderBy=${orderBy}&order=${order}` : ""
        }&auto_order_by_desc=true`,
        {
          groupFilters: [
            {
              operation: "and",
              filters: [
                {
                  column: foreignKey,
                  operation: "equal",
                  value: filter,
                },
              ],
            },
          ],
        }
      );

      if (response.status === "success") {
        setLoading(false);
        console.log(response.data.result);
        setData(response.data.result);
        setTimeTaken(response.data.time_taken)
      } else {
        setLoading(false);
        message.error(response.reason);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  return (
    <Spin size="small" spinning={loading}>
      <CommonTable
        timeTaken={timeTaken ?? 0}
        data={data}
        total={data?.length ?? 0}
        loading={loading}
        onRowSelected={() => { }}
      />
    </Spin>
  );
};
