import { IWidgetObj, RowActionType } from "../../../../interface/iwidget_builder";

export const getWidgetObj = (
  widgetType: string,
  formType: "select" | "text" | "number" | "email" | RowActionType,
) => {
  switch (widgetType) {
    case "filter":
      return getFilterWidget(formType as "select" | "text" | "number" | "email");
    case "action":
      return getRowActionWidget(formType as RowActionType);

    default:
      break;
  }
};

export const getFilterWidget = (
  formType: "select" | "text" | "number" | "email"
): IWidgetObj => {

  return {
    name: "Filter widget",
    type: "filter",
    key: "filter_widget_key",
    widgetConfig: getInputType(formType),
  };
};


export const getRowActionWidget = (
  actionType: RowActionType
): IWidgetObj => {
  console.log(actionType);
  const defaultRowActionConfig = {
    actions: [
      {
        type: "sql",
        action: "sql here..."
      },
      {
        type: "webhook",
        url: "webhook here",
        data: '{}'
      }
    ],
    actionType: actionType,
    description: "description here.."
  }

  return {
    name: "Row widgets",
    type: "action",
    key: "row_widget_key",
    widgetConfig: { ...defaultRowActionConfig, ...getRowActionType(actionType) },
  };
};



const getRowActionType = (actionType: RowActionType) => {
  switch (actionType) {
    case "no_input":
      return {}
    case "single_input":
    case "multiple_inputs":
      return {
        inputs: [
          getInputType("select"),
          getInputType("text"),
          getInputType("number"),
        ]
      }

    default:
      break;
  }
}

const getInputType = (formType: string) => {
  let widgetConfig = {};

  switch (formType) {
    case "select":
      widgetConfig = {
        inputType: "select",
        defaultValue: "all",
        inputOptions: [
          { value: 1, label: "option 1" },
          { value: 2, label: "option 2" },
        ],
      };
      break;
    case "text":
      widgetConfig = {
        inputType: "text",
        defaultValue: "anytext",
      };
      break;
    case "number":
      widgetConfig = {
        inputType: "number",
        defaultValue: 1,
        step: 1,
      };
      break;
    case "email":
      widgetConfig = {
        inputType: "email",
        defaultValue: "email@sample.com",
      };
      break;
    default:
      break;
  }

  return { ...widgetConfig, inputName: 'input name', inputKey: 'input.keyname' }
}